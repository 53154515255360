import { ApisauceInstance, create } from 'apisauce';
import dayjs from 'dayjs';
import { addLogger } from '../../utils';
import { EventData, EventType } from './types';

const __DEV__ = (() => !process.env.NODE_ENV || process.env.NODE_ENV === 'development')();

export class AnalyticsService {
  private apiClient: ApisauceInstance;
  private account_id: string;
  private selectedStoreKey: string | (() => string);
  private userId: string | (() => string);
  private hitCount: (title: string) => Promise<number>;
  private brsmPixelUrl: string;
  private uniqueId: () => Promise<string>;
  private eventLogger?: (eventName: EventType, eventData: Record<string, string | undefined>) => void;

  constructor(config: {
    apiUrl: string;
    accountId: string;
    selectedStoreKey: string | (() => string);
    userId: string | (() => string);
    hitCount: (title: string) => Promise<number>;
    uniqueId: () => Promise<string>;
    eventLogger?: (eventName: EventType, eventData: Record<string, string | undefined>) => void;
  }) {
    this.apiClient = create({ baseURL: config.apiUrl });
    this.account_id = config.accountId;
    this.hitCount = config.hitCount;
    this.userId = config.userId;
    this.selectedStoreKey = config.selectedStoreKey;
    this.uniqueId = config.uniqueId;
    this.brsmPixelUrl = config.apiUrl;
    this.eventLogger = config.eventLogger;

    if (__DEV__) {
      const axiosLogger = require('debug')('api:analytics');
      addLogger(this.apiClient.axiosInstance, axiosLogger);
    }
  }

  trackEvent = async (eventType: EventType, eventData: EventData) => {
    const storeId = typeof this.selectedStoreKey === 'string' ? this.selectedStoreKey : this.selectedStoreKey();
    const userId = typeof this.userId === 'string' ? this.userId : this.userId();
    const hitCount = await this.hitCount(eventData?.title ?? '');
    const uid = await this.uniqueId();
    const params: Record<string, string | number | undefined> = {
      ...eventData,
      acct_id: this.account_id,
      cookie2: encodeURIComponent(`uid=${uid}:v=app:ts=${dayjs().unix()}:hc=${hitCount}`),
      view_id: storeId,
      user_id: userId,
      rand: `${dayjs().unix()}`,
    };

    const qs = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');

    const response = await this.apiClient.get(`/pix.gif?${qs}`, {}, { responseType: 'arraybuffer' });

    if (response.ok) {
      this.eventLogger && this.eventLogger(eventType, { baseUrl: `${this.brsmPixelUrl}`, qs: qs });
    }
  };
}
