import { Lookup } from './common';
import { Address, UserProfile } from './user';

export enum OrderStatusType {
  Pending = 1,
  Active = 2,
  Preprocessing = 3,
  Preprocessed = 4,
  Packing = 5,
  Packed = 6,
  Routing = 7,
  Routed = 8,
  Shipping = 9,
  Shipped = 10,
  Complete = 11,
  Canceled = 12,
  SoftCanceled = 13,
}

export enum OrderType {
  Online = 1,
  'In-store' = 7,
}
export enum ShippingMethodType {
  Pickup = 7,
  Delivery = 8,
}

export type Order = {
  orderId: number;
  customerId: number;
  customer: UserProfile;
  orderType: Lookup;
  orderStatus: Lookup;
  addressType: Lookup;
  orderItems: OrderItem[];
  orderPaymentMethods?: OrderPaymentMethod[];
  shippingAddressId: number;
  shippingAddress: Address;
  extOrderId: string;
  productAmount: number;
  shippingAmount: number;
  productTaxAmount: number;
  shippingTaxAmount: number;
  adjustmentAmount: number;
  taxRate: number;
  estimatedShipDates: Date[];
  estimatedDeliveryDates: Date[];
  createdDate: Date;
  revisedDate: Date;
  fulfillmentDepartments?: string[];
  orderNote?: string;
  shippingNote?: string;
  reviewedByExtId?: string;
  serviceFeeAmount: number;
  deliveryFeeAmount: number;
  tipAmount: number;
  tipPercent: number;
  deliveryBeginTime?: string;
  deliveryEndTime?: string;
  deliverySite?: DeliverySite;
  facilityId?: number;
  facility?: Lookup;
  shippingMethod?: string;
  estimatedLoyaltyPointsTotal?: number;
  alternateContactFirstName?: string;
  alternateContactLastName?: string;
  alternateContactPhoneNumber?: string;
  alternateContactAllowText?: boolean;
  totalPrice?: number;
  offlinePaymentMethodType?: Lookup;
  orderAdjustmentTransactions?: OrderAdjustmentTransaction[];
};

export type OrderItem = {
  orderItemId: number;
  orderId: number;
  orderItemStatus: Lookup;
  orderItemSubItems?: OrderItemSubItem[];
  shippingMethod: Lookup;
  productListingId: number;
  productListing: Lookup;
  extOrderItemId: string;
  qty: number;
  unitPrice: number;
  unitProductTax: number;
  unitShipping: number;
  unitShippingTax: number;
  unitAdjustmentAmount: number;
  estimatedShipDate: Date;
  billedDate?: Date;
  cancelledDate?: Date;
  isEditable: boolean;
  createdDate: Date;
  revisedDate: Date;
  estimatedDeliveryDate: Date;
  orderItemNote?: string;
  productImageUrl: string;
  isCateringItem: boolean;
  excludeForReorder: boolean;
  unitRegularPrice: number;
  unitType: Lookup;
  unitSellType: Lookup;
  estimatedTotalWeight: number;
  actualTotalWeight: number;
  orderItemReplacementType: Lookup;
  orderItemReplacementItems: OrderItemReplacementItem[];
  orderItemDepositTransaction: Lookup;
  merchandisingCategory: Lookup;
};

export interface OrderItemReplacementItem {
  orderItemId: number;
  replacementProductListingId: number;
  replacementProductListing: Lookup;
  productImageUrl: string;
  priority: number;
  createdDate: Date;
  revisedDate: Date;
}

export interface OrderItemSubItem {
  orderItemSubItemId: number;
  orderItemId: number;
  orderItemSubItemStatus: Lookup;
  productListing: Lookup;
  productListingLot?: any;
  extSubItemId: string;
  qty: number;
  originalQty: number;
  baseBuildQty: number;
  unitCost: number;
  unitPrice: number;
  unitsPerPackage: number;
  unitType: string;
  vendorName: string;
  productOriginName: string;
  createdDate: Date;
  revisedDate: Date;
  orderItemSubItemNote?: string;
  productImageUrl: string;
  extProductId: string;
}

export interface OrderPaymentMethod {
  paymentMethodType: Lookup;
  creditCardType: Lookup;
  creditCardMaskValue: string;
  customerPaymentMethod: {
    customerPaymentMethodId: number;
  };
}

export interface OrderAdjustmentTransaction {
  orderAdjustmentTransactionId: number;
  orderId: number;
  promotionDefinitionId?: number;
  memberId?: number;
  member?: string;
  adjustmentAmount: number;
  note: string;
  transactionDate: Date;
  createdDate: Date;
}

export interface DeliverySite extends Address {
  deliverySiteId: number;
  deliverySiteStatusId: number;
  deliverySiteStatus: Lookup;
  serviceAreaDayGroupId: number;
  serviceAreaDayWeekDayName: string;
  supportedDeliveryDays: Lookup[];
  frequencyGroupId: number;
  facilityId: number;
  facility: Lookup;
  hostCustomerId?: number;
  hostCustomer?: string;
  regionId: number;
  deliverySiteName: string;
  displayName: string;
  deliveryHourStart: string;
  deliveryHourEnd: string;
  pickupHourStart: string;
  pickupHourEnd: string;
  maxCustomerLimit: number;
  isRestricted: boolean;
  directions: string;
  restrictionNotes?: string;
  isSystemCorrected: boolean;
  groupDisplayName: string;
  shipDateLeadTimeMinutes: number;
}
