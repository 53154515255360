import { gql } from 'graphql-request';

const inventoryEntryByIdQuery = gql`
  query ($id: String!) {
    inventoryEntry(id: $id) {
      id
      sku
      quantityOnStock
      availableQuantity
      restockableInDays
      expectedDelivery
      supplyChannel {
        id
        key
        custom {
          customFieldsRaw {
            name
            value
          }
        }
      }
      custom {
        customFieldsRaw {
          name
          value
        }
      }
    }
  }
`;

const inventoryEntriesBySkuListQuery = gql`
  query ($where: String!) {
    inventoryEntries(where: $where) {
      offset
      count
      total
      exists
      results {
        id
        sku
        quantityOnStock
        availableQuantity
        restockableInDays
        expectedDelivery
        supplyChannel {
          id
          key
          custom {
            customFieldsRaw {
              name
              value
            }
          }
        }
        custom {
          customFieldsRaw {
            name
            value
          }
        }
      }
    }
  }
`;

export { inventoryEntryByIdQuery, inventoryEntriesBySkuListQuery };
