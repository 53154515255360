import appConstants from '../../constants/app';
import {
  Address,
  CustomerAlternateContact,
  DeliverySite,
  Department,
  DepartmentHours,
  Order,
  OrderAdjustmentTransaction,
  OrderItem,
  OrderItemSubItem,
  OrderPaymentMethod,
  Store,
  UserProfile,
} from '../../types';
import {
  Address as AddressDto,
  Customer,
  CustomerAlternateContactDto,
  DeliverySite as DeliverySiteDto,
  Department as DepartmentDto,
  DepartmentHours as DepartmentHoursDto,
  Lookup as LookupDto,
  OrderAdjustmentTransaction as OrderAdjustmentTransactionDto,
  Order as OrderDto,
  OrderItem as OrderItemDto,
  OrderItemReplacementItem as OrderItemReplacementItemsDto,
  OrderItemSubItem as OrderItemSubItemDto,
  OrderPaymentMethod as OrderPaymentMethodDto,
  Store as StoreDto,
} from './types';

export const toUserProfile = (customer: Customer): UserProfile => {
  return {
    amsCardId: Number(customer.ExtLoyaltyCardId),
    defaultBillingAddressId: customer.DefaultBillingAddressId,
    defaultPaymentMethodId: customer.DefaultPaymentMethodId,
    defaultShippingAddressId: customer.DefaultShippingAddressId,
    email: customer.EmailAddress,
    firstName: customer.FirstName,
    lastName: customer.LastName,
    loyaltyId: customer.ExtLoyaltyId,
    phone: customer.PhoneNumber,
    sepiNumber: Number(customer.ExtCustomerId),
    uid: String(customer.CustomerId),
    lastLoginDate: customer.LastLoginDate,
    pointsBalance: customer.EstimatedLoyaltyPointsTotal,
    birthday: customer.BirthDate,
    digitalLoyaltyAgreementDate: customer.DigitalLoyaltyAgreementDate,
    createdDate: customer.CreatedDate,
    pointsAsOfDate: customer.EstimatedLoyaltyPontsAsOfDate,
  } as UserProfile;
};

export const toCustomer = (profile: UserProfile): Customer => {
  return {
    CustomerId: Number(profile.uid),
    EmailAddress: profile.email,
    ExtCustomerId: String(profile.sepiNumber),
    ExtLoyaltyId: profile.loyaltyId,
    FirstName: profile.firstName,
    LastName: profile.lastName,
    PhoneNumber: profile.phone,
    ExtLoyaltyCardId: String(profile.amsCardId),
  } as Customer;
};
export const toAddress = (address: AddressDto): Address => {
  const region = appConstants.States.find((x) => x.id === parseInt(address?.Region?.Id ?? '0', 10));
  return {
    address1: address.Address1,
    address2: address.Address2,
    city: address.City,
    companyName: address.CompanyName,
    id: address.CustomerAddressId,
    firstName: address.FirstName,
    isDefaultBilling: address.IsDefaultBilling,
    isDefaultShipping: address.IsDefaultShipping,
    isValidated: address.IsValidated,
    lastName: address.LastName,
    phone: address.PhoneNumber,
    postalCode: address.PostalCode,
    state: region?.abbreviation,
    addressType: address.AddressType?.Value?.toLowerCase(),
    latitude: address.Latitude,
    longitude: address.Longitude,
    validatedDate: address.ValidatedDate,
    country: 'US',
  } as Address;
};

export const toStore = (dto: StoreDto): Store => {
  return {
    number: dto.StoreNumber,
    active: dto.IsActive,
    brand: { id: 1, name: dto.BrandName, logo: dto?.BrandName?.replace(/[^\w]/gi, '').toLowerCase() },
    distance: dto.Distance,
    id: dto.DeliverySiteId,
    email: dto.EmailAddress,
    name: dto.StoreName,
    phone: dto.PhoneNumber,
    coordinates: { latitude: String(dto.Latitude), longitude: String(dto.Longitude) },
    address: {
      city: dto.StoreAddress.City,
      state: dto.StoreAddress.State,
      street: dto.StoreAddress.Address1,
      zip: dto.StoreAddress.PostalCode,
    },
    index: dto.SortIndex,
    storeHours: dto.StoreHours,
    departments: dto.Departments?.map(toStoreDepartments),
    canSellAlcohol: dto.CanSellAlcohol,
    canDeliverAlcohol: dto.CanDeliverAlcohol,
    cateringStatusId: dto.CateringStatusId,
    fulfillmentLeadTimeMinutes: dto.FulfillmentLeadTimeMinutes,
  } as Store;
};

const toStoreDepartments = (so: DepartmentDto): Department => {
  return {
    departmentId: so.DepartmentId,
    displayName: so.DisplayName,
    phoneNumber: so.PhoneNumber,
    isActive: so.IsActive,
    departmentHours: so.DepartmentHours?.map(toStoreDepartmentHours),
    isFilterable: so.IsFilterable,
    isInternal: so.IsInternal,
  } as Department;
};

const toStoreDepartmentHours = (h: DepartmentHoursDto): DepartmentHours => {
  return {
    departmentId: h.DepartmentId,
    weekday: h.Weekday,
    departmentHours: h.DepartmentHours,
  } as DepartmentHours;
};

const getStoreNumber = (dto?: LookupDto) => {
  const found: RegExpMatchArray | null = (dto?.Value ?? '')?.match(/^(\d)\w+/gi);
  if (found && found.length) {
    return found[0];
  }
  return undefined;
};

export const toOrder = (o: OrderDto): Order => {
  const isDelivery: boolean = o.ShippingMethod?.toUpperCase() === 'DELIVERY';

  const crvTotal: number =
    o.OrderItems && o.OrderItems.length
      ? o.OrderItems.reduce((total, oi): number => {
          const crv = Number(oi.OrderItemDepositTransaction?.CustomProperties?.UnitDepositAmount ?? '0');
          return total + crv * oi.Qty;
        }, 0)
      : o.CRV ?? 0;

  const totalPrice: number =
    o.ProductAmount +
    o.ProductTaxAmount +
    (isDelivery ? o.ShippingAmount + o.ShippingTaxAmount + o.TipAmount : 0) +
    o.ServiceFeeAmount +
    o.AdjustmentAmount +
    crvTotal;

  return {
    addressType: { id: o.AddressType?.Id, value: o.AddressType?.Value },
    adjustmentAmount: o.AdjustmentAmount,
    createdDate: o.CreatedDate,
    customer: toUserProfile(o.Customer),
    customerId: o.CustomerId,
    extOrderId: o.ExtOrderId,
    orderId: o.OrderId,
    orderStatus: {
      id: o.OrderStatus.Id,
      value: o.OrderStatus.CustomProperties?.ConsumerDisplayName ?? o.OrderStatus.CustomProperties?.DisplayName ?? o.OrderStatus.Value,
    },
    orderType: {
      id: o.OrderType.Id,
      value: o.OrderType.CustomProperties?.ConsumerDisplayName ?? o.OrderType.CustomProperties?.DisplayName ?? o.OrderType.Value,
    },
    productAmount: o.ProductAmount,
    productTaxAmount: o.ProductTaxAmount,
    revisedDate: o.RevisedDate,
    serviceFeeAmount: o.ServiceFeeAmount,
    deliveryFeeAmount: o.DeliveryFeeAmount,
    shippingAddress: {
      address1: o.ShippingAddress1,
      addressType: o.AddressType?.Value,
      address2: o.ShippingAddress2,
      address3: o.ShippingAddress3,
      country: o.ShippingRegion?.Value,
      companyName: o.ShippingCompanyName,
      phone: o.ShippingPhoneNumber,
      postalCode: o.ShippingPostalCode,
      firstName: o.ShippingFirstName,
      lastName: o.ShippingLastName,
      latitude: String(o.ShippingLatitude),
      longitude: String(o.ShippingLongitude),
      city: o.ShippingCity,
      state: o.ShippingRegion?.Value,
      isValidated: true,
    } as Address,
    shippingAddressId: o.CustomerShippingAddressId ?? o.OrganizationShippingAddressId ?? o.SponseeShippingAddressId,
    shippingAmount: o.ShippingAmount,
    shippingTaxAmount: o.ShippingTaxAmount,
    taxRate: o.TaxRate,
    tipAmount: o.TipAmount,
    tipPercent: o.TipPercent,
    promotionJSON: o.PromotionJSON,
    facilityId: o.FacilityId ?? o.OrderItems?.length ?? 0 ? o.OrderItems[0].FacilityId : undefined,
    facility: o.Facility
      ? {
          id: o.Facility.Id,
          value: o.Facility.Value,
          customProperties: { ...o.Facility.CustomProperties, StoreNumber: getStoreNumber(o.Facility) },
        }
      : (o.OrderItems?.length ?? 0) && o.OrderItems[0].Facility
        ? {
            id: o.OrderItems[0].Facility.Id,
            value: o.OrderItems[0].Facility.Value,
            customProperties: { ...o.OrderItems[0].Facility.CustomProperties, StoreNumber: getStoreNumber(o.OrderItems[0].Facility) },
          }
        : undefined,
    estimatedDeliveryDates: o.EstimatedDeliveryDates,
    deliveryBeginTime: o.DeliveryBeginTime,
    deliveryEndTime: o.DeliveryEndTime,
    deliverySite: o.DeliverySite ? toDeliverySite(o.DeliverySite) : undefined,
    estimatedLoyaltyPointsTotal: o.EstimatedLoyaltyPointsTotal,
    orderNote: o.OrderNote,
    shippingNote: o.ShippingNote,
    fulfillmentDepartments: o.FulfillmentDepartments,
    reviewedByExtId: o.ReviewedByExtId,
    shippingMethod: o.ShippingMethod,
    orderPaymentMethods: o.OrderPaymentMethods?.map(toOrderPaymentMethod),
    estimatedShipDates: o.EstimatedShipDates,
    orderItems: o.OrderItems?.map(toOrderItem),
    alternateContactFirstName: o.AlternateContactFirstName,
    alternateContactLastName: o.AlternateContactLastName,
    alternateContactPhoneNumber: o.AlternateContactPhoneNumber,
    alternateContactAllowText: o.AlternateContactAllowText,
    totalPrice: totalPrice,
    offlinePaymentMethodType: o.OfflinePaymentMethodType ? { id: o.OfflinePaymentMethodType.Id, value: o.OfflinePaymentMethodType.Value } : undefined,
    orderAdjustmentTransactions: o.OrderAdjustmentTransactions?.map(toOrderAdjustmentTransaction),
  } as Order;
};

const toOrderItem = (oi: OrderItemDto): OrderItem => {
  return {
    createdDate: oi.CreatedDate,
    estimatedDeliveryDate: oi.EstimatedDeliveryDate,
    estimatedShipDate: oi.EstimatedShipDate,
    extOrderItemId: oi.ExtOrderItemId,
    isEditable: oi.IsEditable,
    orderId: oi.OrderId,
    orderItemId: oi.OrderItemId,
    orderItemNote: oi.OrderItemNote,
    orderItemStatus: {
      id: oi.OrderItemStatus.Id,
      value: oi.OrderItemStatus.CustomProperties?.ConsumerDisplayName ?? oi.OrderItemStatus.CustomProperties?.DisplayName ?? oi.OrderItemStatus.Value,
    },
    productImageUrl: oi.ProductImageUrl,
    productListingId: oi.ProductListingId,
    qty: oi.Qty,
    productListing: { id: oi.ProductListing.Id, value: oi.ProductListing.Value, customProperties: oi.ProductListing.CustomProperties },
    revisedDate: oi.RevisedDate,
    shippingMethod: {
      id: oi.ShippingMethod.Id,
      value: oi.ShippingMethod.CustomProperties?.ConsumerDisplayName ?? oi.ShippingMethod.CustomProperties?.DisplayName ?? oi.ShippingMethod.Value,
    },
    unitAdjustmentAmount: oi.UnitAdjustmentAmount,
    unitPrice: oi.UnitPrice,
    unitProductTax: oi.UnitProductTax,
    unitShipping: oi.UnitShipping,
    unitShippingTax: oi.UnitShippingTax,
    billedDate: oi.BilledDate,
    cancelledDate: oi.CancelledDate,
    orderItemSubItems: oi.OrderItemSubItems?.map(toOrderItemSubItem),
    isCateringItem: oi.IsCateringItem ?? false,
    excludeForReorder: oi.ExcludeForReorder,
    unitRegularPrice: oi.UnitRegularPrice,
    unitType: oi.UnitType ? { id: oi.UnitType.Id, value: oi.UnitType.CustomProperties?.Abbreviation ?? oi.UnitType.Value } : null,
    unitSellType: oi.UnitSellType ? { id: oi.UnitSellType.Id, value: oi.UnitSellType.Value } : null,
    estimatedTotalWeight: oi.EstimatedTotalWeight,
    actualTotalWeight: oi.ActualTotalWeight,
    orderItemReplacementType: oi.OrderItemReplacementType ? { id: oi.OrderItemReplacementType.Id, value: oi.OrderItemReplacementType.Value } : null,
    orderItemReplacementItems: oi.OrderItemReplacementItems ? oi.OrderItemReplacementItems.map(toOrderItemReplacementItems) : null,
    orderItemDepositTransaction: oi.OrderItemDepositTransaction
      ? {
          id: oi.OrderItemDepositTransaction?.CustomProperties?.OrderItemDepositTypeId ?? oi.OrderItemDepositTransaction?.Id,
          value: oi.OrderItemDepositTransaction?.CustomProperties?.UnitDepositAmount ?? '0',
        }
      : undefined,
    merchandisingCategory: oi.TopProductMerchandisingCategory
      ? {
          id: oi.TopProductMerchandisingCategory.Id,
          value: oi.TopProductMerchandisingCategory.Value,
          customProperties: oi.TopProductMerchandisingCategory.CustomProperties,
        }
      : undefined,
  } as OrderItem;
};
const toOrderItemReplacementItems = (oiri: OrderItemReplacementItemsDto) => {
  return {
    orderItemId: oiri.OrderItemId,
    replacementProductListingId: oiri.ReplacementProductListingId,
    replacementProductListing: {
      id: oiri.ReplacementProductListing.Id,
      value: oiri.ReplacementProductListing.Value,
    },
    productImageUrl: oiri.ProductImageUrl,
    priority: oiri.Priority,
    createdDate: oiri.CreatedDate,
    revisedDate: oiri.RevisedDate,
  };
};

const toOrderItemSubItem = (oisi: OrderItemSubItemDto): OrderItemSubItem => {
  return {
    baseBuildQty: oisi.BaseBuildQty,
    createdDate: oisi.CreatedDate,
    extSubItemId: oisi.ExtSubItemId,
    orderItemId: oisi.OrderItemId,
    orderItemSubItemId: oisi.OrderItemSubItemId,
    orderItemSubItemStatus: {
      id: oisi.OrderItemSubItemStatus.Id,
      value:
        oisi.OrderItemSubItemStatus.CustomProperties?.ConsumerDisplayName ??
        oisi.OrderItemSubItemStatus.CustomProperties?.DisplayName ??
        oisi.OrderItemSubItemStatus.Value,
    },
    originalQty: oisi.OriginalQty,
    productImageUrl: oisi.ProductImageUrl,
    productListing: { id: oisi.ProductListing.Id, value: oisi.ProductListing.Value, customProperties: oisi.ProductListing.CustomProperties },
    productOriginName: oisi.ProductOriginName,
    qty: oisi.Qty,
    revisedDate: oisi.RevisedDate,
    unitCost: oisi.UnitCost,
    unitPrice: oisi.UnitPrice,
    unitsPerPackage: oisi.UnitsPerPackage,
    unitType: oisi.UnitType,
    vendorName: oisi.VendorName,
    orderItemSubItemNote: oisi.OrderItemSubItemNote,
    extProductId: oisi.ExtProductId,
  } as OrderItemSubItem;
};

const toOrderPaymentMethod = (opm: OrderPaymentMethodDto): OrderPaymentMethod => {
  return {
    paymentMethodType: {
      id: opm.PaymentMethodType.Id,
      value: opm.PaymentMethodType.Value,
      isActive: opm.PaymentMethodType.IsActive,
      customProperties: opm.PaymentMethodType.CustomProperties,
    },
    creditCardType: {
      id: opm.CreditCardType.Id,
      value: opm.CreditCardType.Value,
      isActive: opm.CreditCardType.IsActive,
      customProperties: opm.CreditCardType.CustomProperties,
    },
    creditCardMaskValue: opm.CreditCardMaskValue,
    customerPaymentMethod: {
      customerPaymentMethodId: opm.CustomerPaymentMethod.CustomerPaymentMethodId,
    },
  } as OrderPaymentMethod;
};

const toOrderAdjustmentTransaction = (oat: OrderAdjustmentTransactionDto): OrderAdjustmentTransaction => {
  return {
    orderAdjustmentTransactionId: oat.OrderAdjustmentTransactionId,
    orderId: oat.OrderId,
    promotionDefinitionId: oat.PromotionDefinitionId,
    memberId: oat.MemberId,
    member: oat.Member,
    adjustmentAmount: oat.AdjustmentAmount,
    note: oat.Note,
    transactionDate: oat.TransactionDate,
    createdDate: oat.CreatedDate,
  } as OrderAdjustmentTransaction;
};

export const toAlternateContact = (alternateContact: CustomerAlternateContactDto): CustomerAlternateContact => {
  return {
    birthYear: alternateContact.BirthYear ?? '',
    birthMonth: alternateContact.BirthMonth.padStart(2, '0'),
    birthDay: alternateContact.BirthDay.padStart(2, '0'),
    alternateContactId: alternateContact.CustomerAlternateContactId,
    firstName: alternateContact.FirstName,
    lastName: alternateContact.LastName,
    name: alternateContact.FirstName + ' ' + alternateContact.LastName,
    emailAddress: alternateContact.EmailAddress,
    phoneNumber: alternateContact.PhoneNumber,
    faxNumber: alternateContact.FaxNumber,
    alternateContactTypeID: alternateContact.CustomerAlternateContactType.Id,
    alternateContactTypeValue: alternateContact.CustomerAlternateContactType.Value,
    alternateContactTypeIsActive: alternateContact.CustomerAlternateContactType.IsActive,
  } as CustomerAlternateContact;
};

const toDeliverySite = (ds: DeliverySiteDto): DeliverySite => {
  return {
    ...toAddress(ds),
    deliverySiteId: ds.DeliverySiteId,
    deliverySiteStatusId: ds.DeliverySiteStatusId,
    deliverySiteStatus: ds.DeliverySiteStatus
      ? {
          id: ds.DeliverySiteStatus.Id,
          value: ds.DeliverySiteStatus.Value,
          isActive: ds.DeliverySiteStatus.Value,
          customProperties: ds.DeliverySiteStatus.CustomProperties,
        }
      : undefined,
    serviceAreaDayGroupId: ds.ServiceAreaDayGroupId,
    serviceAreaDayWeekDayName: ds.ServiceAreaDayWeekDayName,
    supportedDeliveryDays: ds.SupportedDeliveryDays?.map((x) => ({
      id: x.Id,
      value: x.Value,
      isActive: x.Value,
      customProperties: ds.DeliverySiteStatus.CustomProperties,
    })),
    frequencyGroupId: ds.FrequencyGroupId,
    facilityId: ds.FacilityId,
    facility: ds.Facility
      ? {
          id: ds.Facility.Id,
          value: ds.Facility.Value,
          isActive: ds.Facility.Value,
          customProperties: ds.Facility.CustomProperties,
        }
      : undefined,
    hostCustomerId: ds.HostCustomerId,
    hostCustomer: ds.HostCustomer,
    regionId: ds.RegionId,
    deliverySiteName: ds.DeliverySiteName,
    displayName: ds.DisplayName,
    deliveryHourStart: ds.DeliveryHourStart,
    deliveryHourEnd: ds.DeliveryHourEnd,
    pickupHourStart: ds.PickupHourStart,
    pickupHourEnd: ds.PickupHourEnd,
    maxCustomerLimit: ds.MaxCustomerLimit,
    isRestricted: ds.IsRestricted,
    directions: ds.Directions,
    restrictionNotes: ds.RestrictionNotes,
    isSystemCorrected: ds.IsSystemCorrected,
    groupDisplayName: ds.GroupDisplayName,
    shipDateLeadTimeMinutes: ds.ShipDateLeadTimeMinutes,
  } as DeliverySite;
};
