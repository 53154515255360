/* eslint-disable no-unused-vars */
import { Indexable } from '../types/common';

export enum ControlType {
  Switch,
  Radio,
  Checkbox,
}

export interface FilterSortItem {
  id: string | number;
  name: string;
  image?: string | undefined;
  value: boolean;
  count?: number;
}

export interface FilterSort {
  id: string;
  name: string;
  active: boolean;
  children: FilterSortItem[];
  type: ControlType;
}

export interface AllFilters extends Indexable<FilterSort> {
  sort: FilterSort;
  filters: FilterSort;
  brands: FilterSort;
  categories: FilterSort;
  dietLifStyle: FilterSort;
  certifications: FilterSort;
  benefitCards: FilterSort;
}

export enum FilterType {
  SORT = 'sort',
  BRAND = 'brand',
  CATEGORY = 'category',
  FILTERS = 'filters',
  DIETLIFESTYLE = 'dietlifestyle',
  CERTIFICATION = 'certification',
  BENEFITCARDS = 'benefitcards',
}

export interface SelectedItems extends Indexable<string[]> {
  sort: string[];
  brand: string[];
  category: string[];
  filters: string[];
  dietlifestyle: string[];
  certification: string[];
  benefitcards: string[];
}
