import { AddressFragment } from './address';
import { CustomLineItemFragment } from './customlineitem';
import { LineItemFragment } from './lineItem';
import { ShippingMethodFragment } from './shippingMethod';

export const OrderFragment = `
${LineItemFragment}
${AddressFragment}
${ShippingMethodFragment}
${CustomLineItemFragment}

fragment DefaultOrder on Order {
  customLineItems {
    ...DefaultCustomLineItem
  }
  lineItems {
    ...DefaultLineItem
  }
  totalPrice {
    ...MoneyFragment
  }
  taxedPrice {
    totalNet {
      ...MoneyFragment
    }
    totalGross {
      ...MoneyFragment
    }
    taxPortions {
      rate
      amount {
        ...MoneyFragment
      }
    }
  }
  custom {
    customFieldsRaw {
      name
      value
    }
  }
  orderState
  id
  orderNumber
  version
  createdAt
  customerEmail
  shipmentState
  paymentState
  shippingAddress {
    ...DefaultAddress
  }
  billingAddress {
    ...DefaultAddress
  }
  shippingInfo {
    price {
      ...MoneyFragment
    }
    shippingMethodName
    shippingMethod {
      ...DefaultShippingMethod
    }
  }
}
`;
