import { Money } from '../schema';

const defaultMoney: Readonly<Money> = {
  centAmount: 0,
  currencyCode: 'USD',
  fractionDigits: 2,
  type: 'centPrecision',
};

export { defaultMoney };
