import { gql } from 'graphql-request';
import { CategoryFragment } from '../../fragments/category';

const categoryBykeyQuery = gql`
  fragment DefaultCategory on Category {
    key
    name(locale: $locale)
    orderHint
    childCount
    children {
      key
      name(locale: $locale)
      orderHint
    }
    ancestors {
      key
      name(locale: $locale)
      orderHint
      parent {
        key
      }
    }
    parent {
      key
      name(locale: $locale)
      orderHint
    }
  }

  query ($key: String!, $locale: Locale!) {
    category(key: $key) {
      ...DefaultCategory
    }
  }
`;

const queryCategories = gql`
  ${CategoryFragment}

  query categories($where: String, $limit: Int, $offset: Int, $sort: [String!], $locale: Locale!) {
    categories(where: $where, sort: $sort, limit: $limit, offset: $offset) {
      offset
      count
      total
      results {
        ...DefaultCategory
      }
    }
  }
`;

const categoryByIdQuery = gql`
  ${CategoryFragment}

  query categories($id: String!, $locale: Locale!) {
    category(id: $id) {
      ...DefaultCategory
    }
  }
`;

const subcategoryByParentId = gql`
  ${CategoryFragment}

  query categories($where: String, $locale: Locale!) {
    categories(where: $where) {
      results {
        ...DefaultCategory
        custom {
          customFieldsRaw {
            name
            value
          }
        }
      }
    }
  }
`;

export { categoryBykeyQuery, queryCategories, categoryByIdQuery, subcategoryByParentId };
