export const CustomerFragment = `

  fragment DefaultCustomer on Customer {
    id
    version
    firstName
    lastName
    email
  }
`;
