import { AddressFragment } from './address';
import { CustomerFragment } from './customer';
import { CustomLineItemFragment } from './customlineitem';
import { LineItemFragment } from './lineItem';
import { ShippingMethodFragment } from './shippingMethod';

export const CartFragment = `
  ${AddressFragment}
  ${CustomerFragment}
  ${LineItemFragment}
  ${ShippingMethodFragment}
  ${CustomLineItemFragment}

  fragment DefaultCart on Cart {
    id
    createdAt
    customerId
    customerEmail
    lineItems {
      ...DefaultLineItem
    }
    customLineItems {
      ...DefaultCustomLineItem
    }
    shippingAddress {
      ...DefaultAddress
    }
    billingAddress {
      ...DefaultAddress
    }
    customer {
      ...DefaultCustomer
    }
    totalPrice {
      ...MoneyFragment
    }
    taxedPrice {
      totalNet {
        ...MoneyFragment
      }
      totalGross {
        ...MoneyFragment
      }
      taxPortions {
        rate
        amount {
          ...MoneyFragment
        }
        name
      }
    }
    paymentInfo {
      payments {
        id
      }
    }
    shippingInfo {
      price {
        ...MoneyFragment
      }
      shippingMethodName
      shippingMethod {
        ...DefaultShippingMethod
      }
    }
    discountCodes {
      discountCode {
        id
        code
        isActive
        validFrom
        validUntil
        name(locale: $locale)
      }
    }
    refusedGifts {
      isActive
      validFrom
      validUntil
      name(locale: $locale)
    }
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    cartState
    version
    inventoryMode
  }
`;
