import { gql } from 'graphql-request';
import { ProductFragment } from '../../fragments';

const productBySkuQuery = gql`
  ${ProductFragment}

  query ($productSku: String!, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String, $includeChannelIds: [String!]) {
    product(sku: $productSku) {
      ...DefaultProduct
    }
  }
`;

const queryProducts = gql`
  ${ProductFragment}

  query products(
    $where: String
    $limit: Int
    $offset: Int
    $locale: Locale!
    $country: Country!
    $currency: Currency!
    $skus: [String!]
    $sort: [String!]
    $channelId: String
    $includeChannelIds: [String!]
  ) {
    products(where: $where, sort: $sort, limit: $limit, offset: $offset, skus: $skus) {
      offset
      count
      total
      results {
        ...DefaultProduct
      }
    }
  }
`;

const queryProductCategories = gql`
  ${ProductFragment}

  query products(
    $where: String
    $limit: Int
    $offset: Int
    $locale: Locale!
    $country: Country!
    $currency: Currency!
    $skus: [String!]
    $sort: [String!]
    $channelId: String
    $includeChannelIds: [String!]
  ) {
    products(where: $where, sort: $sort, limit: $limit, offset: $offset, skus: $skus) {
      offset
      count
      total
      results {
        id
        key
        masterData {
          published
          current {
            name(locale: $locale)
            categories {
              ...DefaultCategory
            }
          }
        }
      }
    }
  }
`;
export { productBySkuQuery, queryProducts, queryProductCategories };
