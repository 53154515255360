import { ClientError } from 'graphql-request';
import {
  AddMyCartLineItem,
  AddressDraft,
  AddressInput,
  Cart,
  CartDraft,
  InventoryMode,
  Me,
  Money,
  MyCartUpdateAction,
  Order,
  Store,
  TaxMode,
} from '../../../schema/schema';
import {
  AddLineItem,
  CartCustomFieldName,
  CustomerContact,
  FulfillmentStore,
  LineItemChanges,
  LineItemCustomFieldName,
  ShippingAddress,
  ShippingMethodName,
} from '../../../schema/types';
import { partialErrorText } from '../../../utils/helpers';
import logger from '../../../utils/logger';
import { StoreContextServiceBase } from '../../storeContextServiceBase';
import { createOrderMutation } from '../order/mutation';
import { orderInfoByOrderNumberQuery } from '../order/query';
import { createCartMutation, deleteCartMutation, replicateCartMutation, updateCartMutation } from './mutation';
import { activeCart } from './query';

const defaultMoney: Money = {
  type: 'centPrecision',
  currencyCode: 'USD',
  centAmount: 0,
  fractionDigits: 2,
};

export class CartService extends StoreContextServiceBase {
  private executeMutation = async (
    id: string,
    version: number,
    actions: MyCartUpdateAction[],
    locale: string,
    currency: string,
    country: string,
    channelId: string,
  ): Promise<Cart> => {
    const response = await this.executeRequest<'cart', Cart>(updateCartMutation, {
      id,
      version,
      actions,
      locale,
      currency,
      country,
      channelId,
    });
    return response.cart;
  };

  deleteCart = async (id: string, version: number, locale: string = 'en-US', country: string = 'US', currency: string = 'USD'): Promise<Cart | undefined> => {
    const priceChannelId = await this.getDistributionChannelId();
    const response = await this.executeRequest<'cart', Cart>(deleteCartMutation, {
      id,
      version,
      locale,
      country,
      currency,
      channelId: priceChannelId,
    });
    return response.cart ?? undefined;
  };

  getCart = async (locale: string = 'en-US', country: string = 'US', currency: string = 'USD'): Promise<Cart | undefined> => {
    const priceChannelId = await this.getDistributionChannelId();
    const response = await this.executeRequest<'me', Me>(activeCart, {
      locale,
      country,
      currency,
      channelId: priceChannelId,
    });
    return response.me.activeCart ?? undefined;
  };

  createCart = async (
    {
      customerContact,
      shippingAddress,
      deliveryTipDefault,
    }: { customerContact?: CustomerContact; shippingAddress?: ShippingAddress; deliveryTipDefault?: number },
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<Cart> => {
    const draftCart: CartDraft = {
      locale,
      country,
      currency,
      taxMode: TaxMode.External,
      inventoryMode: InventoryMode.None,
      custom: {
        type: {
          key: 'raleys-cart-order-custom-fields',
        },
        fields: [
          { name: 'deliveryFee', value: JSON.stringify({ ...defaultMoney, centAmount: 0 }) },
          { name: 'deliveryTip', value: JSON.stringify({ ...defaultMoney, centAmount: 0 }) },
          { name: 'fulfillmentInstruction', value: JSON.stringify('') },
          { name: 'fulfillmentStore', value: JSON.stringify('') },
          { name: 'orderNote', value: JSON.stringify('') },
          { name: 'orderSource', value: JSON.stringify('Online') },
          { name: 'serviceFee', value: JSON.stringify({ ...defaultMoney, centAmount: 0 }) },
          { name: 'timeSlot', value: JSON.stringify('') },
          { name: 'allowSubstitution', value: JSON.stringify(true) },
          { name: 'calculateSalesTax', value: JSON.stringify(false) },
        ],
      },
    };
    if (deliveryTipDefault) {
      draftCart.custom?.fields?.push({ name: 'deliveryTipPercent', value: JSON.stringify(deliveryTipDefault) });
    }

    if (customerContact) {
      draftCart.custom?.fields?.push(
        { name: 'customerContact', value: JSON.stringify(JSON.stringify(customerContact)) },
        { name: 'pickupPersonName', value: JSON.stringify([customerContact.firstName ?? '', customerContact.lastName ?? ''].join(' ')) },
      );
    }

    draftCart.shippingAddress = undefined;
    if (shippingAddress) {
      draftCart.shippingAddress = {
        postalCode: shippingAddress.zip,
        state: shippingAddress.state,
        city: shippingAddress.city,
        country: 'US',
        custom: {
          type: {
            key: 'raleys-address-custom-fields',
          },
          fields: [
            { name: 'addressType', value: JSON.stringify('business') },
            { name: 'isValidated', value: JSON.stringify(true) },
            { name: 'validatedDate', value: JSON.stringify(new Date()) },
          ],
        },
      } as AddressDraft;
    }
    const response = await this.executeRequest<'cart', Cart>(createCartMutation, {
      draft: {
        ...draftCart,
      },
      locale,
      country,
      currency,
    });
    return response.cart;
  };

  addCustomLineItem = async (
    id: string,
    version: number,
    lineItems: AddLineItem[],
    actions: MyCartUpdateAction[] = [],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
    defaultStore: string = '',
  ) => {
    try {
      const res = await this.addLineItem(id, version, lineItems, actions, locale, currency, country);
      return res;
    } catch (error) {
      const e: ClientError = error as ClientError ;
      if (e?.response?.errors?.[0]?.extensions?.code === 'MatchingPriceNotFound') {
        return await this.addLineItem(id, version, lineItems, [], locale, currency, country, defaultStore);
      } else {
        throw error;
      }
    }
  };

  addLineItem = async (
    id: string,
    version: number,
    lineItems: AddLineItem[],
    actions: MyCartUpdateAction[] = [],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
    defaultStore: string = '',
  ): Promise<Cart> => {
    let priceChannelRef = await this.getDistributionChannelKey();
    let availablityChannelRef = await this.getSupplyChannelKeys();
    let defaultStoreInfo: Store | undefined;

    if (defaultStore) {
      defaultStoreInfo = await this.storeService.getStore(defaultStore);
      priceChannelRef = defaultStoreInfo?.distributionChannels.map((x) => x.key)[0] ?? '';
      availablityChannelRef = defaultStoreInfo?.supplyChannels.map((x) => x.key) ?? [''];
    }

    if (!actions) {
      actions = [];
    }
    actions.push(
      ...lineItems.map((item) => {
        const action = {
          addLineItem: {
            distributionChannel: { key: priceChannelRef },
            supplyChannel: { key: availablityChannelRef[0] },
            sku: item.sku,
            quantity: item.quantity,
            custom: {
              type: { key: 'raleys-line-item-custom-fields' },
              fields: [
                { name: 'substitutionType', value: JSON.stringify('Best-Available') },
                { name: 'substitutionItems', value: JSON.stringify([]) },
              ],
            },
          } as AddMyCartLineItem,
        };
        if (item.parentLineItemId) {
          action.addLineItem.custom?.fields?.push({ name: 'parentLineItemId', value: JSON.stringify(item.parentLineItemId) });
        }
        if (item.customStepSort) {
          action.addLineItem.custom?.fields?.push({ name: 'customStepSort', value: JSON.stringify(item.customStepSort) });
        }
        if (item.childLineItems) {
          action.addLineItem.custom?.fields?.push({ name: 'childLineItems', value: JSON.stringify(item.childLineItems) });
        }
        if (item.itemNote) {
          action.addLineItem.custom?.fields?.push({ name: 'itemNote', value: JSON.stringify(item.itemNote) });
        }
        if (item.fields) {
          action.addLineItem.custom?.fields?.push(
            ...item.fields.map((x) => {
              return { name: x.name, value: JSON.stringify(x.value ?? '') };
            }),
          );
        }
        return action;
      }),
    );

    let priceChannelId = await this.getDistributionChannelId();
    if (defaultStore) {
      priceChannelId = defaultStoreInfo?.distributionChannels.map((x) => x.id)[0] ?? '';
    }

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  removeLineItem = async (
    id: string,
    version: number,
    lineItems: string[],
    actions: MyCartUpdateAction[] = [],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    if (!actions) {
      actions = [];
    }
    actions.push(
      ...lineItems.map((val) => {
        return { removeLineItem: { lineItemId: val } };
      }),
    );
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  applyContextChanges = async (
    // TODO tip
    id: string,
    version: number,
    changes: LineItemChanges,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const priceChannelId = await this.getDistributionChannelId();
    const actions: MyCartUpdateAction[] = [];
    actions.push(
      ...(changes?.remove ?? []).map((val) => {
        return { removeLineItem: { lineItemId: val } };
      }),
    );
    actions.push(
      ...(changes?.reduce ?? []).map((val) => {
        return { changeLineItemQuantity: { lineItemId: val.li, quantity: val.qty } };
      }),
    );
    actions.push(
      ...(changes?.channel ?? []).map((val) => {
        return { setLineItemDistributionChannel: { lineItemId: val, distributionChannel: { id: priceChannelId } } };
      }),
    );
    actions.push(
      ...(changes?.channel ?? []).map((val) => {
        return { setLineItemSupplyChannel: { lineItemId: val, supplyChannel: { id: priceChannelId } } };
      }),
    );
    actions.push({ recalculate: { updateProductData: true } });

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setLineItemQuantity = async (
    id: string,
    version: number,
    lineItemId: string,
    quantity: string | number,
    fields: { name: LineItemCustomFieldName; value?: string | string[] | number | Money }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    quantity = isNaN((quantity = parseInt(`${quantity}`, 10))) ? 1 : quantity;

    const actions: MyCartUpdateAction[] = [{ changeLineItemQuantity: { lineItemId: lineItemId, quantity: quantity } }];
    if (fields) {
      actions.push(
        ...fields.map((x) => {
          return { setLineItemCustomField: { lineItemId: lineItemId, name: x.name, value: x.value ? JSON.stringify(x.value) : null } };
        }),
      );
    }
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  executeCartUpdateActions = async (
    id: string,
    version: number,
    actions: MyCartUpdateAction[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const priceChannelId = await this.getDistributionChannelId();
    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setLineItemCustomField = async (
    id: string,
    version: number,
    lineItemId: string,
    fields: { name: LineItemCustomFieldName; value?: string | string[] | number | Money }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = fields.map((x) => {
      return { setLineItemCustomField: { lineItemId: lineItemId, name: x.name, value: x.value ? JSON.stringify(x.value) : null } };
    });
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setLineItemCustomType = async (
    id: string,
    version: number,
    lineItemId: string,
    fields: { name: LineItemCustomFieldName; value?: string | string[] | number | Money }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [
      {
        setLineItemCustomType: {
          type: { key: 'raleys-line-item-custom-fields' },
          lineItemId: lineItemId,
          fields: fields.map((x) => {
            return { name: x.name, value: JSON.stringify(x.value ?? '') };
          }),
        },
      },
    ];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setShippingAddress = async (
    id: string,
    version: number,
    address: AddressInput | undefined,
    shippingMethod: ShippingMethodName,
    fulfillmentStore?: FulfillmentStore,
    fulfillmentInstruction?: string,
    miscActions: { [key: string]: string[] } = {},
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    // need to check the current setting and only set totaxMode to external if it is not alreadt set to that or it clears out all pre-calculated taxes (on customlineitems)
    const actions: MyCartUpdateAction[] = [{ changeTaxMode: { taxMode: TaxMode.External } }];

    if (address) {
      actions.push({ setShippingAddress: { address } });
      actions.push({ setShippingMethod: { shippingMethod: { key: shippingMethod } } });
      actions.push({
        setCustomField: { name: 'fulfillmentInstruction', value: JSON.stringify(fulfillmentInstruction ?? '') },
      });
      actions.push({
        setCustomField: { name: 'fulfillmentStore', value: JSON.stringify(fulfillmentStore ? JSON.stringify(fulfillmentStore) : '') },
      });
    } else {
      actions.unshift({ setShippingMethod: { shippingMethod: {} } });

      actions.push({
        setCustomField: {
          name: 'fulfillmentInstruction',
          value: JSON.stringify(''),
        },
      });
      actions.push({
        setCustomField: { name: 'fulfillmentStore', value: JSON.stringify('') },
      });
    }
    const priceChannelId = await this.getDistributionChannelId();

    // set distributionChannel on provided items
    actions.push(
      ...(miscActions?.channel ?? []).map((val) => {
        return { setLineItemDistributionChannel: { lineItemId: val, distributionChannel: { id: priceChannelId } } };
      }),
    );
    actions.push(
      ...(miscActions?.channel ?? []).map((val) => {
        return { setLineItemSupplyChannel: { lineItemId: val, supplyChannel: { id: priceChannelId } } };
      }),
    );

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setBillingAddress = async (
    id: string,
    version: number,
    address: AddressInput | undefined,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [{ setBillingAddress: { address } }];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  addPromoCode = async (
    id: string,
    version: number,
    discountCode: string,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [{ addDiscountCode: { code: discountCode } }];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  removePromoCode = async (
    id: string,
    version: number,
    discountCodeId: string,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [{ removeDiscountCode: { discountCode: { id: discountCodeId, typeId: 'cart' } } }];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setCustomField = async (
    id: string,
    version: number,
    fields: { name: CartCustomFieldName; value?: string | string[] | number | Money | boolean | null }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = fields.map((x) => {
      return {
        setCustomField: { name: x.name, value: x.value || typeof x.value === 'boolean' ? JSON.stringify(x.value) : null },
      } as MyCartUpdateAction;
    });
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setCustomType = async (
    id: string,
    version: number,
    fields: { name: CartCustomFieldName; value?: string | string[] | number | Money | boolean | null }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [
      {
        setCustomType: {
          type: { key: 'raleys-cart-order-custom-fields' },
          fields: fields.map((x) => {
            return { name: x.name, value: JSON.stringify(x.value ?? '') };
          }),
        },
      },
    ];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  setShippingMethod = async (
    id: string,
    version: number,
    shippingMethodKey: ShippingMethodName,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [{ setShippingMethod: { shippingMethod: { key: shippingMethodKey } } }];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  addPayment = async (
    id: string,
    version: number,
    paymentMethodKey: string,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [{ addPayment: { payment: { key: paymentMethodKey } } }];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  removePayment = async (
    id: string,
    version: number,
    paymentMethodKey: string,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<Cart> => {
    const actions: MyCartUpdateAction[] = [{ removePayment: { payment: { key: paymentMethodKey } } }];
    const priceChannelId = await this.getDistributionChannelId();

    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  createOrder = async (id: string, version: number, locale: string = 'en-US', country: string = 'US', currency: string = 'USD'): Promise<Order> => {
    const response = await this.executeRequest<'order', Order>(createOrderMutation, {
      draft: {
        id: id,
        version,
      },
      locale,
      country,
      currency,
    });
    return response.order;
  };

  replicateCartFromOrder = async (orderNumber: string, locale: string = 'en-US', country: string = 'US', currency: string = 'USD'): Promise<Cart> => {
    if (!orderNumber) {
      throw new Error('AppError:InvalidParams:IdAndTypeIdAreRequired');
    }

    let meResponse = await this.executeRequest<'me', Me>(orderInfoByOrderNumberQuery, {orderNumber: orderNumber});
    if (!meResponse.me.order?.id) {
      throw new Error('AppError:InvalidParams:TypeOrder:id');
    }

    const origOrderId = meResponse.me.order?.id;
    // for orders, also verify there is no pending modification order in CT
    let newOrderNumber = `${orderNumber}-1`;
    const orderIdParts = (orderNumber).split('-');
    if (orderIdParts.length > 1) {
      newOrderNumber = [orderIdParts.slice(0, -1).join('-'), Number(orderIdParts[orderIdParts.length - 1]) + 1].join('-');
    }
    try {
      meResponse = await this.executeRequest<'me', Me>(orderInfoByOrderNumberQuery, { orderNumber: newOrderNumber });
      if (meResponse.me.order?.id) {
        throw new Error(`AppError:PendingModOrdersExists:${newOrderNumber}:${meResponse.me.order.id}`);
      }
    } catch (e) {
      throw new Error('AppError:CheckForPendingModOrders:Error:' + newOrderNumber + ':' + partialErrorText(e as Error));
    }

    const cartResponse = await this.executeRequest<'cart', Cart>(replicateCartMutation, {
      reference: {
        id: origOrderId,
        typeId: "order"
      },
      locale,
      country,
      currency,
    });
    if (!cartResponse?.cart) {
      throw new Error(`AppError:ReplicateCartMutation:Order:${origOrderId}`);
    }
    const cart = cartResponse.cart;
    let origStoreNumber = typeof this.selectedStoreKey === 'string' ? this.selectedStoreKey : this.selectedStoreKey();
    const fulfilmentStore = (cart.custom?.customFieldsRaw ?? []).find(f => f.name === 'fulfillmentStore')?.value;
    if (fulfilmentStore) {
      try {
        origStoreNumber = JSON.parse(fulfilmentStore).number;
      } catch (eefs) {
        logger.error('AppError:ErrorParsingFulfilmentStorreFromCart:' + partialErrorText(eefs as Error));
      }
    }
    if (origStoreNumber == '1') origStoreNumber = '01';

    try {
      const actions: MyCartUpdateAction[] = [];
      const parentChild: { [name: string]: string } = {};
      cart.lineItems.forEach((i) => {
        if (i.custom?.customFieldsRaw?.find((f) => f.name === 'childLineItems')) {
          const subItemsIds: string[] = [];
          const fld = i.custom?.customFieldsRaw?.find((f) => f.name === 'customStepSort');
          if (fld) {
            parentChild[i.id] = fld.value;
            actions.push({
              setLineItemCustomField:
                { lineItemId: i.id, name: 'customStepSort', value: JSON.stringify(i.id) }
            });
            cart.lineItems.forEach((c) => {
              if (c.custom?.customFieldsRaw?.find((f) => f.name === 'parentLineItemId' && f.value === fld.value)) {
                actions.push({
                  setLineItemCustomField:
                    { lineItemId: c.id, name: 'parentLineItemId', value: JSON.stringify(i.id) }
                });
                subItemsIds.push(c.id);
              }
            });
          }
          if (subItemsIds.length) {
            actions.push({
              setLineItemCustomField:
                { lineItemId: i.id, name: 'childLineItems', value: JSON.stringify(subItemsIds) }
            });
          }
        }
      });

      // and add cart custom fields
      actions.push(
        { setCustomField: { name: 'OriginalOrderNumber', value: JSON.stringify(orderNumber) } },
        { setCustomField: { name: 'OriginalStore', value: JSON.stringify(origStoreNumber) } },
      );
      if ((cart.custom?.customFieldsRaw ?? []).find(f => f.name === 'customerPaymentMethodAuthCode'))
        actions.push({ setCustomField: { name: 'customerPaymentMethodAuthCode', value: null } });
      if ((cart.custom?.customFieldsRaw ?? []).find(f => f.name === 'customerPaymentMethodId'))
        actions.push({ setCustomField: { name: 'customerPaymentMethodId', value: null }});

      const priceChannelId = await this.getDistributionChannelId();
      return await this.executeMutation(cart.id, cart.version, actions, locale, currency, country, priceChannelId);
    } catch (e) {
      try {
        this.deleteCart(cart.id, cart.version);
      } catch (ee) {
        logger.error('AppError:PostSetFieldError:DeleteCart:' + partialErrorText(ee as Error));
      }
      throw new Error('AppError:SetField:Error:' + partialErrorText(e as Error));
    }
  };
}
