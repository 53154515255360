import { CacheProvider } from '../../types';
import { ServiceBase } from './serviceBase';

export class ServiceBaseWithAuth extends ServiceBase {
  protected authToken: () => Promise<string>;

  constructor(config: { apiUrl: string; authUrl: string; authToken: () => Promise<string>; authKey: string; cache: CacheProvider }) {
    super(config);
    this.authToken = config.authToken;
  }
}
