/* eslint-disable no-unused-vars */
import { Lookup } from './common';

export interface ProductCollections {
  ProductCollections: ProductCollection[];
}

export interface ProductCollection {
  ProductCollectionId: number;
  ProductCollectionTypeId: number;
  ProductCollectionStatusId: number;
  ProductCollectionName: string;
  DisplayName: string;
  Description: string;
  ProductCollectionItems: ProductCollectionItem[];
  ProductCollectionFiles: ProductCollectionFile[];
  SortOrder: number;
  StartDate: Date;
  EndDate: Date;
  ExtProductCollectionId: string;
}

export interface ProductCollectionFile {
  ProductCollectionFileId: number;
  ProductCollectionId: number;
  FileUrl: string;
  FileType: Lookup;
}

export interface ProductCollectionItem {
  ProductCollectionItemId: number;
  ProductCollectionId: number;
  ExtProductId: string;
  ItemProductCollectionId?: number;
  ItemProductCollectionItem?: ProductCollection;
  SortOrder: number;
  PageNumber?: number;
  PageBlock?: number;
  ItemCount?: number;
  MainImageUrl?: string;
  ProductCollectionName?: string,
  DisplayName?: string,
}

export enum FileType {
  MAIN_LIST_IMAGE = 1,
  MAIN_DETAIL_ALTERNATE_IMAGE = 2,
  WEEKLY_AD = 3,
  MAIN_DETAIL_IMAGE = 4,
}
