import { CustomizeCategorySearch, OrderSearch, ProductCategorySearch, ProductInventorySearch, ProductSearch } from '../schema/types';

const buildOrderWhere = (search: OrderSearch): string | null => {
  if (search.id) {
    return `id="${search.id}"`;
  }

  let searchString = '';

  if (search.minDate && search.maxDate) {
    searchString = `createdAt >= "${search.minDate.toISOString()}" and createdAt <= "${search.maxDate.toISOString()}"`;
  } else if (search.minDate) {
    searchString = `createdAt >= "${search.minDate.toISOString()}"`;
  } else if (search.maxDate) {
    searchString = `createdAt <= "${search.maxDate.toISOString()}"`;
  }

  if (search.orderSource) {
    searchString += `${searchString.length ? ' and' : ''} custom(fields(orderSource="${search.orderSource}"))`;
  }
  if (search.shippingType) {
    searchString += `${searchString.length ? ' and' : ''} shippingInfo(shippingMethodName in ("${search.shippingType.join('", "')}"))`;
  }

  return searchString;
};

const buildProductWhere = (search: ProductSearch, locale: string = 'en-US'): null | string => {
  if (search && search.catId) {
    let catIds;
    if (!Array.isArray(search.catId)) {
      catIds = [search.catId];
    } else {
      catIds = search.catId;
    }
    catIds = catIds.join('","');
    return `masterData(current(categories(id in ("${catIds}"))))`;
  }

  if (search && search.slug) {
    return `masterData(current(slug(${locale}="${search.slug}")))`;
  }

  if (search && search.id) {
    return `id="${search.id}"`;
  }

  return null;
};

const buildCategoryWhere = (search: ProductCategorySearch): null | string => {
  if (search && search.key) {
    let categoryKeys;
    if (!Array.isArray(search.key)) {
      categoryKeys = [search.key];
    } else {
      categoryKeys = search.key;
    }
    categoryKeys = categoryKeys.join('","');
    return `key in ("${categoryKeys}")`;
  }

  return null;
};

const buildCustomizeCategoryWhere = (search: CustomizeCategorySearch): null | string => {
  if (search && search.id) {
    return `id="${search.id}"`;
  }

  return null;
};

const buildCustomizeSubCategoryWhere = (search: CustomizeCategorySearch): null | string => {
  if (search && search.id) {
    let categoryParentId;
    if (!Array.isArray(search.id)) {
      categoryParentId = [search.id];
    } else {
      categoryParentId = search.id;
    }
    categoryParentId = categoryParentId.join('","');
    return `parent(id in ("${categoryParentId}"))`;
    // return `parent(id="${search.id}")`;
  }

  return null;
};

const buildInventoryWhere = (search: ProductInventorySearch): null | string => {
  if (search && search.skus && search.supplyChannels) {
    let productSkus;
    let productChannels;
    let encodedProductChannels: string | string[];
    if (!Array.isArray(search.skus)) {
      productSkus = [search.skus];
    } else {
      productSkus = search.skus;
    }
    if (!Array.isArray(search.supplyChannels)) {
      productChannels = [search.supplyChannels];
    } else {
      productChannels = search.supplyChannels;
    }
    productSkus = productSkus.join(',');
    encodedProductChannels = productChannels.map((ch) => {
      return `\\"${ch}\\"`;
    });
    encodedProductChannels = encodedProductChannels.join(',');
    return `sku in (${productSkus}) and supplyChannel(id in (${encodedProductChannels}))`;
  }

  if (search && search.id) {
    return `id="${search.id}"`;
  }

  return null;
};

export { buildOrderWhere, buildProductWhere, buildInventoryWhere, buildCategoryWhere, buildCustomizeCategoryWhere, buildCustomizeSubCategoryWhere };
