import { Store } from '../../schema/schema';
import { ServiceBase } from '../serviceBase';
import { storeByKeyQuery } from './query';

export class StoreService extends ServiceBase {
  getStore = async (storeKey: string, locale: string = 'en-US'): Promise<Store | undefined> => {
    const response = await this.executeRequest<'store', Store>(storeByKeyQuery, { storeKey, locale });

    return response.store;
  };
}
