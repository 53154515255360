export const ChannelFragment = `
  fragment DefaultChannel on Channel {
    id
    key
    name(locale: $locale)
    description(locale: $locale)
    custom {
      customFieldsRaw {
        name
        value
      }
    }
  }
`;
