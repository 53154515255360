import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { debug } from 'debug';
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { QueryResponse } from '../schema/types';
import { withRetry } from '../utils/helpers';
import logger from '../utils/logger';

export class ServiceBase {
  protected apiClient: GraphQLClient;
  protected authToken: () => Promise<string>;
  private debugger = debug('commercetools');

  constructor(config: { apiUrl: string; authToken: () => Promise<string> }) {
    this.apiClient = new GraphQLClient(`${config.apiUrl}/graphql`, {
      responseMiddleware: (response) => {
        if (!(response instanceof Error)) {
          if (response.errors) {
            logger.error(
              `Request error:
            status ${response.status}
            details: ${response.errors.map((_) => _.message).join(`, `)}`,
            );
          }
          this.debugger(
            `${response.status} (operationName:'${response.headers.get('x-graphql-top-level-fields')}' complexity:${response.headers.get(
              'x-graphql-query-complexity',
            )})`,
          );
        }
      },
      requestMiddleware: async (request) => {
        const bearerToken = await this.authToken();
        if (!bearerToken) {
          throw new Error('authorization token is required');
        }
        const requestBody = JSON.parse(String(request.body));
        this.debugger(
          `${request.method?.toUpperCase()} ${`${config.apiUrl}/graphql`} operationName:'${requestBody.operationName}' variables:${JSON.stringify(
            requestBody.variables,
          )}`,
        );
        return {
          ...request,
          headers: {
            ...request.headers,
            authorization: `Bearer ${bearerToken}`,
          },
        };
      },
    });
    this.authToken = config.authToken;
  }

  protected executeRequest = async <K extends string, T>(
    document: RequestDocument | TypedDocumentNode<QueryResponse<K, T>, Variables>,
    { version, ...params }: Variables,
    signal?: AbortSignal,
  ): Promise<QueryResponse<K, T>> => {
    if (version) {
      return await withRetry(
        async (currentVersion) =>
          await this.apiClient.request<QueryResponse<K, T>>({
            document,
            variables: { ...params, version: currentVersion },
            signal: signal as RequestInit['signal'],
          }),
        Number(version),
      );
    } else {
      return await withRetry(
        async () => await this.apiClient.request<QueryResponse<K, T>>({ document, variables: { ...params }, signal: signal as RequestInit['signal'] }),
      );
    }
  };
}
