import { CancelToken } from 'axios';
import { DateRange, Order, OrderType, PagedArray, ShippingMethodType } from '../../types';
import { toOrder } from './mappers';
import { ServiceBaseWithAuth } from './serviceBaseWithAuth';
import { PagedResponse, Response } from './types/common';
import { Order as bwOrder } from './types/order';

export type OrderSearchParams = { orderType?: (keyof typeof OrderType)[]; fulfillmentType?: (keyof typeof ShippingMethodType)[]; dateRange: DateRange };

export class OrderService extends ServiceBaseWithAuth {
  fetchOrders = async (offset: number = 0, rows: number = 25, params: OrderSearchParams, cancelToken?: CancelToken): Promise<PagedArray<Order>> => {
    const { dateRange, orderType, fulfillmentType } = params;
    const response = await this.executeRequest<'Orders', PagedResponse<bwOrder>>(
      '/customer/orders/get',
      {
        UserToken: await this.authToken(),
        PageNumber: offset >= rows ? Math.ceil(offset / rows) + 1 : 1,
        PageSize: rows,
        StartDate: dateRange.startDate,
        EndDate: dateRange.endDate,
        OrderTypes: orderType?.map((x) => OrderType[x]),
        ShipmentMethods: fulfillmentType?.map((x) => ShippingMethodType[x]),
      },
      cancelToken,
    );

    return { data: response.Orders.Data.map(toOrder), offset: offset, limit: rows, total: response.Orders.TotalCount };
  };

  getOrder = async (orderNumber: string): Promise<Order> => {
    const response = await this.executeRequest<'Order', bwOrder>('/customer/order/get', {
      UserToken: await this.authToken(),
      ExtOrderId: orderNumber,
    });
    return toOrder(response.Order);
  };

  cancelOrder = async (orderNumber: string): Promise<boolean> => {
    const response = await this.executeRawRequest<{ Response?: Response }>('/customer/order/cancel', {
      UserToken: await this.authToken(),
      ExtOrderId: orderNumber,
    });
    return response?.Response?.HasException ?? false;
  };
}
