/* eslint-disable no-unused-vars */
export enum AuthenticationType {
  FINGERPRINT = 1,
  FACIAL_RECOGNITION = 2,
  IRIS = 3,
}

export type DeviceInfo = {
  supportsBiometrics: boolean;
  biometricsType?: AuthenticationType[];
  hasLocationPermissions: boolean;
  hasNotificationPermissions: boolean;
  requestPermissions: boolean;
  hasCameraPermission: boolean;
};
