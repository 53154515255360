import { gql } from 'graphql-request';
import { NonSellableProductFragment } from 'src/fragments';

const queryNonSellableProducts = gql`
  ${NonSellableProductFragment}

  query products(
    $where: String
    $limit: Int
    $offset: Int
    $locale: Locale!
    $country: Country!
    $currency: Currency!
    $skus: [String!]
    $sort: [String!]
    $channelId: String
    $includeChannelIds: [String!]
  ) {
    products(where: $where, sort: $sort, limit: $limit, offset: $offset, skus: $skus) {
      offset
      count
      total
      results {
        ...DefaultNonsellableProduct
      }
    }
  }
`;

export { queryNonSellableProducts };
