/* eslint-disable no-unused-vars */
import { Address } from './address';
import { Lookup } from './common';

export enum CardMaskType {
  Full = 1,
  Last5 = 2,
  Last4 = 3,
}

export interface PaymentProfile {
  CustomerPaymentMethodId?: number;
  CardNumber?: string;
  CardMaskValues?: Lookup[];
  CardExpirationDate?: string;
  ExpirationMonth?: string;
  ExpirationYear?: string;
  CardType?: string;
  CreditCardTypeId?: 1 | 2 | 3 | 4; //1 – Visa,   2 – MasterCard,   3 – American Express,   4 – Discover
  CVV?: number;
  IsDefault?: boolean;
  BillingAddress?: BillingAddress;
}

export interface BillingAddress {
  PhoneNumber?: string;
  Address1?: string;
  FirstName?: string;
  LastName?: string;
  Region?: Lookup;
  City?: string;
  PostalCode?: string;
  Country?: string;
  IsDefaultBilling?: boolean;
  CustomerAddressId?: number;
}

export interface PaymentMethod {
  CustomerId: number;
  CustomerPaymentMethodId: number;
  EmailAddress: any;
  PaymentMethodTypeId: number;
  PaymentMethodType: Lookup;
  BillingAddressId: number;
  BillingAddress: Address;
  ReplenishmentPaymentMethodId: any;
  ReplenishmentPaymentMethod: any;
  CustomerPaymentMethodName: string;
  IsActive: boolean;
  IsDefault: boolean;
  PaymentMethodHtml: any;
  CreatedDate: string;
  RevisedDate: string;
  CreditCardType: Lookup;
  CreditCardTypeId: number;
  ExpirationMonth: number;
  ExpirationYear: number;
  CVV: any;
  CreditCardMaskValue: string;
  CardNumber: any;
  IsCVVConfirmed: boolean;
  CVVConfirmedDate: string;
  BankAccountType: any;
  AccountNumber: any;
  AccountNumberMaskValue: any;
  RoutingNumberMaskValue: any;
  RoutingNumber: any;
  BankName: any;
  ExtCustomerTokenId: string;
  ExtPaymentTokenId: string;
  ACHPaymentMethodDetails: any;
  CreditCardPaymentMethodDetails: any;
  CurrentPromoCode: any;
  NewPromoCode: any;
  Response: any;
}
