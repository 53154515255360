import { ApisauceInstance, create as apisauce } from 'apisauce';
import { addLogger } from '../../utils';
import { ContentDocument } from './types';

const __DEV__ = (() => !process.env.NODE_ENV || process.env.NODE_ENV === 'development')();

export class BrxmService {
  private apiClient: ApisauceInstance;

  constructor(config: { apiUrl: string }) {    
    this.apiClient = apisauce({ baseURL: config.apiUrl, timeout: 30000, headers: {Accept: 'application/hal+json'}});

    if (__DEV__) {
      const axiosLogger = require('debug')('api:brxm');
      addLogger(this.apiClient.axiosInstance, axiosLogger);
    }
  }

  getDocument = async (documentType: string, query: string): Promise<ContentDocument | undefined> => {
    const params: Record<string, string | number | undefined> = {
      _q: query,
    };

    const response = await this.apiClient.get<ContentDocument>(documentType, params);
    if (response.ok) {
      return response.data;
    } else {
      throw new Error(response.problem);
    }
  };

}
