import { AvailablityResult, AvailablityStatus, defaultMoney, getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import {
  BaseMoney,
  Category,
  CateringStatus,
  InventoryMode,
  LineItem,
  Product,
  ProductCatalogData,
  ProductData,
  ProductVariant,
  ProductVariantAvailability,
  RawCustomField,
  RawProductAttribute,
  ShoppingListLineItem,
  TextLineItem,
} from '@fieldera-raleys/client-commercetools/schema';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Promotion } from 'src/services/brandywine/types';
import { moneyValue } from './helpers';
import logger from './logger';

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_QUANTITY = 999;
export const getCustomField = (name: string, attributes: RawCustomField[]) => {
  return attributes.find((x: RawCustomField) => x.name === name);
};
export const getCustomFieldValue = (name: string, attributes: RawCustomField[]) => getCustomField(name, attributes)?.value;

export const getRootCategory = (categories?: Category[]) => {
  if (!categories || !categories.length) {
    return undefined;
  }
  const root = categories[0].ancestors.find((a) => !a.parent);
  return root ? categories[0].ancestors.find((r) => r.parent?.key === root?.key) : root;
};

export const getProductAisleBayBin = (product: ProductData | LineItem | ShoppingListLineItem | TextLineItem) => {
  let pricingAttributes: RawCustomField[] | undefined;
  if ((<ProductData>(<unknown>product)).masterVariant) {
    pricingAttributes = (<ProductData>(<unknown>product)).masterVariant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  if ((<LineItem>(<unknown>product)).variant) {
    pricingAttributes = (<LineItem>(<unknown>product))?.variant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  return pricingAttributes?.find((f) => f.name === 'areaBayShelfBin')?.value ?? undefined;
};
const getAvailablityStatus = (availableQuantity: number, cateringStatus?: CateringStatus, lowInventoryThreshold = 25): AvailablityStatus => {
  if (!cateringStatus) {
    return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= +(lowInventoryThreshold ?? 25) ? 'Available' : 'LowStock';
  }

  switch (cateringStatus) {
    case 'retired':
      return 'Unavailable';
    case 'closed':
    case 'pending':
      return 'OutOfStock';
    default:
      return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= +(lowInventoryThreshold ?? 25) ? 'Available' : 'LowStock';
  }
};

const findProductVariant = (sku: string, product?: ProductCatalogData): ProductVariant | undefined => {
  if (!product) {
    return undefined;
  }

  if (product.current?.masterVariant.sku === sku) {
    return product.current?.masterVariant;
  } else {
    return product.current?.variants.find((p) => p.sku === sku);
  }
};

/**
 *
 * @param product  ProductCatalogData | null | ShoppingListLineItem
 * @param sku  string
 * @param lowInventoryThreshold  number = 25
 * @returns  AvailablityResult
 */

export const getProductAvailablity = (
  product?: ProductCatalogData | null | ShoppingListLineItem,
  sku?: string,
  lowInventoryThreshold = 25,
  timezone: string = 'America/Los_Angeles'
): AvailablityResult => {
  const result: AvailablityResult = {
    availability: 'Unavailable',
    productSku: sku,
  };

  if (!product) {
    return result;
  }
  if ('current' in product && !product.published) {
    return result;
  }

  let inventoryMode: 'None' | 'ReserveOnOrder' | 'TrackOnly' = 'None';
  let discontinued = false;
  let availability: ProductVariantAvailability | undefined;
  let cateringStatus: CateringStatus = 'open';
  let hasPriceChannel = false;
  let priceCustomFields: RawCustomField[] = [];
  let priceChannelCustomFields: RawCustomField[] = [];
  let productAttributes: RawProductAttribute[] = [];

  if ('current' in product) {
    const variant = sku ? findProductVariant(sku, product) : product.current?.masterVariant;
    hasPriceChannel = !!variant?.price?.channel ?? false;
    priceCustomFields = variant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = variant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = variant?.attributesRaw ?? [];
    availability =
      (variant?.availability?.channels?.results?.length ?? 0) > 0 ? variant?.availability?.channels.results[0].availability ?? undefined : undefined;
  } else if ('variant' in product) {
    hasPriceChannel = !!product.variant?.price?.channel ?? false;
    priceCustomFields = product.variant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = product.variant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = product.variant?.attributesRaw ?? [];
    availability =
      (product.variant?.availability?.channels?.results?.length ?? 0) > 0
        ? product.variant?.availability?.channels.results[0].availability ?? undefined
        : undefined;
  }
  const isCateringManagerItem = Boolean(getProductAttributeValue('isCateringManagerItem', productAttributes) ?? false);
  cateringStatus = getCustomFieldValue('cateringStatus', priceChannelCustomFields);
  inventoryMode = getCustomFieldValue('inventoryMode', priceCustomFields) ?? 'None';
  discontinued = Boolean(getCustomFieldValue('discontinued', priceCustomFields) ?? false);

  result.inventoryMode = inventoryMode as InventoryMode;

  if (!hasPriceChannel || discontinued || (inventoryMode === 'ReserveOnOrder' && !availability)) {
    result.availability =
      hasPriceChannel && discontinued
        ? 'Discontinued'
        : hasPriceChannel && inventoryMode === 'ReserveOnOrder' && !availability
          ? 'OutOfStock'
          : 'Unavailable';
    return result;
  }

  const now = dayjs();
  // check if there is a Pre Order restriction
  let val = getProductAttributeValue('preorderDate', productAttributes);
  if (val) {
    try {
      const preOrder = dayjs.tz(val, timezone);
      if (preOrder.diff(now, 'minutes') >= 0) {
        return { ...result, availabilityDate: preOrder };
      }
    } catch {
      logger.error(['Failed to parse preorderDate attribute:', val].join(' '));
      return result;
    }
  }

  // skip start available day restrictions, treating it as additional lead time of in future
  val = getProductAttributeValue('endAvailableOrderDate', productAttributes);
  if (val) {
    try {
      const endAvailability = dayjs.tz(val, timezone);
      if (endAvailability.diff(now, 'minutes') <= 0) {
        return result;
      }
    } catch {
      logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
      return result;
    }
  }

  let availabilityStatus: AvailablityStatus | undefined = inventoryMode !== 'ReserveOnOrder' ? 'Available' : undefined;
  if (isCateringManagerItem) {
    availabilityStatus = getAvailablityStatus(
      inventoryMode === 'ReserveOnOrder' ? availability?.availableQuantity ?? 0 : DEFAULT_QUANTITY,
      cateringStatus,
      lowInventoryThreshold,
    );
  } else if (!availabilityStatus) {
    availabilityStatus = getAvailablityStatus(availability?.availableQuantity ?? 0, undefined, lowInventoryThreshold);
  }
  // else just check the quantity
  return {
    availability: availabilityStatus,
    quantity: inventoryMode === 'ReserveOnOrder' ? availability?.availableQuantity : DEFAULT_QUANTITY,
    inventoryMode: inventoryMode as InventoryMode,
    productSku: sku,
  };
};

export const getStartsAtPrice = (productData: Product) => {
  const masterVariant = productData.masterData.current?.masterVariant;

  // Check if masterVariant and its price are valid
  if (!masterVariant || masterVariant.price === null) {
    return;
  }

  const masterPrice =
    productData.masterData.current?.masterVariant.price?.value.centAmount /
    10 ** (productData.masterData.current?.masterVariant.price?.value.fractionDigits ?? 2);
  const variants = productData.masterData.current?.variants;
  let startingPrice = masterPrice;

  variants?.forEach((v) => {
    const vPrice = v.price?.value.centAmount / 10 ** (v.price?.value.fractionDigits ?? 2);
    if (+vPrice < +startingPrice) {
      startingPrice = vPrice;
    }
  });
  return `$${startingPrice.toFixed(2)}`;
};

export const getQuantityFromPriceMessage = (priceMessage: string): number => {
  const match = /(you\s)?save\s\$\d*\.\d{2}\s(on|for)\s\d+$/i.exec(priceMessage);

  if (match) {
    const messages = priceMessage.split(' ');
    const qty = Number(messages[messages.length - 1]);

    return qty;
  }

  return 1;
};

export const getDisplayPriceBySellTypeAndPriceMessage = (sellType: string, priceMessage: string, price: BaseMoney | undefined, avgBuyWeight?: number) => {
  if (!price) {
    return undefined;
  }

  const qty = getQuantityFromPriceMessage(priceMessage);
  let finalPrice = price.centAmount * qty;

  if (sellType === 'weightByEach') {
    if (!avgBuyWeight) {
      avgBuyWeight = 1;
      logger.error('weightByEach requires the unitAverageBuyWeight');
      //throw 'weightByEach requires the unitAverageBuyWeight';
    }
    finalPrice *= avgBuyWeight;
  }

  finalPrice /= 10 ** (price.fractionDigits ?? 2);
  return `${qty} for $${finalPrice.toFixed(2)}`;
};

export const filterUnavailableProducts = (products: Product[]) => {
  return products.filter((p) => p?.masterData?.current?.masterVariant.price !== null && p?.masterData.published);
};

export const getDisplayPriceBySellType = (
  sellType: string,
  price: BaseMoney | undefined,
  avgBuyWeight?: number,
  qty: number = 1,
  prefixCurrencyCode: boolean = true,
) => {
  if (!price) {
    return undefined;
  }

  let finalPrice = price.centAmount * qty;
  if (sellType === 'weightByEach') {
    if (!avgBuyWeight) {
      avgBuyWeight = 1;
      logger.warn('weightByEach requires the unitAverageBuyWeight');
    }
    finalPrice *= avgBuyWeight;
  }

  if (prefixCurrencyCode) {
    return moneyValue({ ...defaultMoney, centAmount: finalPrice });
  }
  finalPrice /= 10 ** (price.fractionDigits ?? 2);
  return finalPrice.toFixed(2);
};

export type ProductPromotion = {
  flagHeadline: string;
  badgeType: string;
  autoApply: boolean;
  isAccepted: boolean;
  sku: string;
  extFlagTypeCode: string;
};

export interface ProductList {
  sku: string;
  product: Product;
  promotion: ProductPromotion;
}

//getProductPromotions
export const getPromotionByProductList = (
  productData: Product[] | undefined,
  productFlagTypes: string[],
  productBadgeTypes: string[],
  promotionData: { AvailablePromotions: Promotion[]; AcceptedPromotions: Promotion[] } | undefined,
) => {
  const upl: ProductList[] = [];
  productData?.map((lp) => {
    const productPromotion: ProductPromotion = {
      flagHeadline: '',
      badgeType: '',
      autoApply: false,
      isAccepted: false,
      sku: '',
      extFlagTypeCode: '',
    };

    const sku = lp.masterData.current?.masterVariant.sku ?? '';

    const avp = promotionData?.AvailablePromotions?.filter((p) => p?.ProductList?.includes(sku)) ?? [];
    const acp = promotionData?.AcceptedPromotions?.filter((p) => p?.ProductList?.includes(sku)) ?? [];
    const allPromotions = { AvailablePromotions: avp, AcceptedPromotions: acp };

    const flagsPromotions = allPromotions?.AvailablePromotions.filter((p) => p.AutoApply && p.ExtFlagTypeCode).sort(function (p1, p2) {
      return productFlagTypes.indexOf(p1.ExtFlagTypeCode?.toUpperCase() ?? '') - productFlagTypes.indexOf(p2.ExtFlagTypeCode?.toUpperCase() ?? '');
    });

    const availableBadgePromotions = allPromotions?.AvailablePromotions.filter((p) => p.AutoApply !== true && p.ExtBadgeTypeCode).sort(function (p1, p2) {
      return productBadgeTypes.indexOf(p1?.ExtBadgeTypeCode?.toLowerCase() ?? '') - productBadgeTypes.indexOf(p2?.ExtBadgeTypeCode?.toLowerCase() ?? '');
    });

    if (flagsPromotions.length > 0) {
      productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
      productPromotion.autoApply = flagsPromotions[0].AutoApply ?? true;
      productPromotion.extFlagTypeCode = flagsPromotions[0].ExtFlagTypeCode ?? '';
      productPromotion.flagHeadline = flagsPromotions[0].Headline ?? '';
      productPromotion.isAccepted = false;
    }

    if (availableBadgePromotions.length > 0) {
      productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
      productPromotion.badgeType = availableBadgePromotions[0].ExtBadgeTypeCode ?? '';
      productPromotion.isAccepted = false;
    }

    if ((productPromotion.badgeType ?? '').trim().length === 0) {
      const acceptedBadgePromotions = allPromotions?.AcceptedPromotions.filter((p) => p.ExtBadgeTypeCode).sort(function (p1, p2) {
        return productBadgeTypes.indexOf(p1.ExtBadgeTypeCode?.toLowerCase() ?? '') - productBadgeTypes.indexOf(p2.ExtBadgeTypeCode?.toLowerCase() ?? '');
      });

      if (acceptedBadgePromotions.length > 0) {
        productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
        productPromotion.badgeType = acceptedBadgePromotions[0].ExtBadgeTypeCode ?? '';
        productPromotion.isAccepted = true;
      }
    }

    upl.push({ sku: sku, product: lp, promotion: productPromotion });
  });
  return upl;
};

export const getEndAvailabilityDate = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant, timezone: string = 'America/Los_Angeles'): dayjs.Dayjs | undefined => {
  if (!product) {
    return undefined;
  }
  let endDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
        ? (product.variant as ProductVariant)
        : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'endAvailableOrderDate')?.value;
  }

  if (val) {
    try {
      endDate = dayjs.tz(val, timezone);
    } catch (ex) {
      logger.error(ex, { attribute: 'startAvailableOrderDate', value: ''+val, timezone});
    }
  }

  return endDate;
};

export const isAvailablePastDate = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant, orderDate?: dayjs.Dayjs, timezone: string = 'America/Los_Angeles'): boolean => {
  if (!product || !orderDate) {
    return false;
  }

  let endAvailableDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product?.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
        ? (product.variant as ProductVariant)
        : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'endAvailableOrderDate')?.value;
    if (!val) {
      // no end date
      return true;
    }
  }

  try {
    endAvailableDate = dayjs.tz(val, timezone);
  } catch {
    logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
  }

  if (endAvailableDate) {
    const diff = endAvailableDate.diff(orderDate, 'minutes');
    if (diff > 0) {
      return true;
    }
  }

  return false;
};
