import dayjs from 'dayjs';
import { InventoryMode, ProductData, RawCustomField, RawProductAttribute, ShoppingListLineItem } from '../../schema/schema';
import { CateringStatus } from '../../schema/types';
import logger from '../../utils/logger';

export type AvailablityStatus = 'OutOfStock' | 'Unavailable' | 'LowStock' | 'Available' | 'Discontinued';
export type AvailablityResult = {
  availability: AvailablityStatus;
  quantity?: number;
  availabilityDate?: dayjs.Dayjs;
  inventoryMode?: InventoryMode;
  productSku?: string;
};

const DEFAULT_QUANTITY = 999;

const getAvailablityStatus = (availableQuantity: number, cateringStatus?: CateringStatus, lowInventoryThreshold?: number): AvailablityStatus => {
  if (!cateringStatus) {
    return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= (lowInventoryThreshold ?? 25) ? 'Available' : 'LowStock';
  }

  switch (cateringStatus) {
    case 'retired':
      return 'Unavailable';
    case 'closed':
    case 'pending':
      return 'OutOfStock';
    default:
      return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= (lowInventoryThreshold ?? 25) ? 'Available' : 'LowStock';
  }
};

const getCustomField = (name: string, attributes: RawCustomField[]) => {
  return attributes.find((x) => x.name === name);
};

const getCustomFieldValue = (name: string, attributes: RawCustomField[]) => getCustomField(name, attributes)?.value;

export const getProductAttribute = (name: string, attributes: RawProductAttribute[]) => {
  return attributes.find((x) => x.name === name);
};

export const getProductAttributeValue = (name: string, attributes: RawProductAttribute[]) => getProductAttribute(name, attributes)?.value;

export const getProductAvailablity = (product?: ProductData | null | ShoppingListLineItem): AvailablityResult => {
  const result: AvailablityResult = {
    availability: 'Unavailable',
  };

  if (!product) {
    return result;
  }

  let inventoryMode: InventoryMode = InventoryMode.None;
  let discontinued = false;
  let cateringStatus: CateringStatus = 'open';
  let hasPriceChannel = false;
  let priceCustomFields: RawCustomField[] = [];
  let priceChannelCustomFields: RawCustomField[] = [];
  let productAttributes: RawProductAttribute[] = [];

  if ('masterVariant' in product) {
    hasPriceChannel = !!product.masterVariant?.price?.channel ?? false;
    priceCustomFields = product.masterVariant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = product.masterVariant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = product.masterVariant.attributesRaw ?? [];
  } else if ('variant' in product) {
    hasPriceChannel = !!product.variant?.price?.channel ?? false;
    priceCustomFields = product.variant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = product.variant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = product.variant?.attributesRaw ?? [];
  }
  const isCateringManagerItem = Boolean(getProductAttributeValue('isCateringManagerItem', productAttributes) ?? false);
  cateringStatus = getCustomFieldValue('cateringStatus', priceChannelCustomFields);
  inventoryMode = getCustomFieldValue('inventoryMode', priceCustomFields) ?? InventoryMode.None;
  discontinued = Boolean(getCustomFieldValue('discontinued', priceCustomFields) ?? false);
  const availability =
    (product.variant?.availability?.channels?.results?.length ?? 0) > 0
      ? product.variant?.availability?.channels.results[0].availability ?? undefined
      : undefined;

  result.inventoryMode = inventoryMode;

  if (!hasPriceChannel || discontinued || (inventoryMode === InventoryMode.ReserveOnOrder && !availability)) {
    result.availability =
      hasPriceChannel && discontinued
        ? 'Discontinued'
        : hasPriceChannel && inventoryMode === InventoryMode.ReserveOnOrder && !availability
          ? 'OutOfStock'
          : 'Unavailable';
    return result;
  }

  const now = dayjs();
  // check if there is a Pre Order restriction
  let val = getProductAttributeValue('preorderDate', productAttributes);
  if (val) {
    try {
      const preOrder = dayjs(val);
      if (preOrder.diff(now, 'minutes') >= 0) {
        return { ...result, availabilityDate: preOrder };
      }
    } catch {
      logger.error(['Failed to parse preorderDate attribute:', val].join(' '));
      return result;
    }
  }

  // skip start available day restrictions, treating it as additional lead time of in future
  val = getProductAttributeValue('endAvailableOrderDate', productAttributes);
  if (val) {
    try {
      const endAvailability = dayjs(val);
      if (endAvailability.diff(now, 'minutes') <= 0) {
        return result;
      }
    } catch {
      logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
      return result;
    }
  }

  let availabilityStatus: AvailablityStatus | undefined = inventoryMode !== InventoryMode.ReserveOnOrder ? 'Available' : undefined;
  if (isCateringManagerItem) {
    availabilityStatus = getAvailablityStatus(
      inventoryMode === InventoryMode.ReserveOnOrder ? availability?.availableQuantity ?? 0 : DEFAULT_QUANTITY,
      cateringStatus,
    );
  } else if (!availabilityStatus) {
    availabilityStatus = getAvailablityStatus(availability?.availableQuantity ?? 0);
  }
  // else just check the quantity
  return {
    availability: availabilityStatus,
    quantity: inventoryMode === InventoryMode.ReserveOnOrder ? availability?.availableQuantity : DEFAULT_QUANTITY,
    inventoryMode,
  };
};
