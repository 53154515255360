import { ChannelFragment } from './channel';
import { ProductPriceFragment } from './productPrice';

export const ProductVariantImagesFragment = `
fragment Images on ProductVariant {
  images {
    url
    label
  }
}`;

export const ProductVariantPriceFragment = `
${ProductPriceFragment}

fragment Price on ProductVariant {
  price(currency: $currency, country: $country,channelId: $channelId) {
    ...DefaultProductPrice
  }
}`;

export const ProductVariantPricesFragment = `
fragment Prices on ProductVariant {
  prices {
    ...DefaultProductPrice
  }
}`;

export const ProductVariantAttributesFragment = `
fragment Attributes on ProductVariant {
  attributesRaw {
    name
    value
  }
}`;

export const ProductVariantAvailablity = `
${ChannelFragment}

fragment Availability on ProductVariant {
  availability {
    channels(includeChannelIds: $includeChannelIds) {
      results {
        availability {
          id
          isOnStock
          restockableInDays
          availableQuantity
        }
        channel {
          ...DefaultChannel
        }
      }
    }
  }
}`;

export const ProductVariantFragment = `
${ProductVariantImagesFragment}
${ProductVariantPriceFragment}
${ProductVariantAttributesFragment}
${ProductVariantAvailablity}

fragment DefaultVariant on ProductVariant {
  id
  sku
  ...Images
  ...Price
  ...Attributes
  ...Availability
}`;

export const NonSellableProductVariantFragment = `
${ProductVariantImagesFragment}
${ProductVariantPriceFragment}
${ProductVariantPricesFragment}
${ProductVariantAttributesFragment}
${ProductVariantAvailablity}

fragment DefaultNonsellableVariant on ProductVariant {
  id
  sku
  ...Images
  ...Price
  ...Prices
  ...Attributes
  ...Availability
}`;
