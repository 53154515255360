import { CustomerFragment } from './customer';
import { ProductVariantFragment, ProductVariantPriceFragment } from './productVariant';

const ShoppingListLineItemFragment = `
${ProductVariantFragment}

fragment DefaultShoppingListLineItem on ShoppingListLineItem {
  __typename
  id
  productId
  name(locale: $locale)
  productSlug(locale: $locale)
  productType {
    id
    key
    name
  }
  addedAt
  quantity
  custom {
    customFieldsRaw {
      name
      value
    }
  }
  variant {
      ...DefaultVariant
  }
}`;

const ShoppingListTextLineItemFragment = `
fragment DefaultShoppingListTextLineItem on TextLineItem {
  id
  name(locale: $locale)
  description(locale: $locale)
  addedAt
  quantity
  custom {
    customFieldsRaw {
      name
      value
    }
  }
}`;

export const ShoppingListMinimalFragment = `
${ProductVariantPriceFragment}

fragment MinimalShoppingList on ShoppingList {
  id
  key
  version
  name(locale: "en")
  lineItems {
    id
    name(locale: $locale)
    quantity
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    variant {
      sku
      ...Price
    }
  }
  textLineItems {
    id
    name(locale: $locale)
    quantity
    custom {
      customFieldsRaw {
        name
        value
      }
    }
  }
}`;

export const ShoppingListFragment = `
${ShoppingListLineItemFragment}
${CustomerFragment}
${ShoppingListTextLineItemFragment}

fragment DefaultShoppingList on ShoppingList {
  id
  key
  version
  name(locale: "en")
  description(locale: "en")
  deleteDaysAfterLastModification
  lineItems {
    ...DefaultShoppingListLineItem
  }
  textLineItems {
    ...DefaultShoppingListTextLineItem
  }
  customer {
    ...DefaultCustomer
  }
}`;
