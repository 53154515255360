import { CancelToken } from 'axios';
import { constants } from '../../constants';
import { Address, CustomerAlternateContact, Store, UserProfile } from '../../types';
import { toAddress, toAlternateContact, toCustomer, toStore, toUserProfile } from './mappers';
import { ServiceBaseWithAuth } from './serviceBaseWithAuth';
import { Adjustment, Customer, CustomerAlternateContactDto, PointsBalance } from './types';
import { Address as AddressDto } from './types/address';
import { AccessToken, Response as bwResponse } from './types/common';
import { FuelReward, FuelRewardsSortOrder } from './types/fuelReward';
import { PaymentProfile } from './types/payment';
import { StorePreference } from './types/store';

const PREVIOUSLY_SHOPPED_STORE_PREFERENCE_TYPE_ID = 2;
const FAVORITE_STORE_PREFERENCE_TYPE_ID = 1;

export class UserService extends ServiceBaseWithAuth {
  toAddressDto = (address: Address): AddressDto => {
    const region = constants.States.find((x) => x.abbreviation === address?.state);
    return {
      Address1: address.address1,
      Address2: address.address2,
      City: address.city,
      CompanyName: address.companyName,
      CustomerAddressId: address.id,
      FirstName: address.firstName,
      IsDefaultBilling: address.isDefaultBilling,
      IsDefaultShipping: address.isDefaultShipping,
      IsValidated: address.isValidated,
      LastName: address.lastName,
      PhoneNumber: address.phone,
      PostalCode: address.postalCode,
      Region: { Id: region?.id, Value: region?.name },
      AddressType: { Id: address.addressType === 'home' ? '1' : '2', Value: address.addressType },
      Latitude: address.latitude,
      Longitude: address.longitude,
      ValidatedDate: address.validatedDate,
    } as AddressDto;
  };

  // Customer Payment profile CRUD
  //https://developer.authorize.net/api/reference/features/customer-profiles.html#settings
  getPaymentProfileToken = async (
    hostedProfileSettings?: Partial<
      Record<
        | 'hostedProfileSaveButtonText'
        | 'hostedProfileReturnUrl'
        | 'hostedProfileReturnUrlText'
        | 'hostedProfileHeadingBgColor'
        | 'hostedProfilePageBorderVisible'
        | 'hostedProfileIFrameCommunicatorUrl'
        | 'hostedProfilePaymentOptions'
        | 'hostedProfileValidationMode'
        | 'hostedProfileBillingAddressRequired'
        | 'hostedProfileCardCodeRequired'
        | 'hostedProfileBillingAddressOptions'
        | 'hostedProfileManageOptions',
        string
      >
    >,
    cts?: CancelToken,
  ): Promise<string | undefined> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Token', string>(
      '/customer/paymentprofilepage/token/get',
      { UserToken: userToken, ProfileSettings: hostedProfileSettings ? { ...hostedProfileSettings } : undefined },
      cts,
    );
    return response.Token;
  };

  syncPaymentProfile = async (cts?: CancelToken): Promise<boolean> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Success', boolean>('/customer/paymentprofilepage/sync', { UserToken: userToken }, cts);
    return response.Success;
  };

  // Customer Payment profile CRUD
  getPaymentProfiles = async (cts?: CancelToken): Promise<PaymentProfile[]> => {
    const userToken = await this.authToken();
    try {
      const response = await this.executeRequest<'Payments', PaymentProfile[]>('/customer/creditcard/paymentprofiles/get', JSON.stringify(userToken), cts);
      return response.Payments;
    } catch (ex: any) {
      if (ex.message && ex.message.indexOf('E00040') >= 0) {
        return [];
      }
      throw ex;
    }
  };

  getPaymentProfileByPaymentMethodId = async (customerPaymentMethodId: number): Promise<PaymentProfile> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Payment', PaymentProfile>('/customer/creditcard/paymentprofile/get', {
      UserToken: userToken,
      CustomerPaymentMethodId: customerPaymentMethodId,
    });
    return response.Payment;
  };

  createPaymentProfile = async (paymentInfo: PaymentProfile): Promise<number> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'CustomerPaymentMethodId', number>('/customer/creditcard/tokenize', {
      UserToken: userToken,
      Payment: paymentInfo,
    });
    return response.CustomerPaymentMethodId;
  };

  updatePaymentProfile = async (profile: PaymentProfile): Promise<number> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'CustomerPaymentMethodId', number>('/customer/creditcard/update', {
      UserToken: userToken,
      Payment: profile,
    });
    return response.CustomerPaymentMethodId;
  };

  deletePaymentProfile = async (profile: PaymentProfile): Promise<boolean> => {
    const userToken = await this.authToken();
    await this.executeRequest<'CustomerPaymentMethodId', string>('/customer/paymentprofile/delete', {
      UserToken: userToken,
      Payment: profile,
    });
    return true;
  };

  authorizePayment = async (customerPaymentMethodId: number, amount: number): Promise<{ AuthCode: string; TransactionId: string }> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'AuthResponse', { AuthCode: string; TransactionId: string }>('/customer/creditcard/authorize', {
      UserToken: userToken,
      CustomerPaymentMethodId: customerPaymentMethodId,
      PaymentAmount: amount,
    });
    return response.AuthResponse;
  };

  // Customer Adderss CRUD
  getAddressList = async (cts?: CancelToken): Promise<Address[]> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Addresses', AddressDto[]>('/customer/addresses/get', JSON.stringify(userToken), cts);

    return (response.Addresses ?? []).map((a) => toAddress(a));
  };

  getAddressById = async (addressId: string | number): Promise<Address> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Address', AddressDto>('/customer/address/get', {
      UserToken: userToken,
      CustomerAddressId: addressId,
    });
    return toAddress(response.Address);
  };

  addAddress = async (address: Address): Promise<number> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'CustomerAddressId', number>('/customer/address/add', {
      UserToken: userToken,
      Address: this.toAddressDto(address),
    });
    return response.CustomerAddressId;
  };

  updateAddress = async (address: Address): Promise<number> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'CustomerAddressId', number>('/customer/address/update', {
      UserToken: userToken,
      Address: this.toAddressDto(address),
    });

    return response.CustomerAddressId;
  };

  deleteAddress = async (addressId: string | number): Promise<boolean> => {
    const userToken = await this.authToken();
    await this.executeRequest<'CustomerAddressId', number>('customer/address/delete', {
      UserToken: userToken,
      CustomerAddressId: addressId,
    });

    return true;
  };

  //Previous Stores
  addPreviouslyShoppedStore = async (storeNumber: string | number): Promise<boolean> => {
    const userToken = await this.authToken();
    const response = await this.executeRawRequest<{ Response?: bwResponse }>('/customer/storepreference/add', {
      UserToken: userToken,
      StorePreference: { StoreNumber: storeNumber, CustomerDeliverySitePreferenceType: { Id: PREVIOUSLY_SHOPPED_STORE_PREFERENCE_TYPE_ID } },
    });
    if (!response?.Response?.HasException) {
      return true;
    } else {
      throw new Error(response?.Response?.ReturnMessage);
    }
  };

  deletePreviouslyShoppedStore = async (storeNumber: number): Promise<boolean> => {
    const userToken = await this.authToken();
    const response = await this.executeRawRequest<{ Response?: bwResponse }>('/customer/storepreference/delete', {
      UserToken: userToken,
      StorePreference: { StoreNumber: storeNumber, CustomerDeliverySitePreferenceType: { Id: PREVIOUSLY_SHOPPED_STORE_PREFERENCE_TYPE_ID } },
    });
    if (!response?.Response?.HasException) {
      return true;
    } else {
      throw new Error(response?.Response?.ReturnMessage);
    }
  };

  getPreviouslyShoppedStores = async (latitude?: number, longitude?: number, cts?: CancelToken): Promise<Store[] | undefined> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'StorePreferences', StorePreference[]>(
      '/customer/storepreferences/get',
      {
        UserToken: userToken,
        StorePreference: { Latitude: latitude, Longitude: longitude, CustomerDeliverySitePreferenceType: { Id: PREVIOUSLY_SHOPPED_STORE_PREFERENCE_TYPE_ID } },
      },
      cts,
    );
    return response.StorePreferences.map((s) => toStore(s.Store));
  };

  //Favorite Stores
  addFavoriteStore = async (storeNumber: string | number): Promise<boolean> => {
    const userToken = await this.authToken();
    const response = await this.executeRawRequest<{ Response?: bwResponse }>('/customer/storepreference/add', {
      UserToken: userToken,
      StorePreference: { StoreNumber: storeNumber, CustomerDeliverySitePreferenceType: { Id: FAVORITE_STORE_PREFERENCE_TYPE_ID } },
    });
    if (!response?.Response?.HasException) {
      return true;
    } else {
      throw new Error(response?.Response?.ReturnMessage);
    }
  };

  deleteFavoriteStore = async (storeNumber: string | number): Promise<boolean> => {
    const userToken = await this.authToken();
    const response = await this.executeRawRequest<{ Response?: bwResponse }>('/customer/storepreference/delete', {
      UserToken: userToken,
      StorePreference: { StoreNumber: storeNumber, CustomerDeliverySitePreferenceType: { Id: FAVORITE_STORE_PREFERENCE_TYPE_ID } },
    });
    if (!response?.Response?.HasException) {
      return true;
    } else {
      throw new Error(response?.Response?.ReturnMessage);
    }
  };

  getFavoriteStores = async (latitude?: number, longitude?: number, cts?: CancelToken): Promise<Store[] | undefined> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'StorePreferences', StorePreference[]>(
      '/customer/storepreferences/get',
      {
        UserToken: userToken,
        StorePreference: { Latitude: latitude, Longitude: longitude, CustomerDeliverySitePreferenceType: { Id: FAVORITE_STORE_PREFERENCE_TYPE_ID } },
      },
      cts,
    );
    return response.StorePreferences.map((s) => toStore(s.Store));
  };

  getAlternateContacts = async (cts?: CancelToken): Promise<CustomerAlternateContact[]> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'CustomerAlternateContacts', CustomerAlternateContactDto[]>(
      '/customer/alternatecontact/list',
      JSON.stringify(userToken),
      cts,
    );

    return (response.CustomerAlternateContacts ?? []).map((c) => toAlternateContact(c));
  };

  deleteAlternateContact = async (alternateContactId: string | number, extCustomerId: number): Promise<boolean> => {
    const userToken = await this.authToken();
    await this.executeRequest<'CustomerAlternateContactId', string>('/customer/alternatecontact/delete', {
      UserToken: userToken,
      ExtCustomerId: extCustomerId,
      CustomerAlternateContactId: alternateContactId,
    });

    return true;
  };

  addAlternateContact = async (alternateContact: CustomerAlternateContact, extCustomerId: number): Promise<string> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'CustomerAlternateContactId', string>('/customer/alternatecontact/add', {
      UserToken: userToken,
      CustomerAlternateContact: {
        ExtCustomerId: extCustomerId,
        CustomerAlternateContactTypeId: alternateContact.alternateContactTypeID,
        FirstName: alternateContact.firstName,
        LastName: alternateContact.lastName,
        EmailAddress: alternateContact.emailAddress,
        PhoneNumber: alternateContact.phoneNumber,
        FaxNumber: alternateContact.faxNumber,
        BirthMonth: alternateContact.birthMonth,
        BirthDay: alternateContact.birthDay,
      },
    });

    return response.CustomerAlternateContactId;
  };

  // something extra points
  getPointsBalance = async (): Promise<number | undefined> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'PointsBalance', PointsBalance>('/customer/points/balance/get', JSON.stringify(userToken));
    return response.PointsBalance.PointsBalance;
  };

  // something extra points
  getPointsHistory = async (startdate: Date, cts?: CancelToken): Promise<Adjustment[]> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'Adjustments', Adjustment[]>(
      '/customer/points/adjustments/get',
      {
        UserToken: userToken,
        StartDate: startdate,
      },
      cts,
    );
    return response.Adjustments ?? [];
  };

  // something extra
  getAvailableFuelRewards = async (
    sortBy: 'Amount' | 'ExpirationDate' | 'EarnDate' = 'Amount',
    sortOrder: 'asc' | 'desc' = 'asc',
  ): Promise<{ AvailableCount: number; Rewards: FuelReward[] } | undefined> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'AvailableCount' | 'Rewards', number | FuelReward[]>(
      '/customer/fuel-rewards/available/get',
      JSON.stringify({
        UserToken: userToken,
        SortColumn: sortBy,
        SortDirection: sortOrder,
      }),
    );
    return { AvailableCount: response.AvailableCount as number, Rewards: response.Rewards as FuelReward[] };
  };

  // something extra
  getFuelRewards = async (
    sortBy: 'Amount' | 'ExpirationDate' | 'EarnDate' = 'Amount',
    sortOrder: 'asc' | 'desc' = 'asc',
  ): Promise<FuelReward[] | undefined> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'Rewards', FuelReward[]>(
      '/customer/fuel-rewards/get',
      JSON.stringify({
        UserToken: userToken,
        SortColumn: sortBy,
        SortDirection: sortOrder,
      }),
    );
    return response.Rewards as FuelReward[];
  };

  getFuelRewardsSortOrder = async (): Promise<number> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'RedeemOrderValue', number>(
      '/customer/fuel-rewards/sortorder/get',
      JSON.stringify({
        UserToken: userToken,
      }),
    );
    return response.RedeemOrderValue;
  };

  updateFuelRewaardsSortOrder = async (
    sortOrder: 0 | 1,
    // 0 = Sort by highest earned reward
    // 1 = Sort by oldest earned reward
  ): Promise<boolean> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'SortOrder', FuelRewardsSortOrder>(
      '/customer/fuel-rewards/sort',
      JSON.stringify({
        UserToken: userToken,
        SortOrder: sortOrder,
      }),
    );

    if (!response?.Response?.HasException) {
      return true;
    } else {
      throw new Error(response?.Response?.ReturnMessage);
    }
  };

  changePassword = async (currentPassword: string, newPassword: string, confirmPassword: string): Promise<boolean> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'IsInvalidPassword', boolean>('/customer/change-password', {
      UserToken: userToken,
      CurrentPassword: currentPassword,
      NewPassword: newPassword,
      ConfirmPassword: confirmPassword,
    });
    return response.IsInvalidPassword;
  };

  getProfile = async (): Promise<UserProfile> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'Customer', Customer>('/customer/get', JSON.stringify(userToken));
    return toUserProfile(response.Customer);
  };

  updateProfile = async (profile: UserProfile): Promise<AccessToken> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'LoginResponse', AccessToken>('/customer/update', {
      UserToken: userToken,
      Customer: toCustomer(profile),
    });
    return response.LoginResponse;
  };

  submitTicket = async (topic: string | undefined, message: string): Promise<string> => {
    const response = await this.executeRequest<'TicketId', string>('/app/ticket/add', {
      Topic: topic,
      TopicOther: '',
      Message: message,
      ExtOrderId: '',
      ExtCustomerId: '',
    });
    return response.TicketId;
  };

  enrollCustomerDigitalTransition = async (): Promise<boolean> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest('customer/digital-loyalty-accepted', JSON.stringify(userToken));
    return !response.Response?.HasException;
  };
}
