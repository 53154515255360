import { gql } from 'graphql-request';
import { StoreFragment } from '../../fragments';

const storeByKeyQuery = gql`
  ${StoreFragment}

  query ($storeKey: String!, $locale: Locale) {
    store(key: $storeKey) {
      ...DefaultStore
    }
  }
`;

export { storeByKeyQuery };
