import { gql } from 'graphql-request';
import { CartFragment } from '.././../../fragments/cart';

const createCartMutation = gql`
  ${CartFragment}

  mutation createCart($draft: MyCartDraft!, $locale: Locale!, $country: Country, $currency: Currency!, $channelId: String) {
    cart: createMyCart(draft: $draft) {
      ...DefaultCart
    }
  }
`;

const updateCartMutation = gql`
  ${CartFragment}

  mutation ($id: String!, $version: Long!, $actions: [MyCartUpdateAction!]!, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    cart: updateMyCart(id: $id, version: $version, actions: $actions) {
      ...DefaultCart
    }
  }
`;

const deleteCartMutation = gql`
  ${CartFragment}

  mutation ($id: String!, $version: Long!, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    cart: deleteMyCart(id: $id, version: $version) {
      ...DefaultCart
    }
  }
`;

const replicateCartMutation = gql`
  ${CartFragment}

  mutation replicateMyCart($reference: ReferenceInput!, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    cart: replicateMyCart(reference: $reference) {
      ...DefaultCart
    }
  }
`;

export { createCartMutation, deleteCartMutation, replicateCartMutation, updateCartMutation };

