/* eslint-disable no-unused-vars */
import { Indexable } from './common';

export type TimeSlot = {
  timeSlotDate: string;
  timeSlots: TimeSlotData[];
};

export type TimeSlotData = {
  timeSlotId: string;
  timeSlotHourStart: number;
  timeSlotHourEnd: number;
  timeSlotPrice: number;
  timeSlotDeliveryFee: number;
  timeSlotAvailableQty: number;
};

export type Store = {
  id: number;
  number: string;
  active: boolean;
  name: string;
  brand: Brand;
  address: StoreAddress;
  coordinates: Coordinates;
  phone: string;
  email: string;
  distance: number;
  taxRate?: number;
  fax?: string;
  storeHours?: string;
  departments?: Department[];
  partyPlanner?: boolean;
  eventCenter?: boolean;
  eventCenterCapacity?: number;
  unionStore?: boolean;
  district?: string;
  openDate?: string;
  deliveryInfo?: DeliveryInfo;
  index?: number; // added for map client side
  canSellAlcohol: boolean;
  canDeliverAlcohol: boolean;
  cateringStatusId?: number;
  fulfillmentLeadTimeMinutes?: number;
};

export type Brand = {
  id: number;
  name: string;
  logo: string;
  description?: string;
  heroImage?: string;
  photographs?: any[];
};

export type StoreAddress = {
  street: string;
  city: string;
  state: string;
  zip: string;
};

export type Coordinates = {
  googlePlaceId?: any;
  latitude: string;
  longitude: string;
};

export type Department = {
  departmentId: number;
  displayName?: string;
  phoneNumber?: string;
  isActive?: boolean;
  departmentHours?: DepartmentHours[];
  isFilterable?: boolean;
  isInternal?: boolean;
};

export type DepartmentHours = {
  departmentId: number;
  weekday: string;
  departmentHours: string;
};

export type DepartmentType = {
  id: number;
  name: string;
  description: string;
};

export interface StoreHour extends Indexable<string | boolean> {
  open: string;
  close: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  notes: string;
}

export type DeliveryInfo = {
  id: number;
  storeId: number;
  delivery: boolean;
  deliversAlcohol: boolean;
  deliveryZipcodes: DeliveryZipcode[];
};

export type DeliveryZipcode = {
  zip: string;
  deliveryPartner: string;
  deliveryVan: boolean;
  deliveryTipUpc: string;
};

export type LocationData = {
  lat?: number;
  lon?: number;
};

export type DeliveryArea = {
  delivery: boolean;
  zip: string;
  number: string;
};

export enum ShopType {
  PICKUP = 'Pickup',
  DELIVERY = 'Delivery',
  IN_STORE = 'In-Store',
}
