import { CartService as CartApi } from './cart';
import { OrderService as OrderApi } from './order';
import { ShoppingListService as ShoppingListApi } from './shoppingList';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Me {
  export const CartService = CartApi;
  export const OrderService = OrderApi;
  export const ShoppingListService = ShoppingListApi;
}
