import { GraphQLError } from 'graphql';
import { ClientError } from 'graphql-request';
import logger from './logger';

export const withRetry = async <T>(callback: (version?: number) => Promise<T>, version?: number, retries = 3): Promise<T> => {
  try {
    return await callback(version);
  } catch (ex) {
    if (ex instanceof ClientError) {
      if (retries > 0) {
        let currentVersion = version;
        try {
          const error: GraphQLError | undefined = ex.response.errors ? ex.response.errors[0] : undefined;
          currentVersion = error?.extensions && 'currentVersion' in error.extensions ? Number(error?.extensions['currentVersion']) : version;
          const code = error && 'code' in error ? error.code : error?.extensions && 'code' in error.extensions ? error.extensions['code'] : undefined;
          const extensionKey = error?.extensions && 'extensionKey' in error.extensions ? error?.extensions['extensionKey'] : undefined;
          logger.log(
            `fieldera-raleys-commercetools => error => ${code ? 'code: ' + code : ''} ${version ? ', version: ' + version : ''}  ${
              currentVersion ? ', currentVersion: ' + currentVersion : ''
            } ${extensionKey ? ', extensionKey: ' + extensionKey : ''}, retry count: ${3 - retries + 1}. message:`,
          );
        } catch {
          /* empty */
        }
        return withRetry(callback, currentVersion, retries - 1);
      } else {
        throw ex;
      }
    }
    throw ex;
  }
};

export const partialErrorText = (e: Error, maxChars = 99) => {
  return (e.message ?? '').replace(/[^a-zA-Z0-9]/gi, '.').substring(0, maxChars);
};
