import { gql } from 'graphql-request';
import { ShoppingListFragment, ShoppingListMinimalFragment } from '../../../fragments';

const shoppingListQuery = gql`
  ${ShoppingListFragment}

  query getMe($where: String, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String, $includeChannelIds: [String!]) {
    me {
      shoppingLists(where: $where) {
        total
        offset
        count
        results {
          ...DefaultShoppingList
        }
      }
    }
  }
`;

const shoppingListByIdQuery = gql`
  ${ShoppingListFragment}

  query getMe($id: String, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String, $includeChannelIds: [String!]) {
    me {
      shoppingList(id: $id) {
        ...DefaultShoppingList
      }
    }
  }
`;

const shoppingListMinimalQuery = gql`
  ${ShoppingListMinimalFragment}

  query getMe($where: String, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    me {
      shoppingLists(where: $where) {
        total
        offset
        count
        results {
          ...MinimalShoppingList
        }
      }
    }
  }
`;

export { shoppingListByIdQuery, shoppingListMinimalQuery, shoppingListQuery };
