/* eslint-disable no-unused-vars */
/**@deprecated */
export interface Offers {
  Accepted: Offer[];
  Available: Offer[];
}

/**@deprecated */
export interface OfferIds {
  Accepted: number[];
  Available: number[];
}

/**@deprecated */
export interface Offer {
  OfferId: number;
  OfferType: string;
  OfferSubtype: string;
  Name: string;
  StartDate: Date;
  EndDate: Date;
  Description: string;
  Limit: number;
  LimitType: LimitType;
  CreatedDate: Date;
  LastUpdate: Date;
  ModifiedDate: Date;
  Priority: number;
  Locations: string[];
  UserDefinedFields: UserDefinedFields;
  Conditions: Condition[];
  Rewards: Reward[];
}

/**@deprecated */
export interface Condition {
  Type: Type;
  GrantType: GrantType;
  Amount: number;
  IncludeAnyProduct: boolean;
  LastUpdate: Date;
  Product: Product;
  UseNetValues: boolean;
}

/**@deprecated */
export enum GrantType {
  GreaterThanOrEqual = 'GreaterThanOrEqual',
}

/**@deprecated */
export interface Product {
  Include: string[];
  Exclude: string[];
}

/**@deprecated */
export enum Type {
  Amount = 'Amount',
  Quantity = 'Quantity',
}

/**@deprecated */
export enum LimitType {
  Customer = 'Customer',
  Transaction = 'Transaction',
}

/**@deprecated */
export interface Reward {
  Type: string;
  Discount: Discount;
  TriggerQuantity: number | null;
  ApplyToQuantity: number | null;
  Value: number | null;
  LimitValue: number | null;
  AllowNegative: null | string;
  Tax: Tax;
  DistributionType: DistributionType;
  LimitValueType: LimitValueType | null;
  IncludeAnyProduct: boolean | null;
  LastUpdated: Date;
  Limit: null;
  LimitType: null;
  Product: Product | null;
}
/**@deprecated */
export enum Discount {
  AmountOffItem = 'AmountOffItem',
  AmountOffPerWeight = 'AmountOffPerWeight',
  Empty = '',
  PercentOffItem = 'PercentOffItem',
}
/**@deprecated */
export enum DistributionType {
  Dollar = 'Dollar',
  Transaction = 'Transaction',
}
/**@deprecated */
export enum LimitValueType {
  Amount = 'Amount',
  Weight = 'Weight',
}
/**@deprecated */
export enum Tax {
  AfterDiscounts = 'AfterDiscounts',
  BeforeDiscounts = 'BeforeDiscounts',
  Empty = '',
}
/**@deprecated */
export interface UserDefinedFields {
  ConsumerDescription: string;
  ConsumerDetails: string;
  TermsAndConditions: string;
  ImageUrl: string;
  Targeted: boolean;
  BadgeType: BadgeType;
  OfferOrder: number;
  DisplayBadge: boolean;
  DisplayDate: Date;
  OfferFlag: string;
  OfferHeadline: string;
}

export interface Promotion {
  PromotionDefinitionId: number;
  ExtPromotionId: string;
  ExtPromotionTypeCode: string;
  ExtPromotionType?: string;
  RewardType?: string;
  PromotionDefinitionName?: string;
  PromotionCode?: string;
  Description?: string;
  Headline?: string;
  SubHeadline?: string;
  QualifiedImageUrl?: string;
  ExtBadgeTypeCode?: string;
  ExtFlagTypeCode?: string;
  AutoApply: boolean;
  Priority: number;
  SortOrder: number;
  EndDate: Date;
  ProductList?: string[];
  PDPDisplay: boolean;
  PercentComplete?: number;
  PercentCompleteMessage?: string;
  AvailableSavings?: number;
  IsAccepted?: boolean;
  RewardDiscount?: number;
  RewardPercent?: number;
  RewardProducts?: RewardProduct[];
  Rewards?: LineReward[];
  HasFreeServiceFee?: boolean;
  HasFreeDeliveryFee?: boolean;
  IsPromoCodePromo?: boolean;
}

export interface LineReward {
  ExtProductId?: string;
  LineItemId?: string;
  Price?: number;
  Quantity?: number;
  RegularPrice?: number;
  RewardPrice?: number;
  RewardPoints?: number;
  RewardDiscount?: number;
  RewardPercent?: number;
}

interface RewardProduct {
  ExtProductId: string;
  Quantity: number;
  LineItemId?: number;
  Price: number;
  RegularPrice: number;
  RewardDiscount: number;
  RewardPercent: number;
  RewardPrice?: number;
  RewardPoints: number;
}

export interface Voucher extends Promotion {
  Amount: number;
  RevisedDate: Date;
  RedeemedDate?: Date;
}

export enum BadgeType {
  Empty = '',
  SomethingExtra = 'SomethingExtra',
  WeeklyExclusive = 'WeeklyExclusive',
}
