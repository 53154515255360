import { gql } from 'graphql-request';
import { CartFragment } from '../../../fragments/cart';

const activeCart = gql`
  ${CartFragment}

  query getMe($locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    me {
      activeCart {
        ...DefaultCart
      }
    }
  }
`;

export { activeCart };
