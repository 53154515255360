import { AxiosInstance } from 'axios';
import { Debugger } from 'debug';

export const addLogger = (instance: AxiosInstance, logger: Debugger) => {
  instance.interceptors.request.use((config) => {
    const url = instance.getUri(config);
    logger(config.method?.toUpperCase() + ' ' + url);
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      const url = instance.getUri(response.config);
      logger(response.status + ' ' + response.statusText, '(' + response.config.method?.toUpperCase() + ' ' + url + ')');
      return response;
    },
    (error) => {
      if (error.config) {
        const url = instance.getUri(error.config);
        logger(error.name + ': ' + error.message, '(' + error.config.method.toUpperCase() + ' ' + url + ')');
      } else {
        logger(error.name + ': ' + error.message);
      }
      throw error;
    },
  );
};
