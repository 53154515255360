import { gql } from 'graphql-request';
import { ShoppingListFragment } from '../../../fragments';

const updateShoppingListMutation = gql`
  ${ShoppingListFragment}

  mutation updateShoppingList(
    $id: String!
    $version: Long!
    $actions: [MyShoppingListUpdateAction!]!
    $locale: Locale!
    $country: Country!
    $currency: Currency!
    $channelId: String
    $includeChannelIds: [String!]
  ) {
    shoppingList: updateMyShoppingList(id: $id, version: $version, actions: $actions) {
      ...DefaultShoppingList
    }
  }
`;

const createShoppingListMutation = gql`
  ${ShoppingListFragment}

  mutation createShoppingList(
    $draft: MyShoppingListDraft!
    $locale: Locale!
    $storeKey: KeyReferenceInput
    $country: Country!
    $currency: Currency!
    $channelId: String
    $includeChannelIds: [String!]
  ) {
    shoppingList: createMyShoppingList(draft: $draft, storeKey: $storeKey) {
      ...DefaultShoppingList
    }
  }
`;

const deleteShoppingListMutation = gql`
  ${ShoppingListFragment}

  mutation deleteMyShoppingList(
    $version: Long!
    $storeKey: KeyReferenceInput
    $id: String
    $key: String
    $locale: Locale!
    $country: Country!
    $currency: Currency!
    $channelId: String
    $includeChannelIds: [String!]
  ) {
    shoppingList: deleteMyShoppingList(version: $version, storeKey: $storeKey, id: $id, key: $key) {
      ...DefaultShoppingList
    }
  }
`;
export { updateShoppingListMutation, createShoppingListMutation, deleteShoppingListMutation };
