import {
  AddShoppingListLineItem,
  LocalizedStringItemInputType,
  Me,
  Money,
  MyShoppingListDraft,
  MyShoppingListUpdateAction,
  ShoppingList,
  ShoppingListDraft,
  ShoppingListUpdateAction,
  CartUpdateAction as ShoppingUpdateAction,
} from '../../../schema/schema';
import { AddLineItem, LineItemCustomFieldName } from '../../../schema/types';
import { StoreContextServiceBase } from '../../storeContextServiceBase';
import { createShoppingListMutation, deleteShoppingListMutation, updateShoppingListMutation } from './mutation';
import { shoppingListByIdQuery, shoppingListQuery } from './query';

type ShoppingListData = ShoppingListDraft | MyShoppingListDraft;

interface ShoppingListParams {
  listId?: string;
  listName?: string;
  locale?: string;
  country?: string;
  currency?: string;
}

interface GetShoppingListParams extends ShoppingListParams {
  priceChannelId?: string;
  availablityChannelIds?: string[];
}

export class ShoppingListService extends StoreContextServiceBase {
  private executeMutation = async (
    id: string,
    version: number,
    actions: ShoppingUpdateAction[] | MyShoppingListUpdateAction[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
    channelId?: string,
    includeChannelIds?: string[],
  ): Promise<ShoppingList> => {
    if (!channelId) channelId = await this.getDistributionChannelId();

    const response = await this.executeRequest<'shoppingList', ShoppingList>(updateShoppingListMutation, {
      id,
      version,
      actions,
      locale,
      currency,
      country,
      channelId,
      includeChannelIds,
    });
    return response.shoppingList;
  };

  createShoppingList = async (
    { listName, locale = 'en-US', country = 'US', currency = 'USD' }: ShoppingListParams,
    signal?: AbortSignal,
  ): Promise<ShoppingList> => {
    const shoppingListDraft: ShoppingListData = {
      name: [
        {
          locale: 'en',
          value: listName ?? '',
        },
      ],
    };

    const response = await this.executeRequest<'shoppingList', ShoppingList>(
      createShoppingListMutation,
      {
        locale,
        currency,
        country,
        draft: {
          ...shoppingListDraft,
        },
      },
      signal,
    );
    return response.shoppingList;
  };

  getShoppingListById = async (
    { listId, priceChannelId, availablityChannelIds, locale = 'en-US', country = 'US', currency = 'USD' }: GetShoppingListParams,
    signal?: AbortSignal,
  ): Promise<ShoppingList | undefined> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'me', Me>(
      shoppingListByIdQuery,
      {
        id: listId,
        locale,
        country,
        currency,
        channelId: priceChannelId,
        includeChannelIds: availablityChannelIds,
      },
      signal,
    );
    return response.me.shoppingList ?? undefined;
  };

  getShoppingList = async (
    { listName, priceChannelId, availablityChannelIds, locale = 'en-US', country = 'US', currency = 'USD' }: GetShoppingListParams,
    signal?: AbortSignal,
  ): Promise<ShoppingList> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'me', Me>(
      shoppingListQuery,
      {
        where: `name(en="${listName}")`,
        locale,
        country,
        currency,
        channelId: priceChannelId,
        includeChannelIds: availablityChannelIds,
      },
      signal,
    );
    return response.me.shoppingLists.results[0];
  };

  getShoppingLists = async (
    { priceChannelId, availablityChannelIds, locale = 'en-US', country = 'US', currency = 'USD' }: GetShoppingListParams,
    signal?: AbortSignal,
  ): Promise<ShoppingList[]> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'me', Me>(
      shoppingListQuery,
      {
        where: null,
        locale,
        country,
        currency,
        channelId: priceChannelId,
        includeChannelIds: availablityChannelIds,
      },
      signal,
    );
    return response.me.shoppingLists.results;
  };

  updateShoppingList = async (
    id: string,
    version: number,
    actions: ShoppingUpdateAction[] | MyShoppingListUpdateAction[],
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<ShoppingList> => {
    return await this.executeMutation(id, version, actions, locale, currency, country);
  };

  updateListName = async (
    listId: string,
    version: number,
    name: string,
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<ShoppingList> => {
    const newName = [
      {
        locale: locale,
        value: name,
      },
    ] as LocalizedStringItemInputType[];
    const actions = [
      {
        changeName: {
          name: newName,
        },
      },
    ];

    return await this.executeMutation(listId, version, actions, locale, currency, country);
  };

  deleteShoppingList = async (
    version: number,
    id: string,
    storeKey?: string,
    key?: string,
    locale = 'en-US',
    country = 'US',
    currency = 'USD',
    channelId?: string,
    includeChannelIds?: string[],
  ): Promise<ShoppingList> => {
    if (!id && !key) {
      throw 'List id needed.';
    }
    const priceChannelId = channelId ?? (await this.getDistributionChannelId());
    const availablityChannelIds = includeChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'shoppingList', ShoppingList>(deleteShoppingListMutation, {
      version,
      storeKey,
      id,
      key,
      locale,
      country,
      currency,
      channelId: priceChannelId,
      includeChannelIds: availablityChannelIds,
    });
    return response.shoppingList;
  };

  clearShoppingList = async (listId: string, version: number, lineItemIds: string[] = [], textLineItemIds: string[] = []) => {
    const lineActions: MyShoppingListUpdateAction[] = lineItemIds.map((id) => {
      return { removeLineItem: { lineItemId: id } };
    });
    const textActions: MyShoppingListUpdateAction[] = textLineItemIds.map((id) => {
      return { removeTextLineItem: { textLineItemId: id } };
    });
    const actions = lineActions.concat(textActions);
    return await this.executeMutation(listId, version, actions);
  };

  unCheckAllItems = async (
    id: string,
    version: number,
    lineItemIds: string[],
    textLineItemIds: string[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ) => {
    const lineItemActions: ShoppingListUpdateAction[] = lineItemIds.map((x) => {
      return { setLineItemCustomField: { lineItemId: x, name: 'listChecked', value: JSON.stringify(false) } };
    });
    const textLineItemActions: ShoppingListUpdateAction[] = textLineItemIds.map((x) => {
      return { setTextLineItemCustomField: { textLineItemId: x, name: 'listChecked', value: JSON.stringify(false) } };
    });
    const actions = lineItemActions.concat(textLineItemActions);
    return await this.executeMutation(id, version, actions, locale, currency, country);
  };

  addItemsToList = async (
    id: string,
    version: number,
    lineItems: AddLineItem[],
    textLineItemTitles: string[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ) => {
    const lineItemActions: ShoppingListUpdateAction[] = lineItems.map((x) => {
      const action = {
        addLineItem: {
          quantity: x.quantity ?? 1,
          sku: x.sku,
          custom: { type: { key: 'raleys-shopping-list-item-custom-fields' }, fields: [] },
        } as AddShoppingListLineItem,
      };
      if (x.parentLineItemId) {
        action.addLineItem.custom?.fields?.push({ name: 'parentLineItemId', value: JSON.stringify(x.parentLineItemId) });
      }
      if (x.customStepSort) {
        action.addLineItem.custom?.fields?.push({ name: 'customStepSort', value: JSON.stringify(x.customStepSort) });
      }
      if (x.childLineItems) {
        action.addLineItem.custom?.fields?.push({ name: 'childLineItems', value: JSON.stringify(x.childLineItems) });
      }
      if (x.itemNote) {
        action.addLineItem.custom?.fields?.push({ name: 'itemNote', value: JSON.stringify(x.itemNote) });
      }

      return action;
    });
    const textLineItemActions: ShoppingListUpdateAction[] = textLineItemTitles.map((x) => {
      return {
        addTextLineItem: {
          name: [{ locale: 'en-US', value: x }],
          quantity: 1,
          description: [{ locale: 'en-US', value: '' }],
          custom: { type: { key: 'raleys-shopping-list-item-custom-fields' }, fields: [] },
        },
      };
    });
    const actions = lineItemActions.concat(textLineItemActions);
    const priceChannelId = await this.getDistributionChannelId();
    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  removeItemsFromList = async (
    id: string,
    version: number,
    lineItemIds: string[],
    textLineItemIds: string[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ) => {
    const lineItemActions: ShoppingListUpdateAction[] = lineItemIds.map((x) => {
      return { removeLineItem: { lineItemId: x } };
    });
    const textLineItemActions: ShoppingListUpdateAction[] = textLineItemIds.map((x) => {
      return { removeTextLineItem: { textLineItemId: x } };
    });
    const actions = lineItemActions.concat(textLineItemActions);
    const priceChannelId = await this.getDistributionChannelId();
    return await this.executeMutation(id, version, actions, locale, currency, country, priceChannelId);
  };

  addToShoppingList = async (listId: string, version: number, productKey: string, quantity: number = 1): Promise<ShoppingList> => {
    const actions = [
      {
        addLineItem: {
          quantity: quantity,
          sku: productKey,
        },
      },
    ];
    return await this.executeMutation(listId, version, actions);
  };

  addTextLineItemToShoppingList = async (
    listId: string,
    version: number,
    title: string,
    description: string = '',
    quantity: number = 1,
  ): Promise<ShoppingList> => {
    const actions = [
      {
        addTextLineItem: {
          name: [{ locale: 'en-US', value: title }],
          quantity: quantity,
          description: [{ locale: 'en-US', value: description }],
        },
      },
    ];
    return await this.executeMutation(listId, version, actions);
  };

  removeFromShoppingList = async (listId: string, version: number, lineItemId: string): Promise<ShoppingList> => {
    const actions = [
      {
        removeLineItem: {
          lineItemId: lineItemId,
        },
      },
    ];
    return await this.executeMutation(listId, version, actions);
  };

  removeTextItemFromShoppingList = async (listId: string, version: number, lineItemId: string): Promise<ShoppingList> => {
    const actions = [
      {
        removeTextLineItem: {
          textLineItemId: lineItemId,
        },
      },
    ];
    return await this.executeMutation(listId, version, actions);
  };

  setListLineItemQuantity = async (
    listId: string,
    version: number,
    lineItemId: string,
    quantity: string | number,
    fields?: { name: LineItemCustomFieldName; value?: string | string[] | number | Money }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<ShoppingList> => {
    quantity = isNaN((quantity = parseInt(`${quantity}`, 10))) ? 1 : quantity;
    const actions: ShoppingListUpdateAction[] = [{ changeLineItemQuantity: { lineItemId: lineItemId, quantity: quantity } }];
    if (fields) {
      actions.push(
        ...fields.map((x) => {
          return { setLineItemCustomField: { lineItemId: lineItemId, name: x.name, value: x.value ? JSON.stringify(x.value) : null } };
        }),
      );
    }
    return await this.executeMutation(listId, version, actions, locale, currency, country);
  };

  setListTextLineItemQuantity = async (
    listId: string,
    version: number,
    lineItemId: string,
    quantity: string | number,
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<ShoppingList> => {
    quantity = isNaN((quantity = parseInt(`${quantity}`, 10))) ? 1 : quantity;
    const actions: ShoppingListUpdateAction[] = [{ changeTextLineItemQuantity: { textLineItemId: lineItemId, quantity: quantity } }];
    return await this.executeMutation(listId, version, actions, locale, currency, country);
  };

  setListLineItemCustomField = async (
    id: string,
    version: number,
    lineItemId: string,
    fields: { name: LineItemCustomFieldName; value?: string | boolean | string[] }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<ShoppingList> => {
    const actions: ShoppingListUpdateAction[] = fields.map((x) => {
      return { setLineItemCustomField: { lineItemId: lineItemId, name: x.name, value: JSON.stringify(x.value ?? '') } };
    });
    return await this.executeMutation(id, version, actions, locale, currency, country);
  };

  setListTextLineItemCustomField = async (
    id: string,
    version: number,
    lineItemId: string,
    fields: { name: LineItemCustomFieldName; value?: string | boolean }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<ShoppingList> => {
    const actions: ShoppingListUpdateAction[] = fields.map((x) => {
      return { setTextLineItemCustomField: { textLineItemId: lineItemId, name: x.name, value: JSON.stringify(x.value ?? '') } };
    });
    return await this.executeMutation(id, version, actions, locale, currency, country);
  };

  setListLineItemCustomType = async (
    id: string,
    version: number,
    lineItemId: string,
    fields: { name: LineItemCustomFieldName; value?: string | boolean }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<ShoppingList> => {
    const actions: ShoppingListUpdateAction[] = [
      {
        setLineItemCustomType: {
          type: { key: 'raleys-shopping-list-item-custom-fields' },
          lineItemId: lineItemId,
          fields: fields.map((x) => {
            return { name: x.name, value: JSON.stringify(x.value ?? '') };
          }),
        },
      },
    ];
    return await this.executeMutation(id, version, actions, locale, currency, country);
  };

  setListTextLineItemCustomType = async (
    id: string,
    version: number,
    lineItemId: string,
    fields: { name: LineItemCustomFieldName; value?: string | boolean }[],
    locale: string = 'en-US',
    currency: string = 'USD',
    country: string = 'US',
  ): Promise<ShoppingList> => {
    const actions: ShoppingListUpdateAction[] = [
      {
        setTextLineItemCustomType: {
          type: { key: 'raleys-shopping-list-item-custom-fields' },
          textLineItemId: lineItemId,
          fields: fields.map((x) => {
            return { name: x.name, value: JSON.stringify(x.value ?? '') };
          }),
        },
      },
    ];
    return await this.executeMutation(id, version, actions, locale, currency, country);
  };
}
