import { CancelToken } from 'axios';
import { ServiceBase } from './serviceBase';
import { ProductCollection } from './types/productCollections';

export class ProductService extends ServiceBase {
  getProductCollections = async (
    poductCollectionTypeIds: [number],
    storeNumber: string,
    extCustomerId?: string,
    cts?: CancelToken,
  ): Promise<ProductCollection[]> => {
    const response = await this.executeRequest<'ProductCollections', ProductCollection[]>(
      'product/productcollections/get',
      {
        ProductCollectionTypes: poductCollectionTypeIds,
        ExtCustomerId: extCustomerId,
        StoreNumber: storeNumber,
      },
      cts,
    );
    return response.ProductCollections;
  };

  getProductCollectionById = async (poductCollectionId: number, cts?: CancelToken): Promise<ProductCollection | undefined> => {
    return this.executeRawRequest<ProductCollection>('product/productcollection/get', poductCollectionId, cts);
  };
}
