import { CategoryFragment } from './category';
import { NonSellableProductVariantFragment, ProductVariantFragment } from './productVariant';
export const ProductFragment = `
${ProductVariantFragment}
${CategoryFragment}
fragment DefaultProduct on Product {
  __typename
  id
  key
  productType {
    id
    key
    name
  }
  taxCategory {
    id
    key
    name
  }
  masterData {
    published
    current {
      slug(locale: $locale)
      name(locale: $locale)
      metaTitle(locale: $locale)
      metaKeywords(locale: $locale)
      metaDescription(locale: $locale)
      description(locale: $locale)
      categoryOrderHints {
        categoryId
        orderHint
      }
      categories {
        ...DefaultCategory
      }
      masterVariant {
        ...DefaultVariant
      }
      variants {
        ...DefaultVariant
      }
    }
  }
}`;

export const NonSellableProductFragment = `
${NonSellableProductVariantFragment}
${CategoryFragment}
fragment DefaultNonsellableProduct on Product {
  __typename
  id
  key
  productType {
    id
    key
    name
  }
  taxCategory {
    id
    key
    name
  }
  masterData {
    published
    current {
      slug(locale: $locale)
      name(locale: $locale)
      metaTitle(locale: $locale)
      metaKeywords(locale: $locale)
      metaDescription(locale: $locale)
      description(locale: $locale)
      categoryOrderHints {
        categoryId
        orderHint
      }
      categories {
        ...DefaultCategory
      }
      masterVariant {
        ...DefaultNonsellableVariant
      }
      variants {
        ...DefaultNonsellableVariant
      }
    }
  }
}`;
