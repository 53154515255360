import { Product, ProductQueryResult } from '../../schema/schema';
import { ProductSearch } from '../../schema/types';
import { buildProductWhere } from '../../utils/search';
import { StoreContextServiceBase } from '../storeContextServiceBase';
import { productBySkuQuery, queryProductCategories, queryProducts } from './query';

export class ProductService extends StoreContextServiceBase {
  getProductBySku = async (
    productSku: string,
    priceChannelId?: string,
    availablityChannelIds?: string[],
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<Product> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'product', Product>(productBySkuQuery, {
      productSku,
      locale,
      country,
      currency,
      channelId: priceChannelId,
      includeChannelIds: availablityChannelIds,
    });
    return response.product;
  };

  getProducts = async (
    search: ProductSearch,
    priceChannelId?: string,
    availablityChannelIds?: string[],
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<Product[]> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'products', ProductQueryResult>(queryProducts, {
      where: buildProductWhere(search),
      skus: search?.skus,
      limit: search?.limit,
      offset: search?.offset,
      locale,
      country,
      currency,
      channelId: priceChannelId,
      includeChannelIds: availablityChannelIds,
    });
    return response.products.results;
  };

  getProductCategoriesBySkus = async (
    search: ProductSearch,
    priceChannelId?: string,
    availablityChannelIds?: string[],
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<Product[]> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'products', ProductQueryResult>(queryProductCategories, {
      where: buildProductWhere(search),
      skus: search?.skus,
      limit: search?.limit,
      offset: search?.offset,
      locale,
      country,
      currency,
      channelId: priceChannelId,
      includeChannelIds: availablityChannelIds,
    });
    return response.products.results;
  };
}
