/* eslint-disable no-console */
export const __DEV__ = (() => !process.env.NODE_ENV || process.env.NODE_ENV === 'development')();

const log = (...args: unknown[]): void => {
  if (__DEV__) {
    console.log(...args);
  }
};

const debug = (...args: unknown[]): void => {
  if (__DEV__) {
    console.debug(...args);
  }
};

const warn = (...args: unknown[]): void => {
  if (__DEV__) {
    console.warn(...args);
  }
};

const error = (ex: unknown, params?: Record<string, string>): void => {
  if (__DEV__) {
    console.error(ex, params);
  }
};

export default { log, debug, warn, error };
