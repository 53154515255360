import { gql } from 'graphql-request';
import { OrderFragment } from '../../../fragments/order';

const createOrderMutation = gql`
  ${OrderFragment}
  mutation createMyOrderFromCart($draft: OrderMyCartCommand!, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    order: createMyOrderFromCart(draft: $draft) {
      ...DefaultOrder
    }
  }
`;

export { createOrderMutation };
