import { ApisauceInstance, create as apisauce } from 'apisauce';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { CacheProvider } from '../../types';
import { addLogger } from '../../utils';
import { BearerToken, FielderaApiResponse } from './types/common';

const __DEV__ = (() => !process.env.NODE_ENV || process.env.NODE_ENV === 'development')();

export class ServiceBase {
  protected apiClient: ApisauceInstance;
  protected authClient: ApisauceInstance;
  protected static AUTH_PROVIDER_TYPE_ID = 1;
  protected static BEARER_TOKEN_CACHE_KEY = 'bw_bearer_token';
  protected cache: CacheProvider;
  protected authKey: string;

  constructor(config: { apiUrl: string; authUrl: string; authKey: string; cache: CacheProvider }) {
    this.apiClient = apisauce({ baseURL: config.apiUrl, timeout: 30000 });
    this.authClient = apisauce({ baseURL: config.authUrl });
    this.cache = config.cache;
    this.authKey = config.authKey;

    this.apiClient.addAsyncRequestTransform(async (request: AxiosRequestConfig) => {
      const cachedToken = await this.getApplicationBearerToken();
      request.headers = { ...request.headers, Authorization: `Bearer ${cachedToken?.access_token}` };
    });

    if (__DEV__) {
      const axiosLogger = require('debug')('api:brandyWine');
      addLogger(this.apiClient.axiosInstance, axiosLogger);
      addLogger(this.authClient.axiosInstance, axiosLogger);
    }
  }

  protected async getApplicationBearerToken(): Promise<BearerToken> {
    const authToken = await this.cache.getItem<BearerToken>(ServiceBase.BEARER_TOKEN_CACHE_KEY);
    if (!authToken) {
      const postBody = 'grant_type=client_credentials';

      const response = await this.authClient.post<BearerToken>('/authenticate', postBody, {
        headers: {
          'Content-Type': 'text/plain',
          'AuthProviderTypeID': ServiceBase.AUTH_PROVIDER_TYPE_ID,
          'Authorization': `Basic ${this.authKey}`,
        },
      });
      if (response.ok && response.data) {
        await this.cache.setItem(ServiceBase.BEARER_TOKEN_CACHE_KEY, response.data, response.data.expires_in / 60 - 5);
        return response.data;
      }
      throw new Error('failed to get application token');
    }
    return authToken;
  }

  protected executeRequest = async <K extends string, T>(endpoint: string, params?: unknown, cts?: CancelToken): Promise<FielderaApiResponse<K, T>> => {
    const response = await this.apiClient.post<FielderaApiResponse<K, T>>(endpoint, params, { cancelToken: cts });
    if (response.ok) {
      if (response.data && !response.data.Response?.HasException) {
        return response.data;
      } else {
        throw new Error(response.data?.Response?.ReturnMessage);
      }
    } else {
      throw new Error(response.problem);
    }
  };

  protected executeRawRequest = async <T>(endpoint: string, params?: unknown, cts?: CancelToken): Promise<T | undefined> => {
    const response = await this.apiClient.post<T>(endpoint, params, { cancelToken: cts });
    if (response.ok) {
      return response.data;
    } else {
      throw new Error(response.problem);
    }
  };
}
