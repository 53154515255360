import { ChannelFragment } from './channel';

export const StoreFragment = `
  ${ChannelFragment}

  fragment DefaultStore on Store {
    id
    key
    name (locale: $locale)
    distributionChannels {
      ...DefaultChannel
    }
    supplyChannels {
      ...DefaultChannel
    }
  }
`;
