/* eslint-disable no-unused-vars */

import { OrderType, ShippingMethodType } from './order';

export interface Indexable<T> {
  [key: string]: T;
}

export type Lookup = {
  id?: string;
  value?: string;
  isActive?: boolean;
  customProperties?: Indexable<string>;
};

export type SectionListItem<T> = {
  id: string;
  name: string;
  description?: string;
  visible?: boolean;
  enabled?: boolean;
  data: T[];
};
export type DateRange = { startDate: Date; endDate?: Date };

export type PurchaseType = keyof typeof OrderType;

export type FulfillmentType = keyof typeof ShippingMethodType | 'All';

export type Filter = {
  id: string;
  title?: string;
  children: FilterItem[];
  imageUrl?: string;
};

type FilterItemCallback = () => undefined | DateRange | string | number | Date | boolean | string[] | number[] | Date[] | boolean[];

export type FilterItem = {
  id: string;
  title: string;
  selected: boolean;
  default: boolean;
  imageUrl?: string;
  controlType?: 'switch' | 'radio' | 'text';
  parentId?: string;
  value?: DateRange | string | number | Date | boolean | string[] | number[] | Date[] | boolean[] | FilterItemCallback;
};

// TODO - Product Collection - Remove during actual implementation
export type ProductCollectionType = {
  uri?: string;
  localCTAURI?: any;
  localBannerURI?: any;
  title: string;
  subtitle: string;
};

export enum WidgetType {
  SimilarProducts = 'item',
  FrequentlyBoughtTogether = 'item',
  FrequentlyViewedTogether = 'item',
  Category = 'category',
  Search = 'keyword',
  ItemsYouMayLike = 'personalized',
  PastPurchases = 'personalized',
  Bestseller = 'global',
  TrendingProductsRecommendations = 'global',
  PopularItems = 'item',
  TopDeals = 'personalized',
}
interface WidgetParamBase {
  url: string;
  ref_url: string;
  filters: string;
}

export interface WidgetParams extends WidgetParamBase {
  readonly type: 'global';
}

export interface ItemWidgetParams extends WidgetParamBase {
  readonly type: 'item';
  context_id: string;
  item_ids: string;
}

export interface KeywordWidgetParams extends WidgetParamBase {
  readonly type: 'keyword';
  filter_facet: string;
  facet: string[];
  query: string;
}

export interface CategoryWidgetParams extends WidgetParamBase {
  readonly type: 'category';
  filter_facet: string;
  facet: string[];
  cat_id: string;
}

export interface PersonalizedWidgetParams extends WidgetParamBase {
  readonly type: 'personalized';
  query: string;
}

export enum TourKey {
  HomeScreen = 'homeScreen',
  SEHomeScreen = 'seHomeScreen',
  AccountScreen = 'accountScreen',
  ProductDetailScreen = 'productDetailScreen',
}

export interface LoyaltyValidationResult {
  validationStatus: number;
  statusDescription: string;
  extLoyaltyId: string;
}

export enum PaymentTypeValue {
  CC_ON_PICKUP = 'Credit-Card-On-Pickup',
  EBT_SNAP_ON_PICKUP = 'EBT-SNAP-On-Pickup',
  CASH_ON_PICKUP = 'Cash-On-Pickup',
}

export enum BackupTypeValue {
  DONT_SUBSTITUTE = 'None',
  BEST_AVAILABLE = 'Best-Available',
  SPECIFIC = 'Specific-Items',
}

export enum BackupType {
  DONT_SUBSTITUTE = "Don't Substitute",
  BEST_AVAILABLE = 'Best Available Item',
  SPECIFIC = 'Specific Item',
}
