import { Store } from '../schema/schema';
import { ServiceBase } from './serviceBase';
import { StoreService } from './store/store';

export class StoreContextServiceBase extends ServiceBase {
  protected storeService: StoreService;
  protected selectedStoreKey: string | (() => string);
  private selectedStore?: Store;

  constructor(config: { apiUrl: string; authToken: () => Promise<string>; selectedStoreKey: string | (() => string) }) {
    super({ apiUrl: config.apiUrl, authToken: config.authToken });
    this.selectedStoreKey = config.selectedStoreKey;
    this.storeService = new StoreService(config);
  }

  protected getSelectedStore = async (): Promise<Store | undefined> => {
    let storeKey = typeof this.selectedStoreKey === 'string' ? this.selectedStoreKey : this.selectedStoreKey();
    storeKey = storeKey === '1' ? `0${storeKey}` : storeKey;
    if (this.selectedStore === undefined || storeKey !== this.selectedStore.key) {
      this.selectedStore = await this.storeService.getStore(storeKey);
    }
    return this.selectedStore;
  };

  protected getSupplyChannelIds = async (): Promise<string[]> => {
    return (await this.getSelectedStore())?.supplyChannels.map((x) => x.id) ?? [''];
  };

  // supplyChannel : inventory / availablity
  protected getSupplyChannelKeys = async (): Promise<string[]> => {
    return (await this.getSelectedStore())?.supplyChannels.map((x) => x.key) ?? [''];
  };

  //distributionChannel : pricing
  protected getDistributionChannelKey = async (): Promise<string> => {
    return (await this.getSelectedStore())?.distributionChannels.map((x) => x.key)[0] ?? '';
  };

  protected getDistributionChannelId = async (): Promise<string> => {
    return (await this.getSelectedStore())?.distributionChannels.map((x) => x.id)[0] ?? '';
  };
}
