import { InventoryEntry } from '../../schema/schema';
import { ProductInventorySearch } from '../../schema/types';
import { buildInventoryWhere } from '../../utils/search';
import { StoreContextServiceBase } from '../storeContextServiceBase';
import { inventoryEntriesBySkuListQuery, inventoryEntryByIdQuery } from './query';

export class InventoryService extends StoreContextServiceBase {
  getInventoryById = async (id: string): Promise<InventoryEntry> => {
    const response = await this.executeRequest<'inventoryEntry', InventoryEntry>(inventoryEntryByIdQuery, {
      id,
    });
    return response.inventoryEntry;
  };

  getInventoryBySkuList = async (search: Omit<ProductInventorySearch, 'supplyChannels'>, availablityChannelIds?: string[]): Promise<InventoryEntry> => {
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'inventoryEntry', InventoryEntry>(inventoryEntriesBySkuListQuery, {
      where: buildInventoryWhere({
        ...search,
        supplyChannels: availablityChannelIds,
      }),
    });
    return response.inventoryEntry;
  };
}
