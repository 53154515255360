/* eslint-disable no-unused-vars */

import { CategoryService, ProductService } from '@fieldera-raleys/client-commercetools';
import { Category as CommerceCategory, Product, RawCustomField } from '@fieldera-raleys/client-commercetools/schema';
import { ApisauceInstance, create } from 'apisauce';
import { CancelToken } from 'axios';
import dayjs from 'dayjs';
import { constants } from '../../constants';
import {
  AutoQueryResult,
  AutoSuggestResults,
  CacheProvider,
  CampaignResult,
  CampaignUIType,
  Category,
  CategoryCrumb,
  CategoryWidgetParams,
  FacetType,
  FacetTypeMap,
  Filter,
  FilterItem,
  ItemWidgetParams,
  KeywordRedirectResult,
  KeywordWidgetParams,
  PagedArray,
  PersonalizedWidgetParams,
  ProductSearchResult,
  SearchAssistType,
  SearchResult,
  SearchType,
  WidgetParams,
  WidgetType,
} from '../../types';
import { addLogger } from '../../utils';
import { buildCampaignUI, buildKeywordRedirect, toCamelCase } from '../../utils/helpers';
import logger from '../../utils/logger';
import { HelpTopics, HelpTopicsQuestions } from '../dummyData';
import {
  SearchResponse as BRSMSearchResult,
  Doc,
  FacetCategory,
  FacetFieldValue,
  FacetFields,
  HelpTopicsType,
  Question,
  RecomendationResult,
  ShelfGuideData,
  Widget,
} from './types';

// keys of Doc in ./type
const SEARCH_FILED_LIST = 'pid,title,shelf_guide_indicators,benefits_indicators,promo_indicators,sale_price,price,upcs';

const __DEV__ = (() => !process.env.NODE_ENV || process.env.NODE_ENV === 'development')();

/**
 * This method is used to convert an array with parent-child relationship into an array with tree structure
 * Receive an array with parent-child relationship as a parameter
 * Returns an array of tree structures
 */
const createTree = (data: Category[]) => {
  const categoryTree: Category[] = [];
  const lookup: { [key: string]: Category } = {};

  data.forEach((item) => {
    if (item.parentId && lookup[item.parentId]) {
      // add to the parent's child list
      lookup[item.parentId].children.push(item);
    } else {
      // no parent added yet (or this is the first time)
      categoryTree.push(item);
    }
    lookup[item.id] = item;
  });
  sortCategoryTree(categoryTree);
  return categoryTree;
};

const sortCategoryTree = (nodes: Category[]) => {
  nodes.sort((a, b) => (a.orderHint > b?.orderHint ? 1 : -1));
  nodes.forEach(function (node) {
    if (node.children.length) {
      sortCategoryTree(node.children);
    }
  });
};

const getParent = (crunb: string) => {
  const result = crunb.split('/');
  return result.length > 3 ? result[result.length - 2] : undefined;
};

const mapFilterItem = (facetFields: FacetFields, selectedFilters: Filter[], defaultFilters: Filter[], rootCategoryKey: string): Filter[] => {
  const facetFilters: Filter[] = [];
  Object.entries(FacetTypeMap).forEach((m) => {
    const title = FacetType[m[0]];
    const field = m[1];
    let facet = facetFields[field];
    if (field === 'category') {
      facet = (facet as FacetCategory[]).filter((x) => x.cat_id !== rootCategoryKey);
    }
    if (facet) {
      facetFilters.push({
        id: field,
        title: title,
        children: facet.map((y) => {
          if ('cat_id' in y) {
            return {
              id: y.cat_id,
              default: false,
              selected: false,
              title: `${y.cat_name} (${y.count})`,
              value: y.cat_id,
              controlType: 'switch',
              parentId: getParent(y.crumb),
            } as FilterItem;
          } else if ('name' in y) {
            return {
              id: toCamelCase(y.name),
              default: false,
              selected: false,
              title: `${y.name} (${y.count})`,
              value: `"${y.name}"`,
              controlType: 'switch',
            } as FilterItem;
          } else {
            return undefined;
          }
        }),
      } as Filter);
    }
  });

  const result: Filter[] = [...defaultFilters, ...(facetFilters ?? []).filter((f) => f.children.length)];

  /// apply selection
  selectedFilters.forEach((sf) => {
    const filter = result.find((f) => f.id === sf.id);
    if (filter) {
      filter.children.forEach((fc) => {
        fc.selected = sf.children.find((sfc) => sfc.id === fc.id)?.selected ?? false;
      });
    }
  });
  return result;
};

const buildFacetQuery = (selectedFilters: Filter[]): string => {
  return selectedFilters
    .map((sf) => {
      return `${sf.id}:(${sf.children
        .map((sfc) => {
          const value = typeof sfc.value === 'function' ? sfc.value() : sfc.value;
          return `${Array.isArray(value) ? value.join(' OR ') : value}`;
        })
        .join(' OR ')})`;
    })
    .join(' AND ');
};

const sortProducts = (productlist: Product[], sortOrder: Map<string, number>) => {
  return productlist.sort((a, b) => {
    const aSku = a.masterData.current?.masterVariant.sku ?? '';
    const bSku = b.masterData.current?.masterVariant.sku ?? '';
    return (sortOrder.get(aSku) ?? -1) - (sortOrder.get(bSku) ?? -1);
  });
};

const getProductAisleBayBin = (product: Product): string | undefined => {
  const pricingAttributes: RawCustomField[] | undefined = product.masterData.current?.masterVariant?.price?.custom?.customFieldsRaw ?? undefined;

  return pricingAttributes?.find((f) => f.name === 'areaBayShelfBin')?.value ?? undefined;
};

export type ProductsParam = {
  skus?: string[];
  contextId?: string;
  url: string | undefined;
  limit?: number;
};

export class BRSMService {
  private apiClient: ApisauceInstance;
  private suggestClient: ApisauceInstance;
  private recomendationsClient: ApisauceInstance;
  private categoryService: CategoryService;
  private productService: ProductService;
  private accountId: string;
  private baseUrl: string;
  private selectedStoreKey: string | (() => string);
  private defaultFilters: Filter[];
  private defaultStoreId: string;
  private uniqueId: () => Promise<string>;
  private rootCategoryKey: string;
  private cacheProvider?: CacheProvider;
  private userId: string | (() => string);

  private defaultSearchParams: Record<string, string> = {
    account_id: '',
    domain_key: 'raleys',
    request_type: 'search',
    url: '',
    ref_url: '',
    search_type: 'keyword',
    fl: SEARCH_FILED_LIST,
    sort: '',
    q: '*',
  };

  private getSelectedStoreKey = (): string | undefined => {
    return typeof this.selectedStoreKey === 'string' ? this.selectedStoreKey : this.selectedStoreKey();
  };

  private getUserId = (): string | undefined => {
    return typeof this.userId === 'string' ? this.userId : this.userId();
  };

  private parseSearchQuery = (query?: string): string => {
    return !query || query === '*' ? this.rootCategoryKey : query;
  };

  private getUrl = (pathname: string, storeId?: string): string => {
    const url = new URL(this.baseUrl);
    if (storeId) {
      url.searchParams.append('store_id', storeId);
    }
    url.pathname = pathname;
    return url.toString().toLowerCase();
  };

  constructor(config: {
    brsmSearchUrl: string;
    brsmSuggestUrl: string;
    brsmRecomendationsUrl: string;
    commerceToolsApiUrl: string;
    accountId: string;
    baseURL: string;
    defaultFilters: Filter[];
    defaultStoreId: string;
    rootCategoryKey: string;
    cacheProvider?: CacheProvider;
    uniqueId: () => Promise<string>;
    userId: string | (() => string);
    selectedStoreKey: string | (() => string);
    authToken: () => Promise<string>;
  }) {
    this.apiClient = create({ baseURL: config.brsmSearchUrl });
    this.suggestClient = create({ baseURL: config.brsmSuggestUrl });
    this.recomendationsClient = create({ baseURL: config.brsmRecomendationsUrl });
    this.categoryService = new CategoryService({ apiUrl: config.commerceToolsApiUrl, authToken: config.authToken });
    this.productService = new ProductService({
      apiUrl: config.commerceToolsApiUrl,
      authToken: config.authToken,
      selectedStoreKey: config.selectedStoreKey,
    });
    this.accountId = config.accountId;
    this.baseUrl = config.baseURL;
    this.defaultFilters = config.defaultFilters;
    this.defaultSearchParams = { ...this.defaultSearchParams, account_id: config.accountId };
    this.defaultStoreId = config.defaultStoreId;
    this.uniqueId = config.uniqueId;
    this.rootCategoryKey = config.rootCategoryKey;
    this.cacheProvider = config.cacheProvider;
    this.selectedStoreKey = config.selectedStoreKey;
    this.userId = config.userId;

    if (__DEV__) {
      const searchLogger = require('debug')('api:brsm');
      addLogger(this.apiClient.axiosInstance, searchLogger); // only when debugging to access _ members directly to avoid top level await compile errors for the target
      const suggestLogger = require('debug')('api:brsm:suggest');
      addLogger(this.suggestClient.axiosInstance, suggestLogger); // only when debugging to access _ members directly to avoid top level await compile errors for the target
      const recomendationLogger = require('debug')('api:brsm:recomendation');
      addLogger(this.recomendationsClient.axiosInstance, recomendationLogger); // only when debugging to access _ members directly to avoid top level await compile errors for the target
    }
  }

  private getShelfGuideInternal = async (storeId: string, sortOrder: Record<string, number>): Promise<ShelfGuideData[]> => {
    const params: Record<string, string | number | undefined> = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      url: this.getUrl('/', storeId),
      request_id: dayjs().unix(),
      rows: 0,
      start: 0,
      fl: 'pid',
      search_type: 'category',
      q: this.rootCategoryKey,
      view_id: storeId,
    };

    try {
      const response = await this.apiClient.get<BRSMSearchResult>('/', params);

      if (response.ok && response.data) {
        const shelfGuides = (response.data.facet_counts.facet_fields?.shelf_guide_indicators ?? []) as FacetFieldValue[];
        const shelfGuideData = (shelfGuides ?? []).map((item) => {
          return {
            id: toCamelCase(item.name),
            name: item.name,
            count: item.count,
          } as ShelfGuideData;
        });
        if (shelfGuideData && shelfGuideData.length) {
          shelfGuideData.sort((a, b) => sortOrder[a.name] - sortOrder[b.name]);
          return shelfGuideData;
        }
      } else {
        throw new Error(`getShelfGuideInternal => ${response.originalError?.message}`);
      }
    } catch (ex) {
      logger.error(ex);
    }
    return [];
  };

  getSelfGuides = async (storeId: string, sortOrder: Record<string, number>, refetch: boolean = false): Promise<ShelfGuideData[]> => {
    const returnValue: ShelfGuideData[] = [];
    if (this.cacheProvider) {
      if (!refetch) {
        const cachedData = await this.cacheProvider.hashGet<ShelfGuideData[]>(
          `shelfGuide`,
          [storeId],
          async () => {
            const shelfGuide = await this.getShelfGuideInternal(storeId, sortOrder);
            return { [storeId]: shelfGuide };
          },
          240,
        );
        return cachedData ? cachedData[storeId] : [];
      } else {
        const shelfGuide = await this.getShelfGuideInternal(storeId, sortOrder);
        await this.cacheProvider.hashRemove(`shelf_guide`, [storeId]).catch(() => {
          logger.error(`failed to delete cache hashKey:${shelfGuide} key:${storeId}`);
        });
        this.cacheProvider.hashSet(`shelf_guide`, { [storeId]: shelfGuide }, 240).catch(() => {
          logger.error(`failed to set cache hashKey:${shelfGuide} key:${storeId}`);
        });

        return shelfGuide;
      }
    }
    await this.getShelfGuideInternal(storeId, sortOrder);
    return returnValue ?? (await this.getShelfGuideInternal(storeId, sortOrder));
  };

  private getCommercetoolsCategoriesInternal = async (categoryIds: string[]): Promise<Record<string, CommerceCategory>> => {
    let result: CommerceCategory[] = [];
    const chunkSize = 50;
    const promiseArray: Promise<CommerceCategory[]>[] = [];
    for (let i = 0; i < categoryIds.length; i += chunkSize) {
      const chunk = categoryIds.slice(i, i + chunkSize);
      promiseArray.push(
        this.categoryService.getCategories({
          search: {
            key: chunk,
            limit: chunk.length,
          },
        }),
      );
    }
    result = (await Promise.all(promiseArray)).flat(1);
    return result.reduce(
      (collector, cat) => {
        const key = cat.key ?? undefined;
        if (key) {
          collector[key] = cat;
        }
        return collector;
      },
      {} as Record<string, CommerceCategory>,
    );
  };

  private getCommercetoolsCategories = async (categoryIds: string[]): Promise<CommerceCategory[]> => {
    let result: Record<string, CommerceCategory> = {};
    if (this.cacheProvider) {
      const cachedData = await this.cacheProvider.hashGet('ct:categories', categoryIds, async () => this.getCommercetoolsCategoriesInternal(categoryIds), 240);
      const cachedKeys = Object.keys(cachedData ?? {});
      const missingKeys = categoryIds.filter((id) => !cachedKeys.includes(id));
      const missingData = await this.getCommercetoolsCategoriesInternal(missingKeys);
      this.cacheProvider.hashSet('ct:categories', missingData).catch(() => {
        logger.error('failed to set missing data ct:categories');
      });

      return Object.values({ ...cachedData, ...missingData });
    }

    result = await this.getCommercetoolsCategoriesInternal(categoryIds);

    return Object.values(result);
  };

  private getCommercetoolsCategory = async (categoryId: string): Promise<CommerceCategory | undefined> => {
    if (this.cacheProvider) {
      return await this.cacheProvider.getItem(`ct:category:${categoryId}`, async () => this.categoryService.getCategoryByKey({ key: categoryId }), 240);
    }

    return this.categoryService.getCategoryByKey({ key: categoryId });
  };

  private getAllCategoriesInternal = async (storeId: string): Promise<Category[] | undefined> => {
    //1. fetch toplevel categories from commerceTools
    const rootCategory: CommerceCategory | undefined = await this.getCommercetoolsCategory(this.rootCategoryKey);
    if (!rootCategory || !rootCategory.children) {
      return;
    }
    const returnValue: Category[] = [];
    try {
      for (let x = 0; x < (rootCategory.children.length ?? 0); x++) {
        const child = rootCategory.children[x];
        const params = {
          ...this.defaultSearchParams,
          '_br_uid_2': await this.uniqueId(),
          'url': this.getUrl('/', storeId),
          'rows': 0,
          'start': 0,
          'search_type': 'category',
          'q': `${child.key}`,
          'view_id': storeId,
          'fl': 'pid',
          'request_id': `${dayjs().unix()}`,
          'f.category.facet.prefix': `/${rootCategory.key},${rootCategory.name}/${child.key}`,
        };

        const response = await this.apiClient.get<BRSMSearchResult>('/', params);

        if (response.ok && response.data) {
          const categories = response.data.facet_counts.facet_fields.category as FacetCategory[];
          const categoryMap = response.data.category_map;
          const ctCategories: CommerceCategory[] = await this.getCommercetoolsCategories(categories.map((x) => x.cat_id));
          const categoryData = categories
            .filter((x) => x.cat_id !== this.rootCategoryKey)
            .map((item) => {
              const ctCategory = ctCategories.find((x) => x.key === item.cat_id);
              return {
                id: item.cat_id,
                name: item.cat_name,
                imageName: `${item.cat_name.replace(/[^A-Z0-9]/gi, '').toLowerCase()}.jpg`,
                parentId: item.parent,
                parentName: item.parent && item.parent !== this.rootCategoryKey ? categoryMap[item.parent] : '',
                rootId: '',
                rootName: '',
                count: item.count,
                orderHint: ctCategory?.orderHint ?? 0.8,
                crumb: item.crumb
                  .split('/')
                  .map((val, idx, array) => {
                    return {
                      categoryId: val,
                      categoryName: categoryMap[val],
                      parentId: idx > 0 ? array[idx - 1] : '',
                      rootId: idx > 1 ? array[1] : '',
                    } as CategoryCrumb;
                  })
                  .filter((cat) => cat.categoryId !== ''),
                children: [] as Category[],
              } as Category;
            });
          if (categoryData && categoryData.length) {
            returnValue.push(createTree(categoryData)[0]);
          }
        } else {
          throw new Error(`getAllCategories => ${response.originalError?.message}`);
        }
      }
    } catch (ex) {
      logger.error(ex);
      return undefined;
    }
    return returnValue;
  };

  private getPreviouslyPurchasedProductsInternal = async (
    sepi: string,
    storeId: string,
    pids: string[],
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    cancelToken?: CancelToken,
  ): Promise<Record<string, boolean>> => {
    const params = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      q: this.rootCategoryKey,
      search_type: 'category',
      url: this.getUrl('/', storeId),
      request_id: dayjs().unix(),
      rows: limit,
      start: offset,
      efq: `past_purchase_customer_ids:(${sepi})${pids.length ? ' AND pid:(' + pids.join(' OR ') + ')' : ''}`,
      view_id: storeId,
    };
    let returnValue: string[] = [];
    try {
      const response = await this.apiClient.get<BRSMSearchResult>('/', params, { cancelToken });
      if (response.ok && response.data) {
        returnValue = response.data.response.docs.map((x) => x.pid);
      } else {
        throw new Error(`getPreviouslyPurchasedProducts => ${response.originalError?.message}`);
      }
    } catch (ex) {
      logger.error(ex);
      return {};
    }
    return pids.reduce(
      (collector, pid) => {
        collector[pid] = returnValue.includes(pid);
        return collector;
      },
      {} as Record<string, boolean>,
    );
  };

  getAllCategories = async (storeId: string, refetch: boolean = false): Promise<Category[] | undefined> => {
    const result = this.cacheProvider
      ? this.cacheProvider.getItem<Category[] | undefined>(
          `categories:${storeId}`,
          async () => await this.getAllCategoriesInternal(storeId),
          undefined,
          refetch,
        )
      : await this.getAllCategoriesInternal(storeId);
    return result ?? (await this.getAllCategoriesInternal(storeId));
  };

  getProductsByCategoryId = async (
    categoryId: string,
    offset: number = 0,
    limit: number = 25,
    {
      ref_url,
      sortQuery = '',
      selectedFilters,
      storeKey,
      pathname = '',
    }: { pathname?: string; ref_url?: string; sortQuery?: string; selectedFilters?: Filter[]; storeKey?: string },
    cancelToken?: CancelToken,
  ): Promise<ProductSearchResult> => {
    const storeId = storeKey ? storeKey : this.getSelectedStoreKey() ?? this.defaultStoreId;
    const selectedCategory = await this.getCommercetoolsCategory(categoryId);
    let startWith: string | undefined;
    if (selectedCategory) {
      startWith = [...selectedCategory.ancestors.map((a) => `/${a.key},${a.name}`), `/${selectedCategory.key},${selectedCategory.name}`].join('');
    }
    const params = {
      ...this.defaultSearchParams,
      '_br_uid_2': await this.uniqueId(),
      'ref_url': ref_url ? this.getUrl(ref_url) : '',
      'url': this.getUrl(pathname, storeId),
      'rows': limit,
      'start': offset,
      'search_type': 'category',
      'q': categoryId,
      'efq': buildFacetQuery(selectedFilters ?? []) ?? '',
      'sort': sortQuery,
      'view_id': storeId,
      'f.category.facet.prefix': startWith,
    };

    return this.executeProductSearch('/', params, offset, limit, selectedFilters, cancelToken);
  };

  searchProduct = async (
    offset: number,
    limit: number,
    {
      pathname = '/',
      ref_url,
      searchQuery,
      searchType = 'keyword',
      sortQuery = '',
      selectedFilters,
      storeKey,
    }: {
      pathname?: string;
      ref_url?: string;
      searchQuery: string;
      searchType?: SearchType;
      sortQuery?: string;
      selectedFilters?: Filter[];
      storeKey?: string;
    },
    cancelToken?: CancelToken,
  ): Promise<SearchResult<Product>> => {
    const storeId = storeKey ?? this.getSelectedStoreKey() ?? this.defaultStoreId;
    searchQuery = this.parseSearchQuery(searchQuery);
    const params: Record<string, string | number | undefined> = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      ref_url: ref_url ? this.getUrl(ref_url) : '',
      url: this.getUrl(pathname, storeId),
      request_id: dayjs().unix(),
      rows: limit,
      start: offset,
      search_type: searchQuery === this.rootCategoryKey ? 'category' : searchType,
      q: searchQuery,
      efq: buildFacetQuery(selectedFilters ?? []) ?? '',
      sort: sortQuery,
      view_id: storeId,
    };

    let returnValue: SearchResult<Product> = {
      autoCorrectQuery: '',
      didYouMean: [] as string[],
      docs: { data: [], offset: offset, limit: limit, total: 0 } as PagedArray<Product>,
      facetFilters: [] as Filter[],
      campaign: {} as CampaignResult,
      keywordRedirect: { redirectedURL: '', redirectedQuery: '', originalQuery: '' } as KeywordRedirectResult,
    };
    try {
      const response = await this.apiClient.get<BRSMSearchResult>('/', params, { cancelToken });
      if (response.ok && response.data) {
        const productlist = await this.mergeProductData(response.data.response.docs, cancelToken);
        const campaignUIResult: CampaignUIType = response.data.campaign ? buildCampaignUI(response.data.campaign) : ({} as CampaignUIType);

        returnValue = {
          autoCorrectQuery: response.data.autoCorrectQuery,
          didYouMean: response.data.did_you_mean,
          campaign: { ...response.data.campaign, campaignUI: campaignUIResult },
          keywordRedirect: buildKeywordRedirect(response.data.keywordRedirect),
          docs: { offset: offset, limit: limit, data: productlist, total: response.data.response.numFound },
          facetFilters: mapFilterItem(response.data.facet_counts.facet_fields, selectedFilters ?? [], this.defaultFilters, this.rootCategoryKey) ?? [],
        };
      } else {
        throw new Error(`searchProduct => ${response.originalError?.message}`);
      }
    } catch (ex) {
      logger.error(ex);
      throw ex;
    }
    return returnValue;
  };

  getProductsOnSale = async (
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    {
      searchQuery,
      sortQuery = '',
      selectedFilters,
      storeKey,
      ref_url,
      pathname = '/',
    }: { pathname?: string; ref_url?: string; searchQuery?: string; sortQuery?: string; selectedFilters?: Filter[]; storeKey?: string },
    cancelToken?: CancelToken,
  ): Promise<PagedArray<Product>> => {
    const storeId = storeKey ?? this.getSelectedStoreKey() ?? this.defaultStoreId;
    const sepi = this.getUserId();
    const facetQuery = buildFacetQuery(selectedFilters?.filter((x) => x.id !== 'on_sale_store_ids') ?? []);
    searchQuery = this.parseSearchQuery(searchQuery);

    const params = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      ref_url: ref_url ? this.getUrl(ref_url) : '',
      url: this.getUrl(pathname, storeId),
      request_id: dayjs().unix(),
      rows: limit,
      start: offset,
      view_id: storeId,
      sepi,
      q: searchQuery,
      search_type: searchQuery === this.rootCategoryKey ? 'category' : 'keyword',
      efq: `on_sale_store_ids:("${storeId}") ${facetQuery ? `AND ${facetQuery}` : ''}`,
      sort: sortQuery,
    };
    const result = await this.executeProductSearch('/', params, offset, limit, undefined, cancelToken);
    return result.docs;
  };

  getProductsByLoyaltyPromotionId = async (
    extPromotionId: string,
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    {
      searchQuery,
      sortQuery = '',
      selectedFilters,
      storeKey,
      ref_url,
      pathname = '/',
    }: { pathname?: string; ref_url?: string; searchQuery?: string; sortQuery?: string; selectedFilters?: Filter[]; storeKey?: string },
    cancelToken?: CancelToken,
  ): Promise<PagedArray<Product>> => {
    const storeId = storeKey ?? this.getSelectedStoreKey() ?? this.defaultStoreId;
    const sepi = this.getUserId();
    const facetQuery = buildFacetQuery(selectedFilters?.filter((x) => x.id !== 'loyalty_promo_ids') ?? []);
    searchQuery = this.parseSearchQuery(searchQuery);
    const params = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      ref_url: ref_url ? this.getUrl(ref_url) : '',
      url: this.getUrl(pathname, storeId),
      request_id: dayjs().unix(),
      rows: limit,
      start: offset,
      efq: `loyalty_promo_ids:("${extPromotionId}") ${facetQuery ? `AND ${facetQuery}` : ''}`,
      view_id: storeId,
      sepi,
      q: searchQuery,
      search_type: searchQuery === this.rootCategoryKey ? 'category' : 'keyword',
      sort: sortQuery,
    };
    const result = await this.executeProductSearch('/', params, offset, limit, selectedFilters, cancelToken);
    return result.docs;
  };

  getProductsByDigitalPromotionId = async (
    extPromotionId: string,
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    {
      searchQuery,
      sortQuery = '',
      selectedFilters,
      storeKey,
      ref_url,
      pathname = '/',
    }: { pathname?: string; ref_url?: string; searchQuery?: string; sortQuery?: string; selectedFilters?: Filter[]; storeKey?: string },
    cancelToken?: CancelToken,
  ): Promise<PagedArray<Product>> => {
    const storeId = storeKey ?? this.getSelectedStoreKey() ?? this.defaultStoreId;
    const sepi = this.getUserId();
    const facetQuery = buildFacetQuery(selectedFilters?.filter((x) => x.id !== 'coupon_promo_ids') ?? []);
    searchQuery = this.parseSearchQuery(searchQuery);
    const params = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      ref_url: ref_url ? this.getUrl(ref_url) : '',
      url: this.getUrl(pathname, storeId),
      request_id: dayjs().unix(),
      rows: limit,
      start: offset,
      efq: `coupon_promo_ids:("${extPromotionId}") ${facetQuery ? `AND ${facetQuery}` : ''}`,
      view_id: storeId,
      sepi,
      q: searchQuery,
      search_type: searchQuery === this.rootCategoryKey ? 'category' : 'keyword',
      sort: sortQuery,
    };
    const result = await this.executeProductSearch('/', params, offset, limit, selectedFilters, cancelToken);
    return result.docs;
  };

  getProductsByCollectionId = async (
    extCollectionId: string,
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    {
      searchQuery,
      sortBy = '',
      selectedFilters,
      storeKey,
      ref_url,
      pathname = '/',
    }: { pathname?: string; ref_url?: string; searchQuery?: string; sortBy?: string; selectedFilters?: Filter[]; storeKey?: string },
    cancelToken?: CancelToken,
  ): Promise<ProductSearchResult> => {
    const storeId = storeKey ?? this.getSelectedStoreKey() ?? this.defaultStoreId;
    const sepi = this.getUserId();
    const facetQuery = buildFacetQuery(selectedFilters?.filter((x) => x.id !== 'promo_indicators') ?? []);
    searchQuery = this.parseSearchQuery(searchQuery);
    const params = {
      ...this.defaultSearchParams,
      _br_uid_2: await this.uniqueId(),
      ref_url: ref_url ? this.getUrl(ref_url) : '',
      url: this.getUrl(pathname, storeId),
      request_id: dayjs().unix(),
      rows: limit,
      start: offset,
      efq: `promo_indicators:("${extCollectionId}") ${facetQuery ? `AND ${facetQuery}` : ''}`,
      view_id: storeId,
      sepi,
      q: searchQuery,
      search_type: searchQuery === this.rootCategoryKey ? 'category' : 'keyword',
      sort: sortBy,
    };
    const result = await this.executeProductSearch('/', params, offset, limit, selectedFilters, cancelToken);
    return result;
  };

  getPreviouslyPurchasedProducts = async (
    pids: string[],
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    cancelToken?: CancelToken,
  ): Promise<string[]> => {
    const sepi = this.getUserId();
    if (!sepi) {
      return [];
    }
    const storeId = this.getSelectedStoreKey() ?? this.defaultStoreId;
    if (this.cacheProvider) {
      const cachedData = await this.cacheProvider.hashGet(
        `previously_purchased:${sepi}:${storeId}`,
        pids,
        async () => this.getPreviouslyPurchasedProductsInternal(sepi, storeId, pids, offset, limit, cancelToken).catch(() => ({})),
        240,
      );
      const cachedKeys = Object.keys(cachedData ?? {});
      const missingKey = pids.filter((pid) => !cachedKeys.includes(pid));
      const missingData = await this.getPreviouslyPurchasedProductsInternal(sepi, storeId, missingKey, offset, pids.length, cancelToken);
      this.cacheProvider.hashSet(`previously_purchased:${sepi}:${storeId}`, missingData, 240).catch(() => {
        /*empty*/
      });
      return Object.entries({ ...(cachedData ?? {}), ...missingData })
        .map(([key, value]) => (value ? key : ''))
        .flat(1)
        .filter((x) => !!x);
    }
    const data = await this.getPreviouslyPurchasedProductsInternal(sepi, storeId, pids, offset, limit, cancelToken);
    return Object.entries(data)
      .map(([key, value]) => (value ? key : ''))
      .flat(1)
      .filter((x) => !!x);
  };

  getAutoSuggest = async (searchText: string): Promise<AutoQueryResult | undefined> => {
    const BASE_URL = `?q=${searchText}&request_type=suggest&request_id=${dayjs().unix()}&account_id=${this.accountId}&catalog_views=raleys&url=${this.baseUrl}`;
    try {
      const response = await this.suggestClient.get<AutoSuggestResults>(BASE_URL);
      if (response.ok && response.data) {
        return {
          attributeSuggestions: response.data?.suggestionGroups[0]?.attributeSuggestions ?? undefined,
          querySuggestions: response.data?.suggestionGroups[0]?.querySuggestions ?? undefined,
        };
      }
    } catch (ex) {
      logger.error(ex);
    }
    return undefined;
  };

  getSearchAssistData = async (): Promise<SearchAssistType> => {
    const responseData = {
      popularItems: ['Milk', 'Eggs', 'Butter', 'Bread', 'Cereal'],
      featuredItems: [],
      recentlyViewedItems: [],
    };
    //https://staging-suggest.dxpapi.com/api/v2/suggest/?account_id=6518&auth_key=&catalog_views=1&request_id=7546919099987&_br_uid_2=uid%3D5917073780329%3A_uid%3D9737480431795%3Av%3D11.8%3Ats%3D1459840113832%3Ahc%3D37&url=www.example.com&ref_url=http://www.example.com/&q=cak&request_type=suggest
    return Promise.resolve(responseData);
  };

  getWidgetData = async (
    widgetType: keyof typeof WidgetType,
    widgetId: string,
    widgetParams?:
      | Omit<WidgetParams, 'type'>
      | Omit<ItemWidgetParams, 'type'>
      | Omit<KeywordWidgetParams, 'type'>
      | Omit<CategoryWidgetParams, 'type'>
      | Omit<PersonalizedWidgetParams, 'type'>,
    offset: number = 0,
    limit: number = constants.DefaultPageSize,
    cancelToken?: CancelToken,
  ): Promise<RecomendationResult> => {
    const storeId = this.getSelectedStoreKey() ?? this.defaultStoreId;
    const userId = this.getUserId() ?? '';
    const params: Record<string, number | string | string[] | undefined> = {
      ...widgetParams,
      account_id: this.accountId,
      _br_uid_2: await this.uniqueId(),
      domain_key: 'raleys',
      url: this.getUrl(widgetParams?.url ?? '/', storeId),
      ref_url: widgetParams?.ref_url ? this.getUrl(widgetParams.ref_url, storeId) : undefined,
      request_id: dayjs().unix(),
      fields: SEARCH_FILED_LIST,
      rows: limit,
      start: offset,
      view_id: storeId,
      user_id: userId,
    };

    return this.executeRecomendation(`${WidgetType[widgetType]}/${widgetId}`, params, offset, limit, cancelToken);
  };

  getSitemapProductsByCategoryId = async (
    categoryId: string,
    offset: number = 0,
    limit: number = 25,
    storeKey?: string,
    cancelToken?: CancelToken,
  ): Promise<Pick<SearchResult<{ sku: string; title: string }>, 'docs'>> => {
    const storeId = storeKey ? storeKey : this.getSelectedStoreKey() ?? this.defaultStoreId;
    const selectedCategory = await this.getCommercetoolsCategory(categoryId);
    let startWith: string | undefined;
    if (selectedCategory) {
      startWith = [...selectedCategory.ancestors.map((a) => `/${a.key},${a.name}`), `/${selectedCategory.key},${selectedCategory.name}`].join('');
    }
    const params = {
      ...this.defaultSearchParams,
      '_br_uid_2': await this.uniqueId(),
      'url': this.getUrl('/', storeId),
      'rows': limit,
      'start': offset,
      'search_type': 'category',
      'q': categoryId,
      'view_id': storeId,
      'f.category.facet.prefix': startWith,
    };

    return this.executeSearch('/', params, offset, limit, cancelToken);
  };

  private executeSearch = async (
    query: string,
    params: object,
    offset: number,
    limit: number,
    cancelToken?: CancelToken,
  ): Promise<Pick<SearchResult<{ sku: string; title: string }>, 'docs'>> => {
    let returnValue: Pick<SearchResult<{ sku: string; title: string }>, 'docs'> = {
      docs: { data: [], offset: offset, limit: limit, total: 0 } as PagedArray<{ sku: string; title: string }>,
    };

    try {
      const response = await this.apiClient.get<BRSMSearchResult>(query, params, { cancelToken });
      if (response.ok && response.data) {
        const productlist = response.data.response.docs.map((val) => ({ sku: val.pid, title: val.title ?? '' }));

        returnValue = {
          docs: { offset: offset, limit: limit, data: productlist, total: response.data.response.numFound },
        };
      } else {
        throw new Error(`executeProductSearch => ${response.originalError?.message}`);
      }
    } catch (ex) {
      logger.error(ex);
    }

    return returnValue;
  };

  private executeRecomendation = async (
    query: string,
    params: object,
    offset: number,
    limit: number,
    cancelToken?: CancelToken,
  ): Promise<RecomendationResult> => {
    let returnValue = { products: { data: [], offset: offset, limit: limit, total: 0 } as PagedArray<Product>, widgetData: {} as Widget };

    const response = await this.recomendationsClient.get<BRSMSearchResult>(query, params, { cancelToken });
    if (response.ok && response.data) {
      const productlist = await this.mergeProductData(response.data.response.docs, cancelToken);
      const metadata = response.data.metadata.widget;

      returnValue = { products: { offset: offset, limit: limit, data: productlist, total: response.data.response.numFound }, widgetData: metadata };
    } else {
      throw new Error(`executeRecomendation => ${response.originalError?.message}`);
    }
    return returnValue;
  };

  private executeProductSearch = async (
    query: string,
    params: object,
    offset: number,
    limit: number,
    selectedFilters?: Filter[],
    cancelToken?: CancelToken,
  ): Promise<ProductSearchResult> => {
    let returnValue: ProductSearchResult = {
      docs: { data: [], offset: offset, limit: limit, total: 0 } as PagedArray<Product>,
      facetFilters: [] as Filter[],
      campaign: {} as CampaignResult,
      keywordRedirect: { redirectedURL: '', redirectedQuery: '', originalQuery: '' } as KeywordRedirectResult,
    };

    try {
      const response = await this.apiClient.get<BRSMSearchResult>(query, params, { cancelToken });
      if (response.ok && response.data) {
        const productlist = await this.mergeProductData(response.data.response.docs, cancelToken);
        const campaignUIResult: CampaignUIType = response.data.campaign ? buildCampaignUI(response.data.campaign) : ({} as CampaignUIType);

        returnValue = {
          docs: { offset: offset, limit: limit, data: productlist, total: response.data.response.numFound },
          facetFilters: mapFilterItem(response.data.facet_counts.facet_fields, selectedFilters ?? [], this.defaultFilters, this.rootCategoryKey) ?? [],
          campaign: { ...response.data.campaign, campaignUI: campaignUIResult },
          keywordRedirect: buildKeywordRedirect(response.data.keywordRedirect),
        };
      } else {
        throw new Error(`executeProductSearch => ${response.originalError?.message}`);
      }
    } catch (ex) {
      logger.error(ex);
    }

    return returnValue;
  };

  private mergeProductData = async (docs: Doc[], cancelToken?: CancelToken): Promise<Product[]> => {
    if (!docs || docs.length === 0) {
      return [];
    }
    const skuList = docs.reduce((list, x) => list.set(x.pid, x), new Map<string, Doc>());
    const sortOrder = docs.reduce((list, x, idx) => list.set(x.pid, idx), new Map<string, number>());
    const skuArray = [...skuList.keys()];
    let productlist: Product[] = await this.getProductsFromCommerceTools(skuArray);
    const previouslyPurchased = await this.getPreviouslyPurchasedProducts(skuArray, 0, skuArray.length, cancelToken);
    productlist = productlist.reduce((result, x) => {
      if (x.masterData.current) {
        const attributesRaw = [...x.masterData.current.masterVariant.attributesRaw];
        const previouslyPurchasedFlag = previouslyPurchased.includes(x.masterData.current.masterVariant?.sku ?? 'XXXXXXXXXX');
        attributesRaw.push({
          name: 'previouslyPurchased',
          value: previouslyPurchasedFlag,
          referencedResourceSet: [],
        });
        const areaBayShelfBin = getProductAisleBayBin(x);
        attributesRaw.push({
          name: 'areaBayShelfBin',
          value: areaBayShelfBin,
          referencedResourceSet: [],
        });
        const promoIndicators = x.masterData.current?.masterVariant.sku ? skuList.get(x.masterData.current?.masterVariant.sku)?.promo_indicators : undefined;
        attributesRaw.push({
          name: 'promoIndicator',
          value: promoIndicators,
          referencedResourceSet: [],
        });
        const benefitsIndicators = x.masterData.current?.masterVariant.sku
          ? skuList.get(x.masterData.current?.masterVariant.sku)?.benefits_indicators
          : undefined;
        attributesRaw.push({
          name: 'benefitsIndicator',
          value: benefitsIndicators,
          referencedResourceSet: [],
        });

        x.masterData.current.masterVariant.attributesRaw = attributesRaw;
        result.push(x);
      }
      return result;
    }, [] as Product[]);
    return sortProducts(productlist, sortOrder);
  };

  private getProductsFromCommerceTools = async (skuArray: string[]) => {
    if (skuArray.length > 0) {
      const pageSize = 50;
      const totalPages = Math.ceil(skuArray.length / pageSize);
      const promiseArray: Promise<Product[]>[] = [];
      for (let idx = 0; idx < totalPages; idx++) {
        const start = idx * pageSize;
        const end = (idx + 1) * pageSize > skuArray.length ? skuArray.length : (idx + 1) * pageSize;
        promiseArray.push(this.productService.getProducts({ skus: skuArray.slice(start, end), limit: pageSize }));
      }
      return Promise.all(promiseArray).then((val) => val.flat(1));
    }
    return Promise.resolve([]);
  };

  getHelpTopicsData = async (): Promise<HelpTopicsType[]> => {
    return Promise.resolve(HelpTopics);
  };

  getHelpTopicsQuestions = async (): Promise<Question[]> => {
    return Promise.resolve(HelpTopicsQuestions);
  };
}
