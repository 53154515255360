import { CancelToken } from 'axios';
import { PagedArray, Store, TimeSlot, TimeSlotData } from '../../types';
import { toStore } from './mappers';
import { ServiceBase } from './serviceBase';
import { PagedResponse } from './types/common';
import { Store as StoreDto } from './types/store';
import { TimeSlotResponse } from './types/timeSlot';

export class StoreService extends ServiceBase {
  // get timeslot
  getTimeSlotsByStore = async (
    storeNumber: string,
    seedDateTime: string,
    leadTimeInMinutes: number,
    shippingMethod: number = 7,
    timeSlotDaysToReturn: number = 20,
    cts?: CancelToken,
  ): Promise<TimeSlot[]> => {
    const response = await this.executeRawRequest<TimeSlotResponse[]>(
      `/gettimeslots/${seedDateTime}/${leadTimeInMinutes}/${storeNumber}/${shippingMethod}/${timeSlotDaysToReturn}`,
      {},
      cts,
    );
    return response
      ? response.map(
          (x) =>
            ({
              timeSlotDate: x.TimeSlotDate,
              timeSlots: x.TimeSlots.map(
                (y) =>
                  ({
                    timeSlotId: y.TimeSlotId,
                    timeSlotHourStart: y.TimeSlotHourStart,
                    timeSlotHourEnd: y.TimeSlotHourEnd,
                    timeSlotPrice: y.TimeSlotPrice,
                    timeSlotDeliveryFee: y.TimeSlotDeliveryFee,
                    timeSlotAvailableQty: y.TimeSlotAvailableQty,
                  } as TimeSlotData),
              ),
            } as TimeSlot),
        )
      : [];
  };

  searchStores = async (
    offset: number = 0,
    rows: number = 25,
    {
      shippingMethod,
      searchString,
      postalCode,
      latitude,
      longitude,
      maxMiles,
      departmentIds,
    }: {
      shippingMethod: 'pickup' | 'delivery';
      searchString?: string;
      postalCode?: string;
      latitude?: number;
      longitude?: number;
      maxMiles?: number;
      departmentIds?: number[] | undefined;
    },
  ): Promise<PagedArray<Store>> => {
    const result = await this.executeRequest<'Stores', PagedResponse<StoreDto>>('/store/search', {
      PageNumber: offset >= rows ? Math.ceil(offset / rows) : 1,
      PageSize: rows,
      ShippingMethodId: shippingMethod === 'pickup' ? 7 : 8,
      SearchString: searchString,
      PostalCode: postalCode,
      Latitude: latitude,
      Longitude: longitude,
      MaxMiles: maxMiles,
      DepartmentIds: departmentIds,
    });
    return {
      data: (result.Stores.Data ?? []).map(toStore).sort((a, b) => (a.index ?? 99) - (b.index ?? 99)),
      limit: result.Stores.PageSize,
      offset: result.Stores.PageNumber * result.Stores.PageSize + 1,
      total: result.Stores.TotalCount,
    };
  };

  getStore = async (storeNumber: string, latitude?: number, longitude?: number): Promise<Store> => {
    const result = await this.executeRequest<'Store', StoreDto>('/store/get', {
      StoreNumber: storeNumber,
      Latitude: latitude,
      Longitude: longitude,
    });
    return toStore(result.Store);
  };

  getStores = async (storeNumbers: string[], latitude?: number, longitude?: number): Promise<Store[]> => {
    const result = await this.executeRequest<'Stores', StoreDto[]>('/stores/get', {
      StoreNumbers: storeNumbers,
      PageNumber: 1,
      PageSize: storeNumbers.length,
      Latitude: latitude,
      Longitude: longitude,
    });
    return result.Stores.map(toStore);
  };
}
