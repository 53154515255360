import { Question } from './brsm/types';

export const HelpTopics = [
  {
    id: '1',
    name: 'Order & Purchases',
  },
  {
    id: '2',
    name: 'Returns',
  },
  {
    id: '3',
    name: 'Cancelation Policy',
  },
  {
    id: '4',
    name: 'Delivery & Pickup',
  },
];

export const HelpTopicsQuestions: Question[] = [
  {
    title: 'Order & Purchases',
    question: 'Where can I find a history of my online and in-store orders?',
    id: '1',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Order & Purchases',
    question: 'Lorem ipsum dolor sit amet. Id eius dignissimos  eum dolor tenetur?',
    id: '1',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Returns',
    question: 'Where can I find a Returns of my online and in-store orders?',
    id: '2',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Returns',
    question: 'Lorem ipsum dolor sit amet. Id eius dignissimos  eum dolor tenetur?',
    id: '2',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Cancelation Policy',
    question: 'Where can I find a Cancelation Policy of my online and in-store orders?',
    id: '3',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Cancelation Policy',
    question: 'Lorem ipsum dolor sit amet. Id eius dignissimos  eum dolor tenetur?',
    id: '3',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Delivery & Pickup',
    question: 'Where can I find a Delivery & Pickup of my online and in-store orders?',
    id: '4',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
  {
    title: 'Delivery & Pickup',
    question: 'Lorem ipsum dolor sit amet. Id eius dignissimos  eum dolor tenetur?',
    id: '4',
    answer:
      'Lorem ipsum dolor sit amet. Et explicabo commodi At quia voluptatem in aliquam commodi aut sunt voluptatem est doloremque galisum. Rem consequatur velit et corporis similique.\n Nam officiis voluptates. Aut quaerat excepturi et illo repellat id mollitia omnis eos molestias numquam.Sit voluptatem vero et dolor excepturi hic laboriosam sint sit maxime assumenda sit vero sunt. Aut cupiditate culpa nam sapiente voluptas aut laudantium molestias aut unde consequatur aut libero veniam. Quo vero autem et tenetur debitis sed aperiam sequi vel cupiditate facere vel dicta ratione rem delectus quia.',
  },
];
