import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { Cart, LineItem, MyCartUpdateAction } from '@fieldera-raleys/client-commercetools/schema';

type Prod = {
  LineItemId: string;
  ExtProductId: string;
  Quantity: number;
  Price: number;
  RegularPrice: number;
  EstimatedTotalWeight?: number;
};

export const estimateLineItemWeight = (lineItem: LineItem, quantity: number): number | undefined => {
  let sellType = getProductAttributeValue('unitSellType', lineItem.variant?.attributesRaw ?? []);
  if (!sellType) {
    sellType = { key: 'byEach' };
  }
  const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', lineItem.variant?.attributesRaw ?? []);
  const unitBuyIncrement = getProductAttributeValue('unitBuyIncrement', lineItem.variant?.attributesRaw ?? []);

  const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', lineItem.variant?.attributesRaw ?? []);

  let estimatedWeight;
  if (sellType && sellType.key === 'byWeight') {
    estimatedWeight = quantity > 0 ? unitBuyIncrement * --quantity + unitBuyMinimum : 0;
  } else if (sellType && sellType.key === 'weightByEach') {
    estimatedWeight = (unitAverageBuyWeight ?? 1) * quantity;
  }

  return estimatedWeight;
};

export const getLineItemsToEvaluateCart = (lineItems: LineItem[]): Prod[] => {
  const products: Prod[] = [];

  lineItems.length > 0 &&
    lineItems
      .filter((x) => !x?.custom?.customFieldsRaw?.find((y) => y.name === 'parentLineItemId'))
      .forEach((li) => {
        const q = li.quantity;

        const lineItemVariant = li.variant;
        const sku = lineItemVariant?.sku ?? '';
        const priceCustomValues = lineItemVariant?.price?.custom?.customFieldsRaw ?? [];

        const p = lineItemVariant?.price?.value ?? { type: 'centPrecision', currencyCode: 'USD', centAmount: 0, fractionDigits: 2 };
        const rp = priceCustomValues.length > 0 ? priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value : p;
        const ew = (li.custom?.customFieldsRaw ?? []).find((pcv) => pcv.name === 'estimatedTotalWeight')?.value ?? estimateLineItemWeight(li, q);

        products.push({
          LineItemId: li.id,
          ExtProductId: sku,
          Quantity: q,
          Price: +(Math.ceil(p.centAmount / 1) / (10 ** p.fractionDigits || 1)),
          RegularPrice: +(Math.ceil(rp.centAmount / 1) / (10 ** rp.fractionDigits || 1)),
          EstimatedTotalWeight: ew,
        });
      });

  return products;
};

export const buildUpdateActionLineItemCustomField = (lineItem: LineItem, name: string, value?: any) => {
  const actions: MyCartUpdateAction[] = [];
  if (lineItem.custom) {
    if (value || typeof value === 'boolean') {
      // set it
      actions.push({ setLineItemCustomField: { lineItemId: lineItem.id, name: name, value: JSON.stringify(value) } });
    } else {
      // delete it if it exists already
      if (lineItem.custom.customFieldsRaw?.find((f) => f.name === name)) {
        actions.push({ setLineItemCustomField: { lineItemId: lineItem.id, name: name, value: null } });
      }
    }
  } else {
    actions.push({
      setLineItemCustomType: {
        type: { key: 'raleys-line-item-custom-fields' },
        lineItemId: lineItem.id,
        fields: value || typeof value === 'boolean' ? [{ name: name, value: JSON.stringify(value) }] : [],
      },
    });
  }
  return actions;
};

export const buildUpdateActionCustomField = (aCart: Cart, name: string, value?: any) => {
  const actions: MyCartUpdateAction[] = [];
  if (aCart.custom) {
    if (value || typeof value === 'boolean') {
      // set it
      actions.push({ setCustomField: { name: name, value: JSON.stringify(value) } });
    } else {
      // delete it if it exists already
      if (aCart.custom.customFieldsRaw?.find((f) => f.name === name)) {
        actions.push({ setCustomField: { name: name, value: null } });
      }
    }
  } else {
    actions.push({
      setCustomType: {
        type: { key: 'raleys-cart-order-custom-fields' },
        fields: value || typeof value === 'boolean' ? [{ name: name, value: JSON.stringify(value) }] : [],
      },
    });
  }
  return actions;
};

