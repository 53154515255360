export const AddressFragment = `
fragment DefaultAddress on Address {
  id
  title
  firstName
  lastName
  streetName
  streetNumber
  postalCode
  city
  country
  state
  region
  company
  apartment
  email
  phone
  mobile
  custom {
    customFieldsRaw {
      name
      value
    }
  }
}
`;
