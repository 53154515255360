import { gql } from 'graphql-request';
import { ChannelFragment } from '../../fragments/channel';

const channelByKeyQuery = gql`
  ${ChannelFragment}

  query ($channelKey: String!, $locale: Locale) {
    channel(key: $channelKey) {
      ...DefaultChannel
    }
  }
`;

export { channelByKeyQuery };
