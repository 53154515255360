import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import { addLogger } from '../../utils';
import { AvailablityMessage, AvailablityResponse, SettingsMessage, SwitchServerMessage } from './types';

const __DEV__ = (() => !process.env.NODE_ENV || process.env.NODE_ENV === 'development')();

export class LiveAgentService {
  private apiClient: ApisauceInstance;
  private chatDeploymentId: string;
  private chatOrgId: string;
  private availabilityId: string;

  constructor(config: { salesforceChatApiUrl: string; chatDeploymentId: string; chatOrgId: string; availabilityId: string; apiVersion: number }) {
    this.apiClient = create({ baseURL: config.salesforceChatApiUrl });
    this.apiClient.addAsyncRequestTransform(async (request: AxiosRequestConfig) => {
      request.headers = { ...(request.headers ?? {}), 'X-LIVEAGENT-API-VERSION': config.apiVersion };
    });
    this.apiClient.addAsyncResponseTransform(async (response: ApiResponse<any>) => {
      if (response.data) {
        const switchServer: SwitchServerMessage | undefined = response.data?.messages.find((x: any) => x.type === 'SwitchServer')?.message;
        if (switchServer) {
          const updatedResponse = await this.apiClient.any<any>({ ...response.config, baseURL: switchServer.newUrl });
          this.apiClient.setBaseURL(switchServer.newUrl);
          response.data = updatedResponse.data;
        }
      }
    });
    this.chatDeploymentId = config.chatDeploymentId;
    this.chatOrgId = config.chatOrgId;
    this.availabilityId = config.availabilityId;
    if (__DEV__) {
      const axiosLogger = require('debug')('api:liveagent');
      addLogger(this.apiClient.axiosInstance, axiosLogger); // only when debugging to access _ members directly to avoid top level await compile errors for the target
    }
  }

  getSessionId = async (): Promise<string | undefined> => {
    const response = await this.apiClient.get<{ key: string; id: string; clientPollTimeout: number }>(
      '/rest/System/SessionId',
      {},
      { headers: { 'X-LIVEAGENT-AFFINITY': null } },
    );

    if (response.ok) {
      return response.data?.id;
    }
    return undefined;
  };

  getAgentAvailablity = async (): Promise<boolean> => {
    const params: Record<string, string | number | undefined> = {
      'deployment_id': this.chatDeploymentId ?? '',
      'org_id': this.chatOrgId ?? '',
      'Availability.ids': `[${this.availabilityId ?? ''}]`,
      'Availability.needEstimatedWaitTime': 1,
    };

    const response = await this.apiClient.get<AvailablityResponse>('/rest/Visitor/Availability?', params);
    if (response.ok) {
      const availability = response.data?.messages.find((x) => x.type === 'Availability');
      return (availability?.message as AvailablityMessage).results?.some((m) => m.isAvailable) ?? false;
    }
    return false;
  };

  getSettings = async (): Promise<SettingsMessage | undefined> => {
    const params: Record<string, string | number | undefined> = {
      'deployment_id': this.chatDeploymentId ?? '',
      'org_id': this.chatOrgId ?? '',
      'Settings.buttonIds': `[${this.availabilityId ?? ''}]`,
      'Availability.needEstimatedWaitTime': 1,
    };

    const response = await this.apiClient.get<AvailablityResponse>('/rest/Visitor/Settings', params);
    if (response.ok) {
      return response.data?.messages.find((x) => x.type === 'Settings')?.message as SettingsMessage;
    }
    return undefined;
  };
}
