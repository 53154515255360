import { OrderQueryInterface as Me, Order } from '../../../schema/schema';
import { OrderSearch, PagedArray } from '../../../schema/types';
import { buildOrderWhere } from '../../../utils/search';
import { ServiceBase } from '../../serviceBase';
import { createOrderMutation } from './mutation';
import { orderByIdQuery, orderByOrderNumberQuery, ordersQuery } from './query';

export class OrderService extends ServiceBase {
  getOrders = async (
    where?: OrderSearch,
    limit: number = 20,
    offset: number = 0,
    locale: string = 'en-US',
    sort: string[] = ['createdAt desc'],
  ): Promise<PagedArray<Order>> => {
    const w = buildOrderWhere(where || {});
    const response = await this.executeRequest<'me', Me>(ordersQuery, {
      ...(w ? { where: w } : {}),
      limit: limit,
      offset: offset,
      sort: sort,
      locale: locale,
    });
    return { data: response.me.orders.results, offset, limit, total: response.me.orders.total };
  };

  getOrderById = async (id: string, locale: string = 'en-US', country: string = 'US', currency: string = 'USD'): Promise<Order | undefined> => {
    const response = await this.executeRequest<'me', Me>(orderByIdQuery, {
      id: id,
      locale: locale,
      currency: currency,
      country: country,
    });
    return response.me.order ?? undefined;
  };

  getOrderByOrderNumber = async (
    orderNumber: string,
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<Order | undefined> => {
    const response = await this.executeRequest<'me', Me>(orderByOrderNumberQuery, {
      orderNumber: orderNumber,
      locale: locale,
      currency: currency,
      country: country,
    });
    return response.me.order ?? undefined;
  };

  createOrderFromCart = async (id: string, version: number, locale: string = 'en-US', country: string = 'US', currency: string = 'USD'): Promise<Order> => {
    const response = await this.executeRequest<'order', Order>(createOrderMutation, {
      draft: {
        id: id,
        version,
      },
      locale,
      country,
      currency,
    });
    return response.order;
  };
}
