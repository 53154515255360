const DefaultPageSize = 30;
const Months: Readonly<string[]> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const Days: Readonly<string[]> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const States: Readonly<{ id: number; name: string; abbreviation: string }[]> = [
  { id: 1, name: 'Alabama', abbreviation: 'AL' },
  { id: 2, name: 'Alaska', abbreviation: 'AK' },
  { id: 4, name: 'Arizona', abbreviation: 'AZ' },
  { id: 5, name: 'Arkansas', abbreviation: 'AR' },
  { id: 6, name: 'California', abbreviation: 'CA' },
  { id: 7, name: 'Colorado', abbreviation: 'CO' },
  { id: 8, name: 'Connecticut', abbreviation: 'CT' },
  { id: 9, name: 'Delaware', abbreviation: 'DE' },
  { id: 10, name: 'District of Columbia', abbreviation: 'DC' },
  { id: 12, name: 'Florida', abbreviation: 'FL' },
  { id: 13, name: 'Georgia', abbreviation: 'GA' },
  { id: 15, name: 'Hawaii', abbreviation: 'HI' },
  { id: 16, name: 'Idaho', abbreviation: 'ID' },
  { id: 17, name: 'Illinois', abbreviation: 'IL' },
  { id: 18, name: 'Indiana', abbreviation: 'IN' },
  { id: 19, name: 'Iowa', abbreviation: 'IA' },
  { id: 20, name: 'Kansas', abbreviation: 'KS' },
  { id: 21, name: 'Kentucky', abbreviation: 'KY' },
  { id: 22, name: 'Louisiana', abbreviation: 'LA' },
  { id: 23, name: 'Maine', abbreviation: 'ME' },
  { id: 25, name: 'Maryland', abbreviation: 'MD' },
  { id: 26, name: 'Massachusetts', abbreviation: 'MA' },
  { id: 27, name: 'Michigan', abbreviation: 'MI' },
  { id: 28, name: 'Minnesota', abbreviation: 'MN' },
  { id: 29, name: 'Mississippi', abbreviation: 'MS' },
  { id: 30, name: 'Missouri', abbreviation: 'MO' },
  { id: 31, name: 'Montana', abbreviation: 'MT' },
  { id: 32, name: 'Nebraska', abbreviation: 'NE' },
  { id: 33, name: 'Nevada', abbreviation: 'NV' },
  { id: 34, name: 'New Hampshire', abbreviation: 'NH' },
  { id: 35, name: 'New Jersey', abbreviation: 'NJ' },
  { id: 36, name: 'New Mexico', abbreviation: 'NM' },
  { id: 37, name: 'New York', abbreviation: 'NY' },
  { id: 38, name: 'North Carolina', abbreviation: 'NC' },
  { id: 39, name: 'North Dakota', abbreviation: 'ND' },
  { id: 41, name: 'Ohio', abbreviation: 'OH' },
  { id: 42, name: 'Oklahoma', abbreviation: 'OK' },
  { id: 43, name: 'Oregon', abbreviation: 'OR' },
  { id: 45, name: 'Pennsylvania', abbreviation: 'PA' },
  { id: 47, name: 'Rhode Island', abbreviation: 'RI' },
  { id: 48, name: 'South Carolina', abbreviation: 'SC' },
  { id: 49, name: 'South Dakota', abbreviation: 'SD' },
  { id: 50, name: 'Tennessee', abbreviation: 'TN' },
  { id: 51, name: 'Texas', abbreviation: 'TX' },
  { id: 52, name: 'Utah', abbreviation: 'UT' },
  { id: 53, name: 'Vermont', abbreviation: 'VT' },
  { id: 55, name: 'Virginia', abbreviation: 'VA' },
  { id: 56, name: 'Washington', abbreviation: 'WA' },
  { id: 57, name: 'West Virginia', abbreviation: 'WV' },
  { id: 58, name: 'Wisconsin', abbreviation: 'WI' },
  { id: 59, name: 'Wyoming', abbreviation: 'WY' },
];
const ProductFLags: Readonly<string[]> = ['5DAY', '5DM', 'BMSM%', 'BMSM', 'SPECSAV'];
const ProductBadges: Readonly<string[]>  = ['somethingextra', 'weeklyexclusive', 'mfg'];
const CustomerServicePhone = '(800) 925-9989';
const CustomerServiceEmail = 'service@raleys.com';
const PreAuthMultiplier = 1.15;
const RootCategoryKey = 'PMC1';
const DefaultCacheTimeoutMunutes = 30;
const PickupShippingId = 7;
const DeliveryShippingId = 8;
const OnlineOOrderTypeId = 1;
const InstoreOrderTypeId = 7;
const DefaultFulfillmentLeadTimeMimutes = 120;
const DefaultCustomizationFulfillmentLeadTimeMimutes = 1440;
const MaxLeadTimeToShowHours = 48;
const MaxLineItemQuantity = 20;
const ColorPicker: Readonly<{ name: string; color: string }[]> = [
  {
    name: 'White',
    color: '#FFFFFF',
  },
  {
    name: 'Black',
    color: '#000000',
  },
  {
    name: 'Orange',
    color: '#FA6400',
  },
  {
    name: 'Green',
    color: '#5EB700',
  },
  {
    name: 'Yellow',
    color: '#FFC72C',
  },
  {
    name: 'Red',
    color: '#E02020',
  },
  {
    name: 'Blue',
    color: '#2580EC',
  },
  {
    name: 'Purple',
    color: '#6236FF',
  },
  {
    name: 'Pink',
    color: '#FB95FF',
  },
  {
    name: 'Baby Blue',
    color: '#77D9FF',
  },
  {
    name: 'Lavender',
    color: '#C6B5FF',
  },
  {
    name: 'Light Yellow',
    color: '#FDFD5E',
  },
  {
    name: 'Light Green',
    color: '#AEFF58',
  },
];
const NutritionFacts: Readonly<Record<string, { displayName: string; isBold: boolean; isIndent: boolean; sortOrder: number; height: number }>> = {
  'ENER-': { displayName: 'Calories', isBold: true, isIndent: false, sortOrder: 1, height: 2 },
  'ENERPF': { displayName: 'Calories from Fat', isBold: false, isIndent: true, sortOrder: 2, height: 2 },
  'FATNLEA': { displayName: 'Total Fat', isBold: true, isIndent: false, sortOrder: 3, height: 0.3 },
  'FAT': { displayName: 'Total Fat', isBold: true, isIndent: false, sortOrder: 4, height: 0.3 },
  'FATRN': { displayName: 'Transfatty Acids', isBold: false, isIndent: true, sortOrder: 5, height: 0.3 },
  'FASAT': { displayName: 'Saturated Fat', isBold: false, isIndent: true, sortOrder: 6, height: 0.3 },
  'FAPU': { displayName: 'Polyunsaturated Fat', isBold: false, isIndent: true, sortOrder: 7, height: 0.3 },
  'FAMS': { displayName: 'Monounsaturated Fat ', isBold: false, isIndent: true, sortOrder: 8, height: 0.3 },
  'FATRNF': { displayName: 'Transfatty Acids', isBold: false, isIndent: true, sortOrder: 9, height: 0.3 },
  'CHOL-': { displayName: 'Cholesterol', isBold: true, isIndent: false, sortOrder: 10, height: 0.3 },
  'NA': { displayName: 'Sodium', isBold: true, isIndent: false, sortOrder: 11, height: 0.3 },
  'K': { displayName: 'Potassium', isBold: true, isIndent: false, sortOrder: 12, height: 0.3 },
  'CHO-': { displayName: 'Carbohydrate Total', isBold: true, isIndent: false, sortOrder: 13, height: 0.3 },
  'FIBTSW': { displayName: 'Dietary Fiber', isBold: false, isIndent: true, sortOrder: 14, height: 0.3 },
  'FIBSOL': { displayName: 'Soluble Fiber', isBold: false, isIndent: true, sortOrder: 15, height: 0.3 },
  'FININS': { displayName: 'Insoluble Fiber', isBold: false, isIndent: true, sortOrder: 16, height: 0.3 },
  'SUGAR-': { displayName: 'Sugars', isBold: false, isIndent: true, sortOrder: 17, height: 0.3 },
  'SUGAD': { displayName: 'Added Sugar', isBold: false, isIndent: true, sortOrder: 18, height: 0.3 },
  'POLYL': { displayName: 'Sugar Alcohol ', isBold: false, isIndent: true, sortOrder: 19, height: 0.3 },
  'PRO-': { displayName: 'Protein', isBold: true, isIndent: false, sortOrder: 20, height: 2 },
  'VITA-': { displayName: 'Vitamin A', isBold: false, isIndent: false, sortOrder: 21, height: 0.3 },
  'VITC-': { displayName: 'Vitamin C', isBold: false, isIndent: false, sortOrder: 22, height: 0.3 },
  'CA': { displayName: 'Calcium', isBold: false, isIndent: false, sortOrder: 23, height: 0.3 },
  'FE': { displayName: 'Iron', isBold: false, isIndent: false, sortOrder: 24, height: 0.3 },
  'CARTB': { displayName: '% of Vitamin A as beta carotene', isBold: false, isIndent: true, sortOrder: 25, height: 0.3 },
  'VITD-': { displayName: 'Vitamin D', isBold: false, isIndent: false, sortOrder: 26, height: 0.3 },
  'VITE-': { displayName: 'Vitamin E', isBold: false, isIndent: false, sortOrder: 27, height: 0.3 },
  'THIA': { displayName: 'Thiamin', isBold: false, isIndent: false, sortOrder: 28, height: 0.3 },
  'RIBF': { displayName: 'Riboflavin', isBold: false, isIndent: false, sortOrder: 29, height: 0.3 },
  'NIA': { displayName: 'Niacin', isBold: false, isIndent: false, sortOrder: 30, height: 0.3 },
  'FOL-': { displayName: 'Folate', isBold: false, isIndent: false, sortOrder: 31, height: 0.3 },
  'VITB12': { displayName: 'Vitamin B12', isBold: false, isIndent: false, sortOrder: 32, height: 0.3 },
  'BIOT': { displayName: 'Biotin', isBold: false, isIndent: false, sortOrder: 33, height: 0.3 },
  'PANTAC': { displayName: 'Pantothenic Acid ', isBold: false, isIndent: false, sortOrder: 34, height: 0.3 },
  'P': { displayName: '', isBold: false, isIndent: false, sortOrder: 35, height: 0.3 },
  'ID': { displayName: 'Iodine', isBold: false, isIndent: false, sortOrder: 36, height: 0.3 },
  'MG': { displayName: 'Magnesium', isBold: false, isIndent: false, sortOrder: 37, height: 0.3 },
  'ZN': { displayName: 'Zinc', isBold: false, isIndent: false, sortOrder: 38, height: 0.3 },
  'CU': { displayName: 'Copper', isBold: false, isIndent: false, sortOrder: 39, height: 0.3 },
  'SE': { displayName: 'Selenium', isBold: false, isIndent: false, sortOrder: 40, height: 0.3 },
  'VITB6-': { displayName: 'Vitamin B6', isBold: false, isIndent: false, sortOrder: 41, height: 0.3 },
  'CAFFN': { displayName: 'Caffeine', isBold: false, isIndent: false, sortOrder: 42, height: 0.3 },
  'CLD': { displayName: 'Chloride', isBold: false, isIndent: false, sortOrder: 43, height: 0.3 },
  'FIBINS': { displayName: 'Fibre, Water-Insoluble', isBold: false, isIndent: false, sortOrder: 44, height: 0.3 },
  'MN': { displayName: 'Manganese', isBold: false, isIndent: false, sortOrder: 45, height: 0.3 },
  'MO': { displayName: 'Molybdenum', isBold: false, isIndent: false, sortOrder: 46, height: 0.3 },
  'FAPUN6': { displayName: 'Fat, Polyunsaturated-Omega 6 Acids', isBold: false, isIndent: false, sortOrder: 47, height: 0.3 },
  'CHOAVL': { displayName: 'Carbohydrate', isBold: true, isIndent: false, sortOrder: 48, height: 0.3 },
  'VITK': { displayName: 'Vitamin K', isBold: false, isIndent: false, sortOrder: 49, height: 0.3 },
  'ASH': { displayName: 'Ash', isBold: false, isIndent: false, sortOrder: 50, height: 0.3 },
  'FAPUN3': { displayName: 'Fat, Polyunsaturated-Omega 3 Acids', isBold: false, isIndent: false, sortOrder: 51, height: 0.3 },
  'CR': { displayName: 'Chromium', isBold: false, isIndent: false, sortOrder: 52, height: 0.3 },
  'FOL': { displayName: 'Folate', isBold: false, isIndent: false, sortOrder: 53, height: 0.3 },
  'FOLAC': { displayName: 'Synthetic Folic Acid', isBold: false, isIndent: false, sortOrder: 54, height: 0.3 },
};

export type MetricUnitType = 'GRM' | 'MGM' | 'MC' | 'E14' | 'ml' | 'MLT' | 'OZA' | 'G21' | 'LK' | 'floz' | 'ONZ' | 'G24' | 'EA' | 'H87';

const MetricUnit: Readonly<Record<MetricUnitType, string>> = {
  GRM: 'g',
  MGM: 'mg',
  MC: 'mcg',
  E14: 'Kcal',
  ml: 'ml',
  MLT: 'mlt',
  OZA: 'Fluid ounce',
  G21: 'Cup',
  LK: 'Link',
  floz: 'Fluid ounce',
  ONZ: 'Ounce',
  G24: 'Tablespoon',
  EA: 'Each',
  H87: 'Piece',
};

const MeasurePrecisionCode: Readonly<Record<string, string>> = {
  LESS_THAN: '<',
  GREATER_THAN: '>',
};

export type TimeZoneId =
  | 'Pacific Standard Time'
  | 'Mountain Standard Time'
  | 'Central Standard Time'
  | 'Eastern Standard Time'
  | 'Alaska Standard Time'
  | 'Hawaii Standard Time';

const TimeZone: Readonly<Record<TimeZoneId, string>> = {
  'Pacific Standard Time': 'America/Los_Angeles',
  'Mountain Standard Time': 'America/Denver',
  'Central Standard Time': 'America/Chicago',
  'Eastern Standard Time': 'America/New_York',
  'Alaska Standard Time': 'America/Anchorage',
  'Hawaii Standard Time': 'America/Honolulu',
};

export type ShoppingListType = 'FAVORITES' | 'MSL' | 'MFL' | 'SFL';

const ShoppingList: Record<ShoppingListType, string> = {
  FAVORITES: 'Favorites',
  MSL: 'My Shopping List',
  MFL: 'My Favorites',
  SFL: 'Saved For Later',
};

export default {
  Months,
  Days,
  States,
  DefaultPageSize,
  NutritionFacts,
  MetricUnit,
  MeasurePrecisionCode,
  TimeZone,
  ShoppingList,
  ProductFLags,
  ProductBadges,
  CustomerServicePhone,
  CustomerServiceEmail,
  ColorPicker,
  PreAuthMultiplier,
  RootCategoryKey,
  DefaultCacheTimeoutMunutes,
  PickupShippingId,
  DeliveryShippingId,
  OnlineOOrderTypeId,
  InstoreOrderTypeId,
  DefaultFulfillmentLeadTimeMimutes,
  DefaultCustomizationFulfillmentLeadTimeMimutes,
  MaxLeadTimeToShowHours,
  MaxLineItemQuantity,
};
