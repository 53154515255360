import { Lookup } from 'src/types';
import { ServiceBase } from './serviceBase';
import { AppConfig, Lookup as LookupDto } from './types';

const ProductAttribute: Record<'Shelf Guide', number> = {
  'Shelf Guide': 6,
};

export class EnterpriseService extends ServiceBase {
  private getProductAttributeLookup = async (productAttribute: keyof typeof ProductAttribute): Promise<LookupDto[] | undefined> => {
    const response = await this.executeRequest<'ProductAttributeLookups', LookupDto[]>(
      '/product/productattributelookups/get',
      JSON.stringify({ ProductAttributeId: ProductAttribute[productAttribute] }),
    );
    return response.ProductAttributeLookups;
  };

  getAppConfig = async (): Promise<AppConfig[]> => {
    const response = await this.executeRequest<'AppConfiguration', Record<string, string>>('../appconfigsettings/get');
    return Object.entries(response.AppConfiguration).map(([key, value], idx) => ({
      ConfigId: idx + 1,
      ConfigName: key,
      ConfigValue: value,
      GroupName: 'app',
      IsHidden: true,
    }));
  };

  getWebConfig = async (): Promise<AppConfig[]> => {
    const response = await this.executeRequest<'WebConfiguration', Record<string, string>>('../webconfigsettings/get');
    return Object.entries(response.WebConfiguration).map(([key, value], idx) => ({
      ConfigId: idx + 1,
      ConfigName: key,
      ConfigValue: value,
      GroupName: 'web',
      IsHidden: true,
    }));
  };

  getShelfGuideSortOrder = async (): Promise<Record<string, number> | undefined> => {
    const response = await this.getProductAttributeLookup('Shelf Guide');
    const result = response?.reduce(
      (list, x) => {
        if (x.Value) {
          list[x.Value] = Number(x.CustomProperties?.SortOrder ?? 900);
        }
        return list;
      },
      {} as Record<string, number>,
    );
    return result;
  };

  getStoreDepartments = async (): Promise<Lookup[]> => {
    const response = await this.executeRequest<'Departments', LookupDto[]>('/storedepartments/lookup/get');
    return response.Departments.map((x) => ({ id: x.Id, value: x.Value, isActive: x.IsActive, customProperties: { ...x.CustomProperties } }) as Lookup);
  };
}
