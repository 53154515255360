import { constants } from '../../constants';
import { Address } from '../../types';
import { ServiceBase } from './serviceBase';
import { AddressValidationResponse, Address as bwAddress } from './types/address';

export class ValidationService extends ServiceBase {
  //validate address
  validateAddress = async (address: Address): Promise<AddressValidationResponse[]> => {
    const region = constants.States.find((x) => x.abbreviation === address.state);

    const response = await this.executeRawRequest<AddressValidationResponse[]>('/validateaddress', {
      Address1: address.address1,
      Address2: address.address2,
      City: address.city,
      PostalCode: address.postalCode,
      Region: {
        Id: region?.id,
        Value: region?.name,
      },
    } as bwAddress);
    return response ?? [];
  };

  validateResetPasswordToken = async (email: string, resetToken: string): Promise<boolean> => {
    const response = await this.executeRequest<'IsInvalidToken', boolean>('/customer/reset-password-token/validate', {
      Email: email,
      PasswordResetToken: resetToken,
    });
    return !response.IsInvalidToken;
  };
}
