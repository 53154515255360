import { gql } from 'graphql-request';
import { OrderFragment } from '../../../fragments/order';

const ordersQuery = gql`
  query getMe($where: String, $sort: [String!], $limit: Int, $offset: Int, $locale: Locale!) {
    me {
      orders(where: $where, sort: $sort, limit: $limit, offset: $offset) {
        total
        results {
          shippingInfo {
            shippingMethodName
          }
          taxedPrice {
            totalGross {
              currencyCode
              centAmount
              fractionDigits
            }
          }
          custom {
            customFieldsRaw {
              name
              value
            }
          }
          orderState
          shipmentState
          orderNumber
          id
          createdAt
          store {
            id
            key
            name(locale: $locale)
          }
        }
      }
    }
  }
`;

const orderByIdQuery = gql`
  ${OrderFragment}
  query getMe($id: String, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    me {
      order(id: $id) {
        ...DefaultOrder
        store {
          id
          key
        }
      }
    }
  }
`;

const orderByOrderNumberQuery = gql`
  ${OrderFragment}
  query getMe($orderNumber: String, $locale: Locale!, $country: Country!, $currency: Currency!, $channelId: String) {
    me {
      order(orderNumber: $orderNumber) {
        ...DefaultOrder
        store {
          id
          key
        }
      }
    }
  }
`;

const orderInfoByOrderNumberQuery = gql`
  query getMe($orderNumber: String) {
    me {
      order(orderNumber: $orderNumber) {
        id,
        orderNumber
        version
        createdAt
        orderState
        shippingInfo {
          shippingMethodName
        }
        custom {
          customFieldsRaw (includeNames: ["OriginalOrderNumber", "OriginalStore", "customerPaymentMethodAuthCode", "timeSlot", "fulfillmentStore","customerPaymentMethodId"]){
            name
            value
          }
        }
      }
    }
  }
`;


export { orderByIdQuery, orderByOrderNumberQuery, orderInfoByOrderNumberQuery, ordersQuery };

