import { CancelToken } from 'axios';
import { PagedArray } from '../../types';
import { ServiceBaseWithAuth } from './serviceBaseWithAuth';
import { PagedResponse, Response } from './types';
import { Promotion, Voucher } from './types/offer';

export class OfferService extends ServiceBaseWithAuth {
  //Vouchers
  getVouchers = async (
    source: 'app' | 'web',
    type: 'available' | 'accepted' | 'redeemed',
    offset: number = 0,
    rows: number = 25,
    cts?: CancelToken,
  ): Promise<PagedArray<Voucher>> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'AcceptedVouchers' | 'AvailableVouchers' | 'RedeemedVouchers', PagedResponse<Voucher>>(
      `/promotion/vouchers/${type}/get`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        PageNumber: offset >= rows ? Math.ceil(offset / rows) + 1 : 1,
        PageSize: rows,
      }),
      cts,
    );
    // return type === 'accepted' ? response.AcceptedVouchers : response.AvailableVouchers;
    switch (type) {
      case 'accepted':
        // return response.AcceptedVouchers;
        return {
          data: response.AcceptedVouchers && response.AcceptedVouchers.Data ? response.AcceptedVouchers.Data : [],
          offset: offset,
          limit: rows,
          total: response.AcceptedVouchers.TotalCount,
        };
      case 'redeemed':
        // return response.RedeemedVouchers;
        return {
          data: response.RedeemedVouchers && response.RedeemedVouchers.Data ? response.RedeemedVouchers.Data : [],
          offset: offset,
          limit: rows,
          total: response.RedeemedVouchers.TotalCount,
        };
      default:
        // return response.AvailableVouchers;
        return {
          data: response.AvailableVouchers && response.AvailableVouchers.Data ? response.AvailableVouchers.Data : [],
          offset: offset,
          limit: rows,
          total: response.AvailableVouchers.TotalCount,
        };
    }
  };

  getRedeemedVouchers = async (source: 'app' | 'web', offset: number = 0, rows: number = 25, cts?: CancelToken): Promise<PagedArray<Voucher>> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'RedeemedVouchers', PagedResponse<Voucher>>(
      `/promotion/vouchers/redeemed/get`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        PageNumber: offset >= rows ? Math.ceil(offset / rows) + 1 : 1,
        PageSize: rows,
      }),
      cts,
    );
    // return response.RedeemedVouchers;
    return {
      data: response.RedeemedVouchers && response.RedeemedVouchers.Data ? response.RedeemedVouchers.Data : [],
      offset: offset,
      limit: rows,
      total: response.RedeemedVouchers.TotalCount,
    };
  };

  getOfferById = async (extPromotionId: number): Promise<Promotion> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Promotion', Promotion>(
      `/offer/get`,
      JSON.stringify({
        UserToken: userToken,
        OfferId: extPromotionId,
      }),
    );

    return response.Promotion;
  };

  getCustomerPromotionById = async (extPromotionId: number): Promise<Promotion> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Promotion', Promotion>(
      '/promotion/customer/get',
      JSON.stringify({
        UserToken: userToken,
        ExtPromotionId: extPromotionId,
      }),
    );

    return response.Promotion;
  };

  getNontargetedOfferById = async (extPromotionId: number): Promise<Promotion> => {
    const response = await this.executeRequest<'Promotion', Promotion>(
      `/promotion/nontargeted/${extPromotionId}`
    );

    return response.Promotion;
  }

  getTargeted = async (
    source: 'app' | 'web',
    type: 'available' | 'accepted',
    storeNumber: string,
    offset: number = 0,
    rows: number = 25,
    filter?: string,
    cts?: CancelToken,
  ): Promise<PagedArray<Promotion>> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'AcceptedPromotions' | 'AvailablePromotions', PagedResponse<Promotion>>(
      `/promotion/targeted/${type}/get`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        StoreNumber: storeNumber,
        PageNumber: offset >= rows ? Math.ceil(offset / rows) + 1 : 1,
        PageSize: rows,
        Filter: filter,
      }),
      cts,
    );

    return type === 'accepted'
      ? {
        data:
          response.AcceptedPromotions && response.AcceptedPromotions.Data
            ? response.AcceptedPromotions.Data.map((promotion: Promotion) => ({
              ...promotion,
              IsAccepted: true,
            }))
            : [],
        offset: offset,
        limit: rows,
        total: response.AcceptedPromotions.TotalCount,
      }
      : {
        data:
          response.AvailablePromotions && response.AvailablePromotions.Data
            ? response.AvailablePromotions.Data.map((promotion: Promotion) => ({
              ...promotion,
              IsAccepted: false,
            }))
            : [],
        offset: offset,
        limit: rows,
        total: response.AvailablePromotions.TotalCount,
      };
  };

  getNonTargeted = async (
    source: 'app' | 'web',
    storeNumber: string,
    offset: number = 0,
    rows: number = 25,
    filter?: string,
    cts?: CancelToken,
  ): Promise<PagedArray<Promotion>> => {
    const response = await this.executeRequest<'AvailablePromotions', PagedResponse<Promotion>>(
      `/promotion/nontargeted/get`,
      JSON.stringify({
        Source: source,
        StoreNumber: storeNumber,
        PageNumber: offset >= rows ? Math.ceil(offset / rows) + 1 : 1,
        PageSize: rows,
        Filter: filter,
      }),
      cts,
    );
    return { data: response.AvailablePromotions.Data, offset: offset, limit: rows, total: response.AvailablePromotions.TotalCount };
  };

  acceptOffer = async (extPronotionId: number): Promise<boolean> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'OfferId', number>('/customer/offer/accept', {
      UserToken: userToken,
      OfferId: extPronotionId,
    });
    return response.Response?.ReturnCode === 0;
  };

  acceptOffers = async (extPronotionIds: number[]): Promise<boolean> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'AcceptResponse', { OfferId: number }>('/customer/offers/accept', {
      UserToken: userToken,
      OfferIds: extPronotionIds,
    });
    return response.Response?.ReturnCode === 0;
  };

  unacceptOffer = async (extPronotionIds: number): Promise<boolean> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'OfferId', number>('/customer/offer/unaccept', {
      UserToken: userToken,
      OfferId: extPronotionIds,
    });
    return response.Response?.ReturnCode === 0;
  };

  getCoupons = async (
    source: 'app' | 'web',
    type: 'available' | 'accepted',
    storeNumber: string,
    offset: number = 0,
    rows: number = 25,
    filter?: string,
    cts?: CancelToken,
  ): Promise<PagedArray<Promotion>> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'AcceptedPromotions' | 'AvailablePromotions', PagedResponse<Promotion>>(
      `/promotion/coupons/${type}/get`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        StoreNumber: storeNumber,
        PageNumber: offset >= rows ? Math.ceil(offset / rows) + 1 : 1,
        PageSize: rows,
        Filter: filter,
      }),
      cts,
    );

    return type === 'accepted'
      ? {
        data:
          response.AcceptedPromotions && response.AcceptedPromotions.Data
            ? response.AcceptedPromotions.Data.map((promotion: Promotion) => ({
              ...promotion,
              IsAccepted: true,
            }))
            : [],
        offset: offset,
        limit: rows,
        total: response.AcceptedPromotions.TotalCount,
      }
      : {
        data:
          response.AvailablePromotions && response.AvailablePromotions.Data
            ? response.AvailablePromotions.Data.map((promotion: Promotion) => ({
              ...promotion,
              IsAccepted: false,
            }))
            : [],
        offset: offset,
        limit: rows,
        total: response.AvailablePromotions.TotalCount,
      };
  };

  acceptCoupon = async (extPronotionIds: number[]): Promise<boolean> => {
    const userToken = await this.authToken();

    const response = await this.executeRequest<'AcceptResponse', { OfferId: number }>('/promotion/coupon/accept', {
      UserToken: userToken,
      OfferIds: extPronotionIds,
    });
    return response.Response?.ReturnCode === 0;
  };

  acceptPromo = async (promo: string): Promise<Response | undefined> => {
    const userToken = await this.authToken();

    const response = await this.executeRawRequest<Response>('/customer/promocode/accept', {
      UserToken: userToken,
      PromoCode: promo,
    });
    return response;
  };

  unAcceptPromo = async (promoCode: string): Promise<Response | undefined> => {
    const userToken = await this.authToken();

    const response = await this.executeRawRequest<Response>('/customer/promocode/unaccept', {
      UserToken: userToken,
      PromoCode: promoCode,
    });
    return response;
  };

  getOffersByProductList = async (
    source: 'app' | 'web',
    storeNumber: string,
    productList: string[],
    filter?: string,
    cts?: CancelToken,
  ): Promise<{
    AvailablePromotions: Promotion[];
    AcceptedPromotions: Promotion[];
  }> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'AcceptedPromotions' | 'AvailablePromotions', Promotion[]>(
      `/promotion/byproductlist/get`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        StoreNumber: storeNumber,
        ProductList: productList,
        Filter: filter,
      }),
      cts,
    );
    return response;
  };

  getCouponsByProductList = async (
    source: 'app' | 'web',
    storeNumber: string,
    productList: string[],
    filter?: string,
    cts?: CancelToken,
  ): Promise<{
    AvailableCoupons: Promotion[];
    AcceptedCoupons: Promotion[];
  }> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'AvailableCoupons' | 'AcceptedCoupons', Promotion[]>(
      `/promotion/coupons/byproductlist/get`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        StoreNumber: storeNumber,
        ProductList: productList,
        Filter: filter,
      }),
      cts,
    );
    return response;
  };

  evaluateProduct = async (
    source: 'app' | 'web',
    storeNumber: string,
    shippingMethod: 'pickup' | 'delivery',
    product: { ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number },
    cartLineItems?: { ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number }[],
    cts?: CancelToken,
  ): Promise<Promotion[]> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Promotions', Promotion[]>(
      `/promotion/evaluate/product`,
      JSON.stringify({
        UserToken: userToken,
        Source: source,
        StoreNumber: storeNumber,
        ShippingMethod: shippingMethod,
        Cart: cartLineItems,
        Product: product,
      }),
      cts,
    );
    return response.Promotions;
  };

  evaluateCart = async (
    source: 'app' | 'web',
    storeNumber: string,
    shippingMethod: 'pickup' | 'delivery',
    cartLineItems: { LineItemId: string; ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number }[],
    cts?: CancelToken,
  ): Promise<Promotion[]> => {
    const userToken = await this.authToken();
    const response = await this.executeRequest<'Promotions', Promotion[]>(
      `/promotion/evaluate/cart`,
      JSON.stringify({
        UserToken: userToken,
        StoreNumber: storeNumber,
        ShippingMethod: shippingMethod,
        Source: source,
        Cart: cartLineItems,
      }),
      cts,
    );
    return response.Promotions;
  };
}
