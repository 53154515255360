import { MoneyFragment } from './money';

export const ProductPriceFragment = `
  ${MoneyFragment}

  fragment DefaultProductPrice on ProductPrice {
    validFrom
    validUntil    
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    discounted {
      value {
        ...MoneyFragment
      }
      discount {
        validFrom
        validUntil
        isActive
        name(locale: $locale)
      }
    }
    channel {
      id
      key
      name(locale: $locale)
      custom {
        customFieldsRaw {
          name
          value
        }
      }
    }
    value {
      ...MoneyFragment
    }
  }
`;
