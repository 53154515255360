export const CustomLineItemFragment = `
fragment DefaultCustomLineItem on CustomLineItem {
  name(locale: $locale)
  slug
  custom {
    customFieldsRaw {
      name
      value
    }
  }
  quantity
  money {
    currencyCode
    centAmount
    fractionDigits
  }
  totalPrice {
    currencyCode
    centAmount
    fractionDigits
  }
}
`;
