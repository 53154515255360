/* eslint-disable no-unused-vars */
export interface AuthToken {
  email: string;
  refreshToken: string;
  jwtToken: string;
  passwordExpired: boolean;
}

export interface UserProfile {
  amsCardId: number;
  defaultBillingAddressId?: number;
  defaultShippingAddressId?: number;
  defaultPaymentMethodId?: number;
  email: string;
  firstName: string;
  lastName: string;
  loyaltyId: string;
  phone: string;
  pointsBalance?: number;
  pointsAsOfDate?: Date;
  sepiNumber: number;
  uid: string;
  lastLoginDate: Date;
  createdDate?: Date;
  birthday: string;
  digitalLoyaltyAgreementDate?: string;
}

export interface Address {
  id?: string | number;
  firstName?: string;
  lastName?: string;
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string;
  email?: string;
  companyName?: string;
  addressType: 'home' | 'business';
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
  isValidated: boolean;
  validatedDate?: Date;
  latitude?: string;
  longitude?: string;
}

export interface UserSettings {
  localAuthentication?: boolean;
  showAppBenefits?: boolean;
  version?: string;
  buildNumber?: string;
  darkMode?: boolean;
  notificationsEnabled?: boolean;
  unreadMessageCount?: number;
  showTourGuide?: boolean;
  remindMeLater?: boolean;
  rememberMe?: boolean;
  lastCkecked?: Date;
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type SignUp = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  extLoyaltyId: string;
  sendEmails: boolean;
  sendTexts: boolean;
  autoLogin: boolean;
  acceptTerms: boolean;
};

export type BirthdayClubMember = {
  name: string;
  birthday: string;
};

export interface CustomerAlternateContact {
  birthMonth: string;
  birthDay: string;
  birthYear?: string;
  alternateContactId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  faxNumber: string;
  alternateContactTypeID: string;
  alternateContactTypeValue: string;
  alternateContactTypeIsActive: boolean;
}

export enum AlternateContactTypes {
  SelfCustomer = 1,
  Spouse = 2,
  Child = 3,
  Parent = 4,
  Grandparent = 5,
  FamilyMember = 6,
  DomesticPartner = 7,
  Friend = 8,
  Roomate = 9,
  Neighbor = 10,
  Coworker = 11,
  Assistant = 12,
  Employer = 13,
  Employee = 14,
  Caretaker = 15,
  Nanny = 16,
  DeliveryContact = 17,
  Other = 18,
}
