export const CategoryFragment = `
fragment DefaultCategory on Category {
  id
  key
  name(locale: $locale)
  orderHint
  ancestors{
      id
      key
      name(locale: $locale)
      orderHint
      parent{
          key
      }
  }
  parent {
      id
      key
      name(locale: $locale)
      orderHint
  }
}
`;
