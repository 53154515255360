import { Order as CTOrder, Cart } from '@fieldera-raleys/client-commercetools/schema';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Order, OrderStatusType, Store } from 'src/types';
dayjs.extend(utc);
dayjs.extend(timezone);

export type ReplicateCartData = {
  cart: Cart;
  order: CTOrder;
};

export const isOrderOkToModify = (order?: Order, store?: Store, defaultLead: number = 2 * 60, modifyTimer = 15, timezone: string = 'America/Los_Angeles') => {
  // if modifyTimer is 0, it is the client checking before placing order, otherwise modification is starting include time for modification
  // defaultLeadTime is passed in because it is a client config // custom is 24h hardcoded for now
  if (!order) return false;
  let canMod =
    +(order.orderStatus.id ?? '0') === OrderStatusType.Active && order.orderItems.every((oi) => +(oi.orderItemStatus.id ?? '0') === OrderStatusType.Active);
  const d = order.estimatedDeliveryDates.find((dt) => dt !== undefined);
  if (d && order.deliveryBeginTime) {
    let then = dayjs.tz(order.estimatedDeliveryDates[0], timezone);
    const now = dayjs();
    const leadTime = (order.orderItems ?? []).some((i) => (i.orderItemSubItems ?? []).length > 0) ? 24 * 60 : defaultLead;
    then = then.set('hours', +order.deliveryBeginTime.substring(0, 2));
    if (then.diff(now.add(Math.max(+(store?.fulfillmentLeadTimeMinutes ?? defaultLead), leadTime), 'minutes'), 'minutes') <= modifyTimer) {
      canMod = false;
    }
  }
  return canMod;
};

export const minuteKey = () => dayjs().format('YYYYMMDDHHmm');

export const getOrderStoreNumber = (order?: Order, defultValue: string = '01') => {
  if (order?.shippingMethod?.toUpperCase() !== 'DELIVERY' ?? true) {
    const found: RegExpMatchArray | null = (order?.deliverySite?.facility?.value ?? '')?.match(/^(\d)\w+/gi);
    if (found && found.length) {
      return '' + found[0];
    }
    return defultValue;
  } else {
    return order?.facility?.customProperties?.StoreNumber ?? defultValue;
  }
};

export const getDonationAmount = (obj?: Cart | CTOrder): string | undefined => {
  const money = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'donationAmount')?.value;
  if (!money) {
    return undefined;
  }

  return (money.centAmount / 10 ** money.fractionDigits).toFixed(2);
};
