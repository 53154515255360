import { Category, CategoryQueryResult } from '../../schema/schema';
import { ProductCategorySearch } from '../../schema/types';
import { buildCategoryWhere, buildCustomizeSubCategoryWhere } from '../../utils/search';
import { ServiceBase } from '../serviceBase';
import { categoryBykeyQuery, queryCategories, subcategoryByParentId } from './query';

export class CategoryService extends ServiceBase {
  getCategoryByKey = async ({ key, locale = 'en-US' }: { key: string; locale?: string }, signal?: AbortSignal): Promise<Category> => {
    const response = await this.executeRequest<'category', Category>(
      categoryBykeyQuery,
      {
        key,
        locale,
      },
      signal,
    );
    return response.category;
  };

  getCategories = async ({ search, locale = 'en-US' }: { search: ProductCategorySearch; locale?: string }, signal?: AbortSignal): Promise<Category[]> => {
    const response = await this.executeRequest<'categories', CategoryQueryResult>(
      queryCategories,
      {
        where: buildCategoryWhere(search),
        limit: search?.limit,
        offset: search?.offset,
        sort: search?.sort,
        locale,
      },
      signal,
    );
    return response.categories.results;
  };

  getSubcategoryByParentId = async ({ id, locale = 'en-US' }: { id: string; locale?: string }, signal?: AbortSignal): Promise<Category> => {
    const response = await this.executeRequest<'categories', Category>(
      subcategoryByParentId,
      {
        where: buildCustomizeSubCategoryWhere({ id: id }),
        locale,
      },
      signal,
    );
    return response.categories;
  };
}
