import { AuthToken, LoyaltyValidationResult, SignUp } from '../../types';
import logger from '../../utils/logger';
import { ServiceBase } from './serviceBase';
import { AccessToken, FielderaApiResponse, Response } from './types/common';

export class AuthService extends ServiceBase {
  login = async (email: string, password: string): Promise<AuthToken | undefined> => {
    const response = await this.apiClient.post<FielderaApiResponse<'LoginResponse', AccessToken>>('/customer/login', {
      EmailAddress: email,
      Password: password,
    });
    if (response.ok && response.data && !response.data.Response?.HasException) {
      return {
        email: email,
        jwtToken: response.data.LoginResponse.UserToken,
        refreshToken: response.data.LoginResponse.RefreshToken,
        passwordExpired: response.data.LoginResponse.IsPasswordExpired,
      };
    } else if (response.data?.Response?.HasException && response.data.Response.ReturnMessage.indexOf('invalid loginID or password') >= 0) {
      return undefined;
    } else {
      throw new Error(`${response.status ?? 500}: Internal Server Error: ${response.originalError}`);
    }
  };

  logout = async (): Promise<boolean> => {
    return new Promise(function (resolve) {
      setTimeout(() => {
        logger.log('called logout');
        resolve(true);
      }, 200);
    });
  };

  refreshAuthToken = async (email: string, userToken: string, refreshToken: string): Promise<AuthToken | undefined> => {
    const response = await this.apiClient.post<FielderaApiResponse<'LoginResponse', AccessToken>>('/customer/refresh-token', {
      UserToken: userToken,
      RefreshToken: refreshToken,
    });

    if (response.ok && response.data && !response.data.Response?.HasException) {
      return { email, jwtToken: response.data.LoginResponse.UserToken, refreshToken: response.data.LoginResponse.RefreshToken, passwordExpired: false };
    } else if (response.data?.Response?.HasException && response.data.Response.ReturnMessage.indexOf('invalid loginID or password') >= 0) {
      return undefined;
    } else {
      throw new Error(`${response.status ?? 500}: Internal Server Error: ${response.originalError}`);
    }
  };

  resetPassword = async (email: string, password: string, resetToken: string): Promise<boolean> => {
    const response = await this.apiClient.post<FielderaApiResponse<'IsInvalidPassword', boolean>>(
      '/customer/reset-password',
      JSON.stringify({
        Email: email,
        NewPassword: password,
        PasswordResetToken: resetToken,
      }),
    );
    return response.data?.IsInvalidPassword ?? true;
  };

  forgotEmail = async (extLoyaltyId: string, lastName: string, phoneNumber: string): Promise<string | undefined> => {
    const response = await this.apiClient.post<FielderaApiResponse<'EmailAddress', string>>('/customer/forgot-email/get', {
      ExtLoyaltyId: extLoyaltyId,
      LastName: lastName,
      PhoneNumber: phoneNumber,
    });

    if (response.ok) {
      if (response.data && !response.data.Response?.HasException) {
        return response.data.EmailAddress;
      } else {
        throw new Error(response.data?.Response?.ReturnMessage);
      }
    } else {
      throw new Error(response.problem);
    }
  };

  forgotPassword = async (email: string): Promise<boolean> => {
    const response = await this.apiClient.post<FielderaApiResponse<'IsInvalidEmail', boolean>>('/customer/forgot-password', JSON.stringify(email));
    if (response.ok) {
      if (response.data && !response.data.Response?.HasException) {
        return !!response.data.IsInvalidEmail;
      } else {
        throw new Error(response.data?.Response?.ReturnMessage);
      }
    } else {
      throw new Error(response.problem);
    }
  };

  updateEmail = async (
    extLoyaltyId: string,
    lastName: string,
    postalCode: string,
    currentEmail: string,
    newEmail: string,
    verifyNewEmail: string,
  ): Promise<boolean | undefined> => {
    const response = await this.apiClient.post<FielderaApiResponse<'IsInvalidEmail', boolean>>('/customer/forgot-email/update', {
      ExtLoyaltyId: extLoyaltyId,
      LastName: lastName,
      PostalCode: postalCode,
      EmailAddress: currentEmail,
      NewEmailAddress: newEmail,
      ConfirmNewEmailAddress: verifyNewEmail,
    });

    if (response.ok) {
      if (response.data && !response.data.Response?.HasException) {
        return response.data.IsInvalidEmail;
      } else {
        throw new Error(response.data?.Response?.ReturnMessage);
      }
    } else {
      throw new Error(response.problem);
    }
  };

  signUp = async (profile: SignUp): Promise<AuthToken | undefined> => {
    const response = await this.apiClient.post<{ Response?: Response; LoginResponse: AccessToken; CustomerId: string }>('/customer/add', {
      Customer: {
        FirstName: profile.firstName,
        LastName: profile.lastName,
        EmailAddress: profile.email,
        Password: profile.password,
        PhoneNumber: profile.phoneNumber,
        ExtLoyaltyId: profile.extLoyaltyId,
        SendEmails: profile.sendEmails,
        SendTexts: profile.sendTexts,
        AutoLogin: profile.autoLogin,
        AcceptTerms: profile.acceptTerms,
      },
    });
    if (response.ok && response.data && !response.data.Response?.HasException) {
      return {
        email: profile.email,
        jwtToken: response.data?.LoginResponse?.UserToken,
        refreshToken: response.data?.LoginResponse?.RefreshToken,
        passwordExpired: false,
      };
    } else if (response.data?.Response?.HasException) {
      throw new Error(response.data?.Response?.ReturnMessage);
    } else {
      throw new Error(`${response.status ?? 500}: Internal Server Error: ${response.originalError}`);
    }
  };

  checkEmailExists = async (emailAddress: string): Promise<boolean | undefined> => {
    const response = await this.apiClient.post<FielderaApiResponse<'EmailExists', boolean>>('/customer/email/exists', {
      EmailAddress: emailAddress,
    });

    if (response.ok) {
      if (response.data && !response.data.Response?.HasException) {
        return response.data.EmailExists;
      } else {
        throw new Error(response.data?.Response?.ReturnMessage);
      }
    } else {
      throw new Error(response.problem);
    }
  };

  validateLoyaltyInformation = async (lastName: string, extLoyaltyId?: string, extLoyaltyCardId?: string): Promise<LoyaltyValidationResult | undefined> => {
    let reqBody;
    if (extLoyaltyId) {
      reqBody = {
        LastName: lastName,
        ExtLoyaltyId: extLoyaltyId,
      };
    } else {
      reqBody = {
        LastName: lastName,
        ExtLoyaltyCardId: extLoyaltyCardId,
      };
    }

    const response = await this.apiClient.post<FielderaApiResponse<'ValidationStatus' | 'StatusDescription' | 'ExtLoyaltyId', string>>(
      '/customer/loyalty-info/validate',
      reqBody,
    );

    // Validation Status
    // MatchedValidForNewAccount = 1
    // MatchedAlreadyInUse = 2
    // NotMatched = 3

    if (response.ok) {
      if (response.data && !response.data.Response?.HasException) {
        return {
          validationStatus: +response.data.ValidationStatus,
          statusDescription: response.data.StatusDescription,
          extLoyaltyId: response.data.ExtLoyaltyId,
        };
      } else {
        throw new Error(response.data?.Response?.ReturnMessage);
      }
    } else {
      throw new Error(response.data?.Response?.ReturnMessage);
    }
  };

  startImpersonationSession = async (token: string): Promise<{ isValid: boolean; memberName: string; authToken: AuthToken }> => {
    const response = await this.executeRequest<'IsValid' | 'MemberLastName' | 'MemberFirstName' | 'UserToken', boolean | string | undefined>(
      '/customer/impersonation-token/validate',
      {
        Token: token,
      },
    );
    return {
      isValid: (response.IsValid ?? false) as boolean,
      memberName: `${response.MemberFirstName} ${response.MemberLastName}`,
      authToken: { jwtToken: `${response.UserToken}`, email: '', refreshToken: '', passwordExpired: false },
    };
  };

  killImpersonationSession = async (token: string): Promise<boolean> => {
    const result = await this.executeRequest<'Token', string>('/customer/impersonation/killsession', {
      Token: token,
    });
    return !result.Response?.HasException;
  };
}
