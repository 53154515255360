import { CustomizeInfo, ProductSearch, Step } from 'src/schema/types';
import logger from 'src/utils/logger';
import { Category, CategoryQueryResult, Product, ProductQueryResult, RawCustomField, RawProductAttribute } from '../../schema/schema';
import { buildCustomizeSubCategoryWhere, buildProductWhere } from '../../utils/search';
import { categoryByIdQuery, subcategoryByParentId } from '../category/query';
import { StoreContextServiceBase } from '../storeContextServiceBase';
import { queryNonSellableProducts } from './query';

const getCustomAttributes = (name: string, attributesRaw: RawCustomField[]) => {
  return attributesRaw.find((x) => x.name === name);
};

export class CustomizeService extends StoreContextServiceBase {
  getCustomizeInfo = async (
    productData: Product,
    // priceChannelId?: string,
    locale: string = 'en-US',
  ): Promise<CustomizeInfo | undefined> => {
    // priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    // availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const configOptionsId = productData.masterData.current?.masterVariant?.attributesRaw?.find(
      (val: RawProductAttribute) => val.name === 'configurationOptions',
    )?.value.id;
    // const configOptions = await getCategoryInfo(configOptionsId);
    const parentCatResponse = await this.executeRequest<'category', Category>(categoryByIdQuery, {
      id: configOptionsId,
      locale,
    });
    const configOptions = parentCatResponse.category;

    // const selectionStepsQuery = await getSubcategoryByParentId(configOptions.id);
    const subCatResponse = await this.executeRequest<'categories', CategoryQueryResult>(subcategoryByParentId, {
      where: buildCustomizeSubCategoryWhere({ id: configOptions.id }),
      locale,
    });
    const selectionStepsQuery = subCatResponse.categories.results;

    const selectionStepsData = selectionStepsQuery?.sort((a, b) => parseFloat(a.orderHint ?? '.9') - parseFloat(b.orderHint ?? '.9'));
    if (!selectionStepsData) {
      return undefined;
    }

    const name = productData.masterData.current?.name ?? '';
    let stepInfo: Step;

    //Build and Push Size step
    const customizeInfo: CustomizeInfo = {
      itemName: name,
      id: productData.id,
      productType: productData.productType?.name ?? '',
      steps: Array<typeof stepInfo>(0),
    };
    let tempSizeName = productData.masterData.current?.masterVariant?.attributesRaw?.find((attr: RawProductAttribute) => attr.name === 'name')?.value;
    if (productData.masterData.current?.variants.length) {
      // logger.log(JSON.stringify(productData, null, 2));
      stepInfo = {
        pageItem: 'Size',
        selectionOption: '',
        choices: [
          {
            id: '' + productData.masterData.current.masterVariant.id,
            sku: productData.masterData.current.masterVariant.sku ?? '',
            itemName: tempSizeName,
            // itemName: tempSizeName.trim(),
            cost:
              (productData.masterData.current.masterVariant.price?.value.centAmount ?? 0) /
              10 ** (productData.masterData.current.masterVariant.price?.value?.fractionDigits ?? 2),
            description: productData.masterData.current.masterVariant.attributesRaw
              .find((val: RawProductAttribute) => val.name === 'feeds')
              ?.value?.replace('to', '-')
              ?.replace(/\s/g, ''),
            product: productData,
          },
        ],
        limit: 1,
        min: 1,
        orderHint: 0,
        catIds: [],
      };

      for (let j = 0; j < productData.masterData.current.variants.length; j++) {
        tempSizeName = productData.masterData.current.variants[j].attributesRaw.find((attr: RawProductAttribute) => attr.name === 'name')?.value;
        stepInfo?.choices?.push({
          id: '' + productData.masterData.current.variants[j].id,
          sku: productData.masterData.current.variants[j].sku ?? '',
          itemName: tempSizeName,
          // itemName: tempSizeName.trim(),
          cost:
            (productData.masterData.current.variants[j].price?.value.centAmount ?? 0) /
            10 ** (productData.masterData.current.variants[j].price?.value.fractionDigits ?? 2),
          description: productData.masterData.current.variants[j].attributesRaw
            ?.find((val: RawProductAttribute) => val.name === 'feeds')
            ?.value?.replace('to', '-')
            ?.replace(/\s/g, ''),
          product: productData.masterData.current.variants[j],
        });
      }
      if ((stepInfo?.choices?.length ?? 0) < 1) {
        logger.error('Failed to fetch customize products');
        return undefined;
      }
      customizeInfo.steps.push(stepInfo);
    }

    //Build and push selection steps
    for (let i = 0; i < selectionStepsData.length; i++) {
      const stepAttributes = selectionStepsData[i]?.custom?.customFieldsRaw ?? [];
      const allowMulti = getCustomAttributes('allowMultiQty', stepAttributes);
      const limit = getCustomAttributes('max', stepAttributes);
      const min = getCustomAttributes('min', stepAttributes);

      stepInfo = {
        pageItem: selectionStepsData[i]?.name ?? '',
        selectionOption: selectionStepsData[i].name === 'Message' ? '' : allowMulti?.value ? 'quantity' : 'select',
        // selectionStepsData[i].name === 'Message' ? '' : selectionStepsData[i]?.custom?.fields?.allowMultiQty ? 'quantity' : 'select',
        // choices: selectionProducts,
        catIds: [selectionStepsData[i].id],
        limit: limit?.value,
        min: min?.value,
        orderHint: parseFloat(selectionStepsData[i]?.orderHint),
        slug: selectionStepsData[i].slug ?? undefined,
      };
      // logger.log('STEP INFO', JSON.stringify(selectionStepsData[i], null, 2));
      customizeInfo.steps.push(stepInfo);
    }

    //Push Notes and Review Steps
    customizeInfo.steps.push({
      pageItem: 'Notes',
      selectionOption: '',
      choices: [],
      limit: 1,
    });
    customizeInfo.steps.push({
      pageItem: 'Review',
      selectionOption: '',
      choices: [],
      limit: 1,
    });

    return customizeInfo;
  };

  getCakeBuilderInfo = async (productData: Product, locale: string = 'en-US'): Promise<CustomizeInfo | undefined> => {
    const configOptionsId = productData.masterData.current?.masterVariant?.attributesRaw?.find(
      (val: RawProductAttribute) => val.name === 'configurationOptions',
    )?.value.id;
    const parentCatResponse = await this.executeRequest<'category', Category>(categoryByIdQuery, {
      id: configOptionsId,
      // where: buildCustomizeCategoryWhere({ id: configOptionsId }),
      locale,
    });
    const configOptions = parentCatResponse.category;

    // const selectionStepsQuery = await getSubcategoryByParentId(configOptions.id);
    const subCatResponse = await this.executeRequest<'categories', CategoryQueryResult>(subcategoryByParentId, {
      where: buildCustomizeSubCategoryWhere({ id: configOptions.id }),
      locale,
    });
    const selectionStepsQuery = subCatResponse.categories.results;
    const selectionStepsData = selectionStepsQuery?.sort((a, b) => parseFloat(a.orderHint) - parseFloat(b.orderHint));
    if (!selectionStepsData) {
      return undefined;
    }
    // logger.log(JSON.stringify(selectionStepsData, null, 3));
    // logger.log('product data:', JSON.stringify(productData, null, 3));

    const name = productData?.masterData?.current?.name ?? '';
    let stepInfo: Step;

    //Build and Push Size step
    const customizeInfo: CustomizeInfo = {
      itemName: name,
      id: productData.id,
      productType: productData.productType?.name ?? '',
      steps: Array<typeof stepInfo>(0),
    };
    let tempSizeName = productData.masterData.current?.masterVariant?.attributesRaw?.find((attr: RawProductAttribute) => attr.name === 'name')?.value;
    if (productData.masterData.current?.variants.length) {
      //if (tempSizeName !== 'Custom Bundt Cake' && !tempSizeName.includes('Donut')) {
      stepInfo = {
        pageItem: 'Size',
        selectionOption: '',
        choices: [
          {
            id: '' + productData.masterData.current.masterVariant.id,
            sku: productData.masterData.current.masterVariant.sku ?? '',
            itemName: tempSizeName.trim(),
            cost:
              (productData.masterData.current.masterVariant.price?.value.centAmount ?? 0) /
              10 ** (productData.masterData.current.masterVariant.price?.value.fractionDigits ?? 2),
            description: productData.masterData.current.masterVariant.attributesRaw
              ?.find((val: RawProductAttribute) => val.name === 'feeds')
              ?.value?.replace('to', '-')
              ?.replace(/\s/g, ''),
            product: productData,
          },
        ],
        limit: 1,
        min: 1,
        orderHint: 0,
        catIds: [],
      };

      for (let j = 0; j < productData.masterData.current.variants.length; j++) {
        tempSizeName = productData.masterData.current.variants[j].attributesRaw?.find((attr: RawProductAttribute) => attr.name === 'name')?.value;
        stepInfo?.choices?.push({
          id: '' + productData.masterData.current.variants[j].id,
          sku: productData.masterData.current.variants[j].sku ?? '',
          itemName: tempSizeName.trim(),
          cost:
            (productData.masterData.current.variants[j].price?.value.centAmount ?? 0) /
            10 ** (productData.masterData.current.variants[j].price?.value.fractionDigits ?? 2),
          description: productData.masterData.current.variants[j].attributesRaw
            ?.find((val: RawProductAttribute) => val.name === 'feeds')
            ?.value?.replace('to', '-')
            ?.replace(/\s/g, ''),
          product: productData.masterData.current.variants[j],
        });
      }
      if ((stepInfo?.choices?.length ?? 0) < 1) {
        logger.error('Failed to fetch customize products');
        return undefined;
      }
      if (stepInfo?.choices && stepInfo?.choices.some((ch) => ch.itemName?.includes('Quarter'))) {
        const ordering: Record<string, number> = {};
        const sortOrder = ['Quarter', 'Half', 'Full'];
        for (let z = 0; z < sortOrder.length; z++) {
          ordering[sortOrder[z]] = z;
        }
        stepInfo?.choices?.sort((a, b) => {
          return ordering[a.itemName] - ordering[b.itemName] || a.itemName?.localeCompare(b.itemName);
        });
      }
      customizeInfo.steps.push(stepInfo);
    }

    //Build and push selection steps
    for (let i = 0; i < selectionStepsData.length; i++) {
      const stepAttributes = selectionStepsData[i]?.custom?.customFieldsRaw ?? [];
      const allowMulti = getCustomAttributes('allowMultiQty', stepAttributes);
      const limit = getCustomAttributes('max', stepAttributes);
      const min = getCustomAttributes('min', stepAttributes);

      stepInfo = {
        pageItem: selectionStepsData[i].name ?? '',
        selectionOption: selectionStepsData[i].name === 'Message' ? '' : allowMulti?.value ? 'quantity' : 'select',
        // choices: selectionProducts,
        catIds: [selectionStepsData[i].id],
        limit: limit?.value,
        min: min?.value,
        orderHint: parseFloat(selectionStepsData[i]?.orderHint),
        slug: selectionStepsData[i].slug ?? undefined,
      };
      // logger.log('STEP INFO', JSON.stringify(selectionStepsData[i], null, 2));
      customizeInfo.steps.push(stepInfo);
    }

    //Push Message and Review Steps
    customizeInfo.steps.push({
      pageItem: 'Review',
      selectionOption: '',
      choices: [],
      limit: 1,
      min: 1,
      orderHint: 1,
      catIds: [],
    });
    // logger.log(JSON.stringify(customizeInfo, null, 3));
    return customizeInfo;
  };

  getNonsellableProducts = async (
    search: ProductSearch,
    priceChannelId?: string,
    availablityChannelIds?: string[],
    locale: string = 'en-US',
    country: string = 'US',
    currency: string = 'USD',
  ): Promise<Product[]> => {
    priceChannelId = priceChannelId ?? (await this.getDistributionChannelId());
    availablityChannelIds = availablityChannelIds ?? (await this.getSupplyChannelIds());

    const response = await this.executeRequest<'products', ProductQueryResult>(queryNonSellableProducts, {
      where: buildProductWhere(search),
      skus: search?.skus,
      limit: search?.limit,
      offset: search?.offset,
      locale,
      country,
      currency,
      channelId: priceChannelId,
      includeChannelIds: availablityChannelIds,
    });
    const products = response.products.results;
    const filteredProducts = products.filter((prod) => {
      const defaultPriceChannelCount = prod.masterData.current?.masterVariant.prices?.filter((x) => x.channel?.key === '01').length;
      if ((prod.masterData.current?.masterVariant.prices?.length ?? 0) > (defaultPriceChannelCount ?? 0)) {
        if (prod.masterData.current?.masterVariant.price === null) {
          return;
        }
        return prod;
      } else if ((prod.masterData.current?.masterVariant.prices?.length ?? 0) === (defaultPriceChannelCount ?? 0)) {
        if (prod.masterData.current?.masterVariant.prices && prod.masterData.current?.masterVariant.prices[0]?.channel?.key?.toString() === '01') {
          prod.masterData.current.masterVariant.price = prod.masterData.current.masterVariant.prices[0];
          return prod;
        }
      }
      return;
    });

    return filteredProducts;
  };
}
