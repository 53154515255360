import { AppConfig } from 'src/services/brandywine/types';

export const getAppConfig = (appConfig: AppConfig[], name: string): AppConfig | undefined => appConfig.find((c) => c.ConfigName === name);

export const getAppConfigValue = <T>(appConfig: AppConfig[], name: string, defaultValue?: T) => {
  const configValue = getAppConfig(appConfig, name)?.ConfigValue;
  if (configValue) {
    try {
      return JSON.parse(configValue ?? '') as T;
    } catch {
      return configValue as unknown as T;
    }
  }
  return defaultValue;
};
