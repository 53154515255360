import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { Category } from './category';
import { Filter } from './common';
import { PagedArray } from './pagedArray';

export type SearchResult<T> = {
  autoCorrectQuery: string;
  didYouMean: string[];
  docs: PagedArray<T>;
  facetFilters: Filter[];
  campaign?: CampaignResult;
  keywordRedirect?: KeywordRedirectResult;
};

export type SearchType = 'bestseller' | 'category' | 'keyword';

export const FacetType: Record<string, string> = Object.freeze({
  Brand: 'Brand',
  Category: 'Category',
  DietLifestyle: 'Diet & Lifestyle',
  Certifications: 'Certifications',
  PreviouslyPurchased: 'Previously Purchased',
  Favorites: 'Favorites',
  OnSale: 'On Sale',
  SomethingExtraOffers: 'Personalized Offers',
  WeeklyExclusiveOffers: 'Member Deals',
  DigitalDeals: 'Digital Coupons',
  BenefitCards: 'Benefits Cards',
});

export const FacetTypeMap: Record<keyof typeof FacetType, 'brand' | 'category' | 'shelf_guide_indicators' | 'certifications' | 'benefits_indicators'> =
  Object.freeze({
    Brand: 'brand',
    Category: 'category',
    DietLifestyle: 'shelf_guide_indicators',
    Certifications: 'certifications',
    BenefitCards: 'benefits_indicators',
  });

export type SearchAssistType = {
  popularItems: string[];
  featuredItems: Product[];
  recentlyViewedItems: Product[];
  departments?: string[];
  recentlySearched?: string[];
};

export type ArticlesType = {
  category: Category[];
  articles: ArticlesData[];
};

export type ArticlesData = {
  id: number;
  name: string;
  imageUrl: string;
  date: string;
  description: string;
};

export type AutoSuggestResults = {
  queryContext: QueryContext;
  suggestionGroups: SuggestionGroup[];
  attributeSuggestions: AttributeSuggestion[];
};

export type QueryContext = {
  originalQuery: string;
};

export type SuggestionGroup = {
  catalogName: string;
  view: string;
  querySuggestions: QuerySuggestion[];
  searchSuggestions: SearchSuggestion[];
  productSuggestQuery: null;
  attributeSuggestions: AttributeSuggestion[];
};

type AttributeSuggestion = {
  name: string;
  value: string;
  attributeType: string;
};

export type QuerySuggestion = {
  query: string;
  displayText: string;
};

export type SearchSuggestion = {
  salePrice: number;
  url: string;
  pid: string;
  thumbImage: string;
  title: string;
};

export type AutoQueryResult = {
  querySuggestions: QuerySuggestion[];
  attributeSuggestions: AttributeSuggestion[];
};

export type AutoSuggestResult = {
  query: string;
  displayText: string;
  name: string;
  value: string;
  attributeType: string;
};

export type CampaignResult = {
  id: string;
  htmlText?: string;
  bannerType?: string;
  keyword?: string;
  name?: string;
  campaignUI?: CampaignUIType;
};

export type CampaignUIType = {
  isSponsored?: boolean;
  imageType?: 'tile' | 'banner';
  tileType?: '' | 'single' | 'double' | 'triple';
  location?: 'top' | 'mid' | 'bottom';
  isLinkExternal?: boolean;
};

export type KeywordRedirectResult = {
  redirectedURL: string;
  redirectedQuery: string;
  originalQuery: string;
};

export type ProductSearchResult = Omit<SearchResult<Product>, 'autoCorrectQuery' | 'didYouMean'>;
