import { ProductVariantAttributesFragment, ProductVariantImagesFragment, ProductVariantPriceFragment } from './productVariant';

const ProductVariantFragment = `
${ProductVariantImagesFragment}
${ProductVariantPriceFragment}
${ProductVariantAttributesFragment}

fragment DefaultVariant on ProductVariant {
  id
  sku
  ...Images
  ...Price
  ...Attributes
}`;

export const LineItemFragment = `

${ProductVariantFragment}

  fragment DefaultLineItem on LineItem {
    __typename
    id
    productId
    productKey
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    name(locale: $locale)
    productSlug(locale: $locale)
    productType {
      name
    }
    quantity
    discountedPricePerQuantity {
      quantity
      discountedPrice {
        value {
          centAmount
        }
        includedDiscounts {
          discount {
            name(locale: $locale)
            isActive
          }
        }
      }
    }
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    variant {
      ...DefaultVariant
    }
    price {
      ...DefaultProductPrice
    }
    totalPrice {
      currencyCode
      centAmount
      fractionDigits
    }
    taxedPrice {
      totalNet {
        currencyCode
        centAmount
        fractionDigits
      }
      totalGross {
        currencyCode
        centAmount
        fractionDigits
      }
    }
    supplyChannel {
      id
      key
    }
    distributionChannel {
      id
      key
    }
  }
`;
