export const ShippingMethodFragment = `

fragment DefaultShippingMethod on ShippingMethod {
  id
  key
  version
  name
  isDefault
  zoneRates {
    zone {
      id
      name
    }
    shippingRates {
      freeAbove {
        type
        currencyCode
        centAmount
        fractionDigits
      }
      isMatching
      price {
        type
        currencyCode
        centAmount
        fractionDigits
      }
    }
  }
}
`;
