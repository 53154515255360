import { Channel } from '../../schema/schema';
import { ServiceBase } from '../serviceBase';
import { channelByKeyQuery } from './query';

export class ChannelService extends ServiceBase {
  getChannel = async (channelKey: string, locale: string = 'en-US'): Promise<Channel | undefined> => {
    const response = await this.executeRequest<'channel', Channel>(channelByKeyQuery, { channelKey, locale });
    return response.channel;
  };
}
